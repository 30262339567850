import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading5 } from '~/theme/utils/typography'

import { StyledButton } from '~/shared/atoms/Button/ButtonShards'
import { ButtonWidth } from '~/shared/atoms/Button/ButtonTypes'
import IconAtom from '~/shared/atoms/Icon'

export const Wrap = styled.div<{ width: ButtonWidth }>`
  max-height: 2.5rem;
  height: 2.5rem;
  width: ${({ width }) => (width === 'full' ? '100%' : 'fit-content')};

  ${mediaQueryUp('m')} {
    max-height: 3rem;
    height: 3rem;
  }

  ${StyledButton} {
    max-height: 2.5rem;
    height: 2.5rem;

    ${mediaQueryUp('m')} {
      max-height: 2.875rem;
      height: 2.875rem;
    }
  }
`

export const Wrapper = styled.div<{ size: string; hidden: boolean }>`
  position: absolute;
  box-sizing: border-box;
  z-index: 3;
  height: calc(100% + 2px);
  min-height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  border-radius: 6px;
  background-color: ${({ theme, size }) => theme.colors.gray[size === 'small' ? 0 : 100]};
  border: 1px solid ${({ theme, size }) => theme.colors.gray[size === 'small' ? 0 : 100]};
  display: flex;
  align-items: center;
  justify-content: ${({ size, hidden }) => (size === 'small' && hidden ? 'right' : 'space-between')};
  padding: ${({ size }) => (size === 'small' ? 0 : '.625rem .5625rem')};
  color: ${({ theme }) => theme.colors.gray[1000]};
  width: ${({ size, hidden }) => (size === 'small' ? (hidden ? '60px' : '90px') : 'calc(100% + 2px)')};
`

export const Icon = styled(IconAtom)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  filter: ${({ theme }) => theme.filters.orange[400]};
  cursor: pointer;

  &:hover {
    filter: ${({ theme }) => theme.filters.orange[500]};
  }
`

export const SmallCounter = styled.div<{ value: number }>`
  display: flex;
  width: ${({ value }) => (value === 0 ? '60px' : '90px')};
  flex-shrink: 0;
  height: 80px;
  position: relative;
`

export const Value = styled(Heading5)<{ hidden: boolean; fontWeight: string }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'initial')};
`

export const IconWrapper = styled.div<{ hidden: boolean; disabled: boolean }>`
  width: 1.625rem;
  height: 1.625rem;
  position: relative;
  display: ${({ hidden }) => (hidden ? 'none' : 'initial')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  display: flex;
  justify-self: right;
  transition: 0.3s;

  &::after {
    display: ${({ hidden }) => (hidden ? 'none' : 'initial')};
    content: ' ';
    z-index: 5;
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    background-color: ${({ theme, disabled }) => theme.colors.gray[disabled ? 500 : 0]};
  }

  ${Icon} {
    ${({ theme, disabled, hidden }) =>
      disabled &&
      css`
        filter: ${theme.filters.gray[100]};
        cursor: initial;
        display: ${hidden ? 'none' : 'initial'};

        &:hover {
          filter: ${theme.filters.gray[100]};
        }
      `}
  }
`

export const ButtonIcon = styled(IconAtom)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  filter: ${({ theme }) => theme.filters.gray[0]};
`
