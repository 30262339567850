export const splitToChunks = (array: JSX.Element[], parts: number, Wrapper: React.ElementType): JSX.Element[] => {
  if (array.length <= 4) {
    return array
  }

  const basePerRow = Math.floor(array.length / parts)
  const extraPerRow = array.length % parts
  let currentIndex = 0

  const rows: JSX.Element[][] = Array.from({ length: parts }, (_, i) => {
    const rowLength = basePerRow + (i < extraPerRow ? 1 : 0)
    const row = array.slice(currentIndex, currentIndex + rowLength)
    currentIndex += rowLength
    return row
  })

  const maxLength = Math.max(...rows.map(row => row.length))

  const transposed = Array.from({ length: maxLength }, (_, i) => rows.map(chunk => chunk[i]).filter(Boolean))

  return transposed.map((chunk, index) => <Wrapper key={index}>{chunk}</Wrapper>)
}
