import styled from '@emotion/styled'

import { Body2, Heading6 } from '~/theme/utils/typography'

import { Header, Title } from '~/shared/atoms/Collapse/CollapseStyle'

export const Select = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;

  ${Body2} {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  img {
    height: 0.6rem;
    filter: ${({ theme }) => theme.filters.gray[1000]};
    opacity: 0.34;
    transform: rotate(${({ active }) => (active ? 90 : -90)}deg);
  }
`

export const SelectLabel = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;

  & ${Heading6} {
    margin: 1rem 2.25rem;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.text.fontWeight.regular};
  }

  & ${Header} {
    width: 100%;
    padding: 0;
    margin: 0;

    & ${Title} {
      margin: unset;
      width: 100%;
      font-size: ${({ theme }) => theme.text.fontSize.m};
      font-weight: ${({ theme }) => theme.text.fontWeight.medium};

      & > * {
        line-height: 1.5rem;
      }

      img {
        height: 1rem;
        width: 1rem;
      }
    }
  }
`

export const LocaleItemContainer = styled.div`
  display: flex;
  gap: 8px;
`
