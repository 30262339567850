// @ts-strict-ignore
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import Image from 'next/image'

import { mediaQueryUpVal } from '~/theme/utils/grid'
import { Body2, Heading5 } from '~/theme/utils/typography'
import { formatPrice } from '~/utils/graphqlDataFormatters'
import { imgProxyLoader } from '~/utils/imgproxy'

import { ChefsMenuSelectContainer } from '~/components/ChefsMenu/ChefsMenuPage/ChefsMenuPageShards'
import ProductDetailsPanel from '~/components/Product/ProductDetailsPanel'
import {
  ContentContainer,
  HeaderContainer,
  ProductContainer,
  ProductGrid,
  SliderContainer,
} from '~/components/Restaurant/RestaurantPage/RestaurantPageShards'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import ProductPageTitle from '~/shared/molecules/ProductPageTitle'

import Counter from '../shared/Counter'

import { ChefsMenuSelectHeader } from './ChefsMenuDetailModalUtils'
import { ImageWrapper, Lower, Upper } from './ChefsMenuDetailsModalShards'

const ChefsMenuDetailsModalView = ({ onClose, reservableExtra, restaurant, max, value: initialValue, onDecrement, onIncrement }: any) => {
  const { tp } = gt
  const [count, setCount] = useState(initialValue || 0)
  const isDesktop = useMediaQuery({ query: mediaQueryUpVal(1080) })
  const handleAdd = count => {
    const difference = initialValue - count
    difference <= 0 ? onIncrement(Math.abs(difference)) : onDecrement(difference)
    onClose()
  }
  return (
    <ProductContainer>
      <ProductGrid>
        <HeaderContainer>
          <ProductPageTitle reservableExtra={reservableExtra} restaurant={restaurant} />
        </HeaderContainer>
        <SliderContainer>
          <ImageWrapper>
            <Image
              loader={imgProxyLoader}
              src={reservableExtra.image?.thumbnail || restaurant?.imageFiles[0]?.preview}
              layout='fill'
              objectFit='cover'
            />
          </ImageWrapper>
        </SliderContainer>
        <ContentContainer>
          <ProductDetailsPanel
            defaultShow={['chefsMenuDescription']}
            reservableExtra={reservableExtra}
            sections={['chefsMenuDescription', 'menu']}
            restaurant={restaurant}
          />
        </ContentContainer>
        <ChefsMenuSelectContainer>
          {isDesktop ? (
            <Upper>
              <ChefsMenuSelectHeader price={reservableExtra.price} />
            </Upper>
          ) : (
            <Heading5 fontWeight={'regular'}>
              <strong>{formatPrice(reservableExtra.price)}</strong> {tp('Checkout', '/ Guest')}
            </Heading5>
          )}
          <Lower>
            <Counter
              min={0}
              max={max}
              value={count}
              onIncrement={() => setCount(count + 1)}
              onDecrement={() => setCount(count - 1)}
              width='full'
              hasButtonLabel={false}
            />
            <Button
              color='orange'
              label={tp('Checkout', 'Add to booking')}
              width='full'
              onClick={() => handleAdd(count)}
              disabled={count === initialValue}
            />
            <Body2>{tp('Checkout', 'You can select the menus in the next step')}</Body2>
          </Lower>
        </ChefsMenuSelectContainer>
      </ProductGrid>
    </ProductContainer>
  )
}

export default ChefsMenuDetailsModalView
