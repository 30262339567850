import styled from '@emotion/styled'

import Icon from '~/shared/atoms/Icon'

export const StyledLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.commons.shadow.s};
  height: 2.5rem;
  border-radius: 0.5rem;
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  transition: 0.25s ease-out;

  &:hover {
    background: ${({ theme }) => theme.colors.green[500]};
    color: ${({ theme }) => theme.colors.gray[0]};
    cursor: pointer;
    img {
      filter: ${({ theme }) => theme.filters.gray[0]};
    }
  }
`

export const StyledIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
  margin-right: 0.5rem;
`
