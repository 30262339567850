// @ts-strict-ignore
import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { filtersToQuery, searchSettingsToQuery } from '~/utils/filters'

import { paths } from '~/constants'
import { HomepageQueryQuery } from '~/generated/graphql'
import { SearchSettings } from '~/shared/molecules/Search'
import { SmartSearchOption } from '~/shared/molecules/SmartSearch/SmartSearchTypes'
import { isRestaurantOption, isTagOption } from '~/shared/molecules/SmartSearch/SmartSearchUtils'

export const MAIN_PAGE_TOP_COLLECTIONS_SLUG = 'main-page-top'

interface HomePageData {
  collections: HomepageQueryQuery['collections']['edges'][0]['node'][]
}

export const getHomePageData = (data: HomepageQueryQuery): HomePageData => {
  const collections = [...data.collections.edges]
    .map(e => e.node)
    .sort((a, b) => a.position - b.position)
    .filter(c => c.section.slug === MAIN_PAGE_TOP_COLLECTIONS_SLUG)

  return {
    collections,
  }
}

export const useOnSmartSearchOptionSelect = ({ searchValue }: { searchValue: SearchSettings }) => {
  const router = useRouter()

  return useCallback(
    (option: SmartSearchOption) => {
      if (isRestaurantOption(option)) {
        const restaurant = option.details
        router.push({ pathname: paths.singleRestaurant(restaurant), query: searchSettingsToQuery(searchValue) })
      } else if (isTagOption(option)) {
        const tag = option.details

        router.push({ pathname: paths.restaurants, query: { ...searchSettingsToQuery(searchValue), ...filtersToQuery({ tags: [tag] }) } })
      }
    },
    [router, searchValue]
  )
}
