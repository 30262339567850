import { Body2, Heading6 } from '~/theme/utils/typography'
import { formatMenuLabel } from '~/utils/graphqlDataFormatters'

import { LeanFerFragment } from '~/generated/graphql'

import { Menu, Position, Wrapper } from './RestaurantMenuShards'

interface Props {
  menus: LeanFerFragment['menus']
  forceLabels: boolean
}

const RestaurantMenuView = ({ menus, forceLabels }: Props) => {
  return (
    <Wrapper>
      {menus?.map((menu, i) => (
        <Menu key={menu.id}>
          <Heading6 fontWeight='medium'>{formatMenuLabel(i, menu.label, forceLabels)}</Heading6>
          {menu.positions.map(position => {
            return (
              <Position key={position.name}>
                <Body2 fontWeight='semiBold'>{position.name}</Body2>
                <Body2 fontWeight='regular'>{position.content}</Body2>
              </Position>
            )
          })}
        </Menu>
      ))}
    </Wrapper>
  )
}

export default RestaurantMenuView
