import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { GalleryRow } from './RestaurantGalleryTypes'
import { styleColumns } from './RestaurantGalleryUtils'

export const Container = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  padding: 1.5rem 1.5rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  z-index: 10;

  ${mediaQueryUp('m')} {
    padding: 3rem 3rem 0;
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      pointer-events: unset;
    `}
`

export const Header = styled.div`
  max-width: 1064px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  ${mediaQueryUp('m')} {
    margin-bottom: 1.5rem;
  }
`

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 2rem;

  ${mediaQueryUp('m')} {
    padding-bottom: 4.375rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Row = styled.div<{ type: GalleryRow }>`
  display: grid;
  grid-gap: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${mediaQueryUp('m')} {
    grid-gap: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  img {
    aspect-ratio: 1.5;
  }

  ${({ type }) => styleColumns[type]};
`

export const RowImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.5rem;
  object-fit: cover;

  * {
    border-radius: 0.5rem;
  }
`
