import { StyledTag, StyledXIcon } from './TagShards'
import { TagSize, TagVariant } from './TagTypes'

export interface TagViewProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  size?: TagSize
  variant?: TagVariant
  onClose?: (id?: string) => void
}

const TagView = ({ label, size = 'small', variant = 'default', onClose, ...props }: TagViewProps) => {
  const tagSize = variant === 'green' || variant === 'filter' ? 'large' : size

  return (
    <StyledTag as='div' size={tagSize} variant={variant} {...props}>
      {label}
      {variant === 'filter' && <StyledXIcon onClick={onClose} icon='x' />}
    </StyledTag>
  )
}

export default TagView
