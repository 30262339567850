import { Country } from '~/utils/countries'

enum FlagShape {
  Square = 'square',
  Rectangle = 'rectangle',
}

type Props = {
  country: Country
  shape?: FlagShape
}

export const Flag = ({ country, shape = FlagShape.Rectangle }: Props) => {
  return <span className={`fi fi-${country.toLowerCase()}${shape === FlagShape.Square ? ' fis' : ''}`} />
}
