import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { Wrapper } from '~/components/Festival/FERModal/FERModalShards'
import {
  HeaderContainer,
  ModalInnerContainer,
  ProductGrid,
  SliderContainer,
} from '~/components/Restaurant/RestaurantPage/RestaurantPageShards'

export const MODAL_BP = 1080

export const ChefsMenuWrapper = styled(Wrapper)`
  ${ModalInnerContainer} {
    ${mediaQueryUp('m')} {
      top: 0;
      border: none;
    }
  }

  ${mediaQueryUp('m')} {
    width: calc(100vw - 6rem);
    max-width: 500px;

    ${SliderContainer} {
      margin-top: 0;
      border-radius: 0.75rem;
    }
  }

  ${mediaQueryUp('l')} {
    width: calc(100vw - 12rem);
    max-width: 1056px;
    padding-left: 2.5rem;

    ${HeaderContainer} {
      margin: 1rem 0 2rem;
      padding: 0;
    }
  }

  & ${ProductGrid} {
    ${mediaQueryUp('m')} {
      width: 100%;
      grid-template-columns: 100%;
      margin: 0 auto;
    }

    ${mediaQueryUp('l')} {
      grid-template-columns: 1fr 25.75rem;
      grid-template-areas:
        'header select'
        'slider select'
        'content select';
      padding: 0;
    }
  }
`
