// @ts-strict-ignore
import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { processEnv } from '~/utils/env'

import ChefsMenuModal from '~/components/ChefsMenu/ChefsMenuModal'
import FERModal, { festivalModalProps } from '~/components/Festival/FERModal'
import { RESERVABLE_TYPES } from '~/constants'
import { FerModalFragment, SingleReservableExtraQuery } from '~/generated/graphql'
import Modal from '~/shared/molecules/Modal'

import { getProductProfileUrl } from './ProductModalUtils'

interface Props {
  type: typeof RESERVABLE_TYPES.FER | typeof RESERVABLE_TYPES.EXTRA
  FER?: FerModalFragment
  reservableExtra?: SingleReservableExtraQuery['reservableExtra']
  handleClose: () => void
}

const ProductModal = ({ reservableExtra, FER, type, handleClose }: Props) => {
  const router = useRouter()
  const [previousPath, setPreviousPath] = useState('')
  useEffect(() => {
    setPreviousPath(router.asPath)
    router.replace(
      router.asPath,
      {
        pathname: getProductProfileUrl(reservableExtra || FER, type),
        query: {
          ...router.query,
          ...(FER && { festivalFilter: FER.festivalEdition.id }),
        },
      },
      { shallow: true }
    )
  }, [type])

  const closeModal = () => {
    const url = new URL(previousPath, processEnv.CLUB_URL)
    url.searchParams.delete('festivalFilter')
    const asPath = url.pathname + url.search
    router.push(asPath, asPath, { shallow: true })
    handleClose()
  }

  return (
    <Modal {...festivalModalProps} handleClose={closeModal}>
      {type === RESERVABLE_TYPES.FER ? (
        <FERModal handleClose={closeModal} fer={FER} withCarousel />
      ) : (
        <ChefsMenuModal handleClose={closeModal} reservableExtra={reservableExtra} />
      )}
    </Modal>
  )
}

export default ProductModal
