import Image from 'next/image'

import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'

import { Header, Logo, Logos, MediaSectionWrapper } from './MediaSectionShards'
import { MEDIA_LOGOS } from './MediaSectionUtils'

const MediaSectionView = () => {
  const { tp } = gt
  return (
    <MediaSectionWrapper>
      <Header>{tp('HomePage', 'They write about us')}</Header>
      <Logos>
        {MEDIA_LOGOS.map(logo => (
          <Logo key={logo.image}>
            <Link href={logo.href}>
              <a>
                <Image src={logo.image} alt={logo.alt} width='197' height='96' />
              </a>
            </Link>
          </Logo>
        ))}
      </Logos>
    </MediaSectionWrapper>
  )
}

export default MediaSectionView
