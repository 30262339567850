import styled from '@emotion/styled'

import Loader from '../Loader'

export const Viewport = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vw;
  background: transparent;
  pointer-events: none;
  z-index: 100;
`

export const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

export const StyledLoader = styled(Loader)`
  width: 1rem;
  height: 1rem;
`
