import styled from '@emotion/styled'

import { mediaQueryUp } from '~/utils/grid'

export const PriceContainer = styled.span`
  display: inline-block;
  position: relative;
  font-size: ${({ theme }) => theme.text.fontSize.m};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.l};
  }
`

export const Currency = styled.span`
  margin-left: 0.3rem;
`

export const Guest = styled.span`
  font-weight: ${({ theme }) => theme.text.fontWeight.regular};
`

export const MainPrice = styled.span``

export const AfterCommaPrice = styled.sup`
  font-size: 0.6rem;
  top: -0.8em;
`

export const AdditionalInformation = styled.span`
  font-size: 0.5rem;
  margin-left: 0.6rem;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-weight: ${({ theme }) => theme.text.fontWeight.regular};
`
