export const modalProps = {
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: false,
  size: 'small' as const,
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeOnEscapeKeyClick: true,
  handleClick: () => null,
  position: 'fixed' as const,
  iconSize: 'big' as const,
}
