import { ReactNode } from 'react'
import { GroupBase } from 'react-select'

import { SmartSearchOption } from '~/shared/molecules/SmartSearch/SmartSearchTypes'

import { Heading, Options, Wrapper } from './GroupShards'

interface Props {
  children: ReactNode
  headingProps?: any
  label: ReactNode
  horizontal?: boolean
  data?: Partial<GroupBase<SmartSearchOption>> & {
    horizontal?: boolean
  }
}

export const GroupView = ({ children, headingProps, label, data = {} }: Props) => {
  return (
    <Wrapper>
      <Heading as='span' {...headingProps}>
        {label}
      </Heading>
      <Options horizontal={data.horizontal}>{children}</Options>
    </Wrapper>
  )
}
