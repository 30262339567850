import React from 'react'

import useCurrency from '~/utils/hooks/useCurrency'

import { gt } from '~/locale'

import { AdditionalInformation, AfterCommaPrice, Currency, Guest, MainPrice, PriceContainer } from './FormattedPriceShards'

type FormattedPriceProps = {
  price: number
  withGuest?: boolean
  additionalPriceInfo?: string
}

const FormattedPrice: React.FC<FormattedPriceProps> = ({ price, withGuest, additionalPriceInfo }) => {
  const [main, afterComma] = price.toFixed(2).split('.')
  const currency = useCurrency()

  return (
    <PriceContainer>
      <MainPrice>{main}</MainPrice>
      {afterComma && afterComma !== '00' && <AfterCommaPrice>{afterComma}</AfterCommaPrice>}
      <Currency>
        {currency}
        {withGuest && <Guest>{' ' + gt.tp('Checkout', '/ Guest')}</Guest>}
      </Currency>
      {additionalPriceInfo && <AdditionalInformation>{additionalPriceInfo}</AdditionalInformation>}
    </PriceContainer>
  )
}

export default FormattedPrice
