import Image from 'next/image'

import { Award, Awards, AwardsSection } from './AwardsShards'
import { AWARDS_LOGOS } from './AwardsUtils'

const AwardsView = () => {
  return (
    <AwardsSection>
      <Awards>
        {AWARDS_LOGOS.map(logo => (
          <Award key={logo.image}>
            <Image src={logo.image} alt={logo.alt} width='218' height='101' />
          </Award>
        ))}
      </Awards>
    </AwardsSection>
  )
}

export default AwardsView
