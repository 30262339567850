// @ts-strict-ignore
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { BasicIcon } from '../Icon'

import { Props } from './ButtonTypes'
import { buttonBehavior, buttonFontSize, buttonHeight, buttonPadding, buttonWidth } from './ButtonUtils'

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  & > span:not(:last-child),
  & > div:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const BasicButtonIcon = styled(BasicIcon)`
  height: 100%;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledButton = styled.button<{ props: Props; href?: string }>`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: inherit;
  border-radius: 0.5rem;
  cursor: pointer;
  outline: none;
  user-select: none;
  transition: ease-in-out 0.3s;
  position: relative;

  ${({ theme, props }) => {
    const behavior = buttonBehavior(theme, props)[props.variant]
    const { width, height, arrayLabel, fontWeight, loading, disabled, justify } = props

    return css`
      ${behavior};
      width: ${width === 'fixed' ? buttonWidth[width][height] : buttonWidth[width]};
      min-height: ${buttonHeight[height]};
      justify-content: ${arrayLabel ? 'stretch' : 'center'};
      padding: ${buttonPadding[height]};
      font-size: ${buttonFontSize[height]};
      font-weight: ${theme.text.fontWeight[fontWeight || 'normal']};
      line-height: ${theme.text.lineHeight.tight};
      letter-spacing: ${theme.text.letterSpacing.normal};

      ${ButtonContent} {
        justify-content: ${justify};
        align-items: center;
      }

      ${IconWrapper} {
        height: 100%;
        width: ${buttonFontSize[height]};
      }

      ${BasicButtonIcon} {
        /* filter: ${theme.filters.gray[0]}; */
      }

      ${(loading || disabled) &&
      css`
        pointer-events: none;
        opacity: 0.4;
      `}

      ${loading &&
      css`
        ${ButtonContent} {
          color: transparent;
        }
        ${BasicButtonIcon} {
          opacity: 0;
        }
      `}
    `
  }};
`

export const LoaderCnt = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
`
