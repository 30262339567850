// @ts-strict-ignore
import slugify from 'slugify'

import { getClubConfig } from '~/utils/clubConfig'

import config from '~/config'
import { PolymorphicCollection, ReservableExtra, Restaurant } from '~/generated/graphql'

export const pathFragments = {
  chefsMenuProfile: '',
  restaurantProfile: '',
  restaurants: 'restaurants',
  chefsMenuOffers: 'menu',
  festivalRestaurants: 'restaurants/festival',
  blogPost: 'blog/post',
  festival: (festivalSlug: string) => `restaurants/festival/${festivalSlug}`,
  restaurantWeek: 'restaurants/festival/restaurant-week',
  sideEvents: 'side-events-listing',
}

export const paths = {
  home: '/',
  about: '/about',
  partners: '/partners',
  forRestaurateurs: '/for-restaurateurs',
  help: '/help',
  career: '/career',
  festivals: '/festivals',
  restaurants: getClubConfig().restaurantsListingEnabled ? '/restaurants' : '/restaurants/festival/restaurant-week',
  earlyReservation: (festivalEditionId: number | string) => `/early-reservation/${festivalEditionId}`,
  festivalListing: (festival: string) => `/restaurants/festival/${festival}`,
  singleFestival: (slug: string) => `/${pathFragments.festivalRestaurants}/${slug}`,
  singleRestaurant: ({ slug }: Pick<Restaurant, 'slug'>) => `${pathFragments.restaurantProfile}/${slug}`,
  singleCollection: ({ id, title }: Pick<PolymorphicCollection, 'id' | 'title'>) =>
    `/collection/${id}-${slugify(title.toLocaleLowerCase())}`,
  chefsMenuOffers: '/menu',
  singleChefsMenu: ({ slug }: Pick<ReservableExtra, 'slug'>) => `${pathFragments.chefsMenuProfile}/${slug}`,
  singleSideEvent: (id: number | string) => `/side-events/${id}`,
  reservations: '/user/reservations',
  userInformation: '/user/information',
  paymentOptions: '/user/payment-options',
  paymentOptionsSuccess: '/user/payment-options/success',
  paymentOptionsFailure: '/user/payment-options/failure',
  favorites: '/user/favorites',
  settings: '/user/settings',
  reservationDetails: code => `/user/reservations/details?code=${code}`,
  reservationEdit: code => `/user/reservations/edit?code=${code}`,
  reservationEditOld: code => `${config().oldUrl}/reservation-edit?prevReservationCode=${code}`,
  checkoutSuccess: (code: string, type?: string, method?: string) => {
    let url = `/order/result/success?code=${code}`
    if (type === 'Discount') {
      url += `&type=${type}`
    }

    if (method) {
      url += `&method=${method}`
    }

    return url
  },
  checkout: (code: string) => `/order?code=${code}`,
  checkoutError: (code: string, error: string) => `/order?code=${code}&error=${error}`,
  checkoutFailure: (code: string) => `/order/result/failure?code=${code}`,
  privacyPolicy: '/privacy-policy',
  regulations: '/regulations',
  faq: '/faq',
  blog: '/weekly',
  blogPost: (slug: string) => `/weekly/${slug}`,
  giftEventsListing: '/gift-events-listing',
  sideEventsListing: '/side-events-listing',
  voucherDetails: code => `/voucher-details/${code}`,
  voucherOrder: code => `/voucher-order/${code}`,
  error404: '/404',

  // mobile restaurant festival menu (+ drinks)
  mobileRestaurantMenuRedirect: '/restaurant-festival-menu',
  festivalRegions: '/restaurant-festival-menu/regions',
  festivalRegionRestaurants: (regionId: string) => `/restaurant-festival-menu/regions/${regionId}`,
  restaurantFestivalMenu: ({ regionId, slug }: { regionId: string; slug: string }) =>
    `/restaurant-festival-menu/regions/${regionId}/${slug}`,
  error401: (source: string) => `/401?source=${source}`,
  oldFestivalUrl: (festivalSlug: string) => `${config().oldUrl}/${festivalSlug}`,
}

export const externalPaths = {
  rwLandingPage: 'https://restaurantweek.pl/',
  bwLandingPage: 'https://breakfastweek.pl/',
}

export const mobileNavigationPaths = [paths.restaurants, paths.chefsMenuOffers, paths.festivals, paths.blog, pathFragments.blogPost]

export const inUserPanel = (route: string) => route.startsWith('/user/')

export const faqQuestionLink = (question: string) => `${paths.faq}?question=${question}`

// const toast = useToast()

// const showErrorToast = (text) => {
//   toast.add({
//     type: 'error',
//     containerSelector: '#CheckoutPaymentSectionToastsContainer',
//     size: 'large',
//     message: text,
//   })
// }
