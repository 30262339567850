// @ts-strict-ignore
import { useMemo, useRef, useState } from 'react'
import AliceCarousel, { AutoPlayStrategy, EventObject } from 'react-alice-carousel'
import { useMediaQuery } from 'react-responsive'

import 'react-alice-carousel/lib/alice-carousel.css'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { imgProxyLoader } from '~/utils/imgproxy'

import { MainPageBannerFragment } from '~/generated/graphql'
import Icon from '~/shared/atoms/Icon'
import Link from '~/shared/atoms/Link'

import { Arrow, BannerImage, BannersContainer, BannersOverlayLeft, BannersOverlayRight, BannerWrapper } from './BannersShards'

interface BannersProps extends React.InputHTMLAttributes<HTMLInputElement> {
  banners: Array<MainPageBannerFragment>
  autoPlay?: boolean
  autoPlayInterval?: number
}

const toBanner = (banners: Array<MainPageBannerFragment>, isMobile: boolean) =>
  banners?.map(banner => {
    if (!banner?.files?.mobile) {
      return null
    }

    const { original, preview } = banner.files[isMobile ? 'mobile' : 'desktop']

    return (
      <BannerWrapper key={banner.id}>
        {banner.href ? (
          <Link href={banner.href}>
            <a>
              <BannerImage
                loader={imgProxyLoader}
                src={original.url}
                width={preview.width}
                height={preview.height}
                alt={banner.alt}
                quality={80}
                draggable={false}
                layout='fixed'
              />
            </a>
          </Link>
        ) : (
          <BannerImage
            loader={imgProxyLoader}
            src={original.url}
            width={preview.width}
            height={preview.height}
            alt={banner.alt}
            quality={80}
            draggable={false}
            layout='fixed'
          />
        )}
      </BannerWrapper>
    )
  })

const responsive = {
  0: { items: 1 },
  600: { items: 2 },
}

const SliderArrow = ({ left, active, onClick }) => (
  <Arrow onClick={onClick} left={left} active={active}>
    <Icon icon='arrow-right' />
  </Arrow>
)

const BannersView = ({ banners, autoPlay = true, autoPlayInterval = 5000 }: BannersProps) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const carouselRef = useRef<AliceCarousel>(null)
  const [active, setActive] = useState({
    prevButtonEnabled: false,
    nextButtonEnabled: true,
  })

  const handleOnSlideChange = ({ isPrevSlideDisabled, isNextSlideDisabled }: EventObject) => {
    setActive({
      prevButtonEnabled: !isPrevSlideDisabled,
      nextButtonEnabled: !isNextSlideDisabled,
    })
  }

  const bannersItems = useMemo(() => {
    return toBanner(banners, isMobile)
  }, [banners, isMobile])

  return (
    <BannersContainer>
      <BannersOverlayLeft>
        <SliderArrow
          left={true}
          active={active.prevButtonEnabled}
          onClick={e => {
            carouselRef.current.slidePrev(e)
          }}
        />
      </BannersOverlayLeft>
      <BannersOverlayRight>
        <SliderArrow
          left={false}
          active={active.nextButtonEnabled}
          onClick={e => {
            carouselRef.current.slideNext(e)
          }}
        />
      </BannersOverlayRight>
      <AliceCarousel
        mouseTracking
        items={bannersItems}
        responsive={responsive}
        ref={carouselRef}
        disableDotsControls
        disableButtonsControls
        onSlideChanged={handleOnSlideChange}
        autoPlay={autoPlay}
        autoPlayStrategy={AutoPlayStrategy.ALL}
        autoPlayInterval={autoPlayInterval}
        paddingRight={630}
        autoWidth
        infinite
      />
    </BannersContainer>
  )
}

export default BannersView
