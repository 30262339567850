import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { fontWeight } from '~/theme/text'

import { CollapseSize } from './CollapseTypes'

export const Title = styled.div<{ fontWeight?: keyof typeof fontWeight }>`
  display: flex;
  align-items: center;

  span {
    line-height: 1;
  }
`

export const Header = styled.div<{ borderStyle: 'border-bottom' | 'border-top' | 'none'; size: CollapseSize }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.gray[1000]};
  cursor: pointer;
  padding: ${({ size }) => (size === 'big' ? '1.875rem 0' : '1.5rem 1rem')};

  ${mediaQueryUp('l')} {
    padding: ${({ size }) => (size === 'big' ? '1.875rem 0' : '1.5rem 0')};
  }

  ${({ borderStyle, theme }) =>
    borderStyle === 'border-top' &&
    css`
      border-top: 1px solid ${theme.colors.gray[200]};
    `}

  ${Title} {
    font-weight: ${({ size, theme }) => size === 'big' && theme.text.fontWeight.medium};
  }
`

export const Container = styled.div<{ borderStyle: 'border-bottom' | 'border-top' | 'none' }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${({ borderStyle, theme }) =>
    borderStyle === 'border-bottom' &&
    css`
      border-bottom: 1px solid ${theme.colors.gray[200]};

      :last-child {
        border-bottom: none;
      }
    `}

  :last-child {
    ${Header} {
      border-bottom: none;
    }
  }
`

export const ButtonIcon = styled.button<{ active: boolean }>`
  margin-left: 1rem;
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 0.6875rem;
    width: auto;
    filter: ${({ theme }) => theme.filters.gray[700]};
    transform: rotate(${({ active }) => (active ? 270 : 180)}deg);
    transition: 0.1s ease;
  }

  &:hover {
    cursor: pointer;
  }
`

export const IconWrapper = styled.div`
  margin-right: 0.75rem;
  display: flex;

  img {
    height: 1.5rem;
    width: auto;
    filter: ${({ theme }) => theme.filters.gray[1000]};
    opacity: 0.4;
  }
`
