import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6, lineClamp } from '~/theme/utils/typography'

export const DescriptionText = styled(Heading6)<{ expandText: boolean }>`
  color: ${({ theme }) => theme.colors.gray[700]};
  ${({ expandText }) =>
    !expandText &&
    `
    ${lineClamp(3)}
  `}
`

export const ExpandTextButton = styled(Heading6)`
  color: ${({ theme }) => theme.colors.green[500]};
  margin-top: 0.5rem;

  ${mediaQueryUp('m')} {
    margin-top: 0.75rem;
  }
`
