import { Body2, Heading6 } from '~/theme/utils/typography'
import { getEventName, getPreview, getReservationDateTime, getRestaurantName, GetRestaurantNameParams } from '~/utils/graphqlDataFormatters'

import { paths } from '~/constants'
import { gt } from '~/locale'

import {
  ReservationStyledContent,
  StyledEventName,
  StyledImage,
  StyledInfobox,
  StyledLink,
  StyledLinks,
  Wrapper,
} from './ReservationModalShards'
import { ReservationModalParameters } from './ReservationModalTypes'

interface ReservationModalViewProps {
  reservation: ReservationModalParameters
  closeModal?(): void
}

const ReservationModalView = ({ reservation, closeModal }: ReservationModalViewProps) => {
  const { date, slot, reservable } = reservation || {}
  const eventName = getEventName(reservable)
  return (
    <Wrapper>
      <ReservationStyledContent>
        <StyledImage src={getPreview(reservable)} />
        <StyledInfobox>
          <Heading6 fontWeight='medium'>{getReservationDateTime({ date, slot }, { weekday: true })}</Heading6>
          <Body2 fontWeight='medium'>{getRestaurantName(reservable as GetRestaurantNameParams)}</Body2>
          {eventName && <StyledEventName>{eventName}</StyledEventName>}
        </StyledInfobox>
      </ReservationStyledContent>
      <StyledLinks>
        <StyledLink
          size='small'
          label={`${gt.tp('Common', 'See all reservations')}`}
          href={paths.reservations}
          onRouteChanged={closeModal}
        />
      </StyledLinks>
    </Wrapper>
  )
}

export default ReservationModalView
