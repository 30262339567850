// @ts-strict-ignore
import { ReactNode, useState } from 'react'

import NextLink, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

import Loader from '../Loader'

import { Container, Line } from './LinkShards'
import { LinkSize } from './LinkTypes'
import { getLinkFontWeight, getLinkLabel } from './LinkUtils'

interface Props extends Omit<LinkProps, 'href'> {
  label?: React.ReactNode
  size?: LinkSize
  /**
   * @deprecated use href and global path object from /constants instead
   */
  route?: string
  /**
   * @deprecated  use href and global path object from /constants instead
   */
  slug?: string
  href?: string
  prefetch?: boolean
  onClick?(): void
  onRouteChanged?(): void
  children?: ReactNode
}

const Link: React.FC<Props> = ({ size = 'regular', label, onClick, onRouteChanged, route, slug, prefetch = false, children, ...props }) => {
  const LinkLabel = getLinkLabel(size)
  const fontWeight = getLinkFontWeight(size)
  const notLink = !props.href && !(route && slug)
  const href = props.href || `${route}/${slug}`
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  const handleRouting = () => {
    router.events.on('routeChangeStart', () => setLoading(true))
    router.events.on('routeChangeComplete', () => {
      setLoading(false)
      onRouteChanged && onRouteChanged()
    })
  }

  const handleClick = e => {
    e.preventDefault()
    onClick && onClick()
  }

  if (label) {
    const content = (
      <Container onClick={notLink ? handleClick : handleRouting} as={notLink ? 'div' : 'a'}>
        {loading ? (
          <Loader color='gray' />
        ) : (
          <>
            <LinkLabel fontWeight={fontWeight}>{label || children}</LinkLabel>
            <Line />
          </>
        )}
      </Container>
    )

    if (notLink) {
      return content
    }

    return (
      <NextLink href={href} prefetch={prefetch} {...props} passHref>
        {content}
      </NextLink>
    )
  }

  return (
    <NextLink href={href} prefetch={prefetch} {...props} passHref>
      {children}
    </NextLink>
  )
}

export default Link
