import { animations } from './animations'
import { colors } from './colors'
import * as commons from './commons'
import { filters } from './filters'
import text from './text'

export default {
  animations,
  commons,
  colors,
  filters,
  text,
}
