import Image from 'next/image'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1, Body2 } from '~/theme/utils/typography'

import { RestaurantCardType } from '~/components/Restaurant/RestaurantCard/RestaurantCardView'
import { Container as RestaurantSliderContainer } from '~/components/Restaurant/RestaurantPage/RestaurantSlider/RestaurantSliderShards'
import Icon from '~/shared/atoms/Icon'
import { StyledPriceTag } from '~/shared/atoms/PriceTag/PriceTagShards'
import Tile from '~/shared/atoms/Tile'
import { CloseIconContainer } from '~/shared/molecules/Modal/ModalShards'
import { Item, PlainTextTagBar } from '~/shared/molecules/TagBar/TagBarShards'

const photoStyles = css`
  height: 74px;
  width: 74px;

  ${mediaQueryUp('m')} {
    height: 164px;
    width: 164px;
  }
`

export const Wrapper = styled(Tile)<{ horizontal: boolean }>`
  position: relative;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s;

  ${mediaQueryUp('m')} {
    max-width: 305px;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.commons.shadow.l};
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      padding: 1rem;
      height: fit-content;
      width: 100%;

      ${mediaQueryUp('m')} {
        padding: 2rem;
        border-radius: 1rem;
        max-width: unset;
      }
    `}
`

export const PhotoWrapper = styled.div`
  grid-area: photo;
  aspect-ratio: 16 / 9;
  height: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.gray[200]};
  pointer-events: none;

  ${RestaurantSliderContainer} {
    aspect-ratio: unset;
    height: 100%;
  }

  .alice-carousel {
    height: 100%;

    > div {
      height: 100%;
    }

    &__wrapper {
      height: 100%;
    }
  }
`

export const RestaurantName = styled.div<{ fontWeight: string }>`
  grid-area: title;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  margin: 1rem 0.25rem 0.375rem;
`

export const RestaurantDescription = styled(Body2)`
  grid-area: description;
  color: ${({ theme }) => theme.colors.gray[800]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0.25rem 1.5rem;
`

export const TagContainer = styled.div`
  padding: 0;
  padding-bottom: 1px;
  grid-area: tags;
  overflow: hidden;
  align-self: flex-end;

  ${PlainTextTagBar} {
    overflow: hidden;
    display: inline-block;
    max-height: 1rem;
  }

  & ${Item} {
    display: inline-block;
  }

  & ${StyledPriceTag} {
    display: inline-block;
    overflow: visible;
  }
`

export const MainContentContainer = styled.div<{ cardType: string }>`
  height: fit-content;
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(4, min-content);
  grid-template-areas: 'photo photo' 'title menu' 'description description' 'tags tags';
  grid-gap: 0;

  ${({ cardType }) =>
    cardType === 'daily' &&
    css`
      pointer-events: none;
      grid-template-columns: minmax(0, 1fr) 74px;
      grid-template-rows: repeat(4, min-content);
      grid-template-areas: 'title photo' 'description photo' 'tags photo' 'pills pills';
      grid-column-gap: 1rem;

      ${mediaQueryUp('m')} {
        grid-template-columns: 164px minmax(0, 1fr);
        grid-template-areas: 'photo title' 'photo description' 'photo pills' 'photo tags';
        grid-column-gap: 2rem;
      }

      ${PhotoWrapper} {
        ${photoStyles};

        margin-bottom: 1px;
      }

      ${RestaurantName} {
        margin: 0;
        ${mediaQueryUp('m')} {
          margin: 0.5rem 0;
        }
      }

      ${RestaurantDescription} {
        margin: 0;
      }
    `};
`

export const Photo = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;
`

export const PillContainer = styled.div`
  grid-area: pills;
  pointer-events: auto;
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  cursor: default;

  ${mediaQueryUp('m')} {
    margin: 2.25rem 0 1.25rem;
  }
`

export const ReservationsCount = styled(Body1)`
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${({ theme }) => theme.colors.gray[700]};

  ${mediaQueryUp('m')} {
    display: block;
  }
`

export const TooltipWrapper = styled.div`
  margin: 0.5rem 0;
  align-self: end;
  pointer-events: none;
`

export const IconContainer = styled(CloseIconContainer)`
  pointer-events: auto;

  ${mediaQueryUp('l')} {
    display: none;
  }
`

const ICON_SIZE = '1.375rem'

export const MenuIconWrapper = styled.div`
  position: relative;
  pointer-events: auto;
  grid-area: menu;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};

  &::after {
    content: '';
    position: absolute;
    width: calc(${ICON_SIZE} * 3);
    height: calc(${ICON_SIZE} * 3);
    top: calc(${ICON_SIZE} * -1);
    left: calc(${ICON_SIZE} * -1);
  }
`

export const MenuIcon = styled(Icon)`
  display: block;
  width: ${ICON_SIZE};
  height: fit-content;
  color: ${({ theme }) => theme.colors.gray[900]};
`

export const LikeContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  pointer-events: auto;
  right: 1.5rem;
  z-index: 4;
`

export const LikeContainerWide = styled(LikeContainer)`
  top: 0.625rem;
  right: 0.625rem;
  pointer-events: auto;
  z-index: 3;

  ${mediaQueryUp('m')} {
    display: block;
  }
`

export const NoSlotsWrapper = styled.div`
  grid-area: pills;
  pointer-events: auto;
  display: flex;
  margin: 0.5rem 0;
  flex-direction: column;
  gap: 0.3rem;

  ${mediaQueryUp('m')} {
    margin: 1rem 0;
    gap: 0.5rem;
  }
`

export const NoSlotsText = styled.span`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const NoSlotsBadgeText = styled.span`
  color: ${({ theme }) => theme.colors.orange[600]};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const NoSlotsBadge = styled.div`
  display: flex;
  height: 2rem;
  padding: 0.375rem 0.75rem;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.orange[200]};
  width: fit-content;
`

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: 0.4rem;

  img {
    transform: rotate(180deg);
    filter: ${({ theme }) => theme.filters.orange[600]};
  }
`

export const SliderImageWrapper = styled.div<{ type: RestaurantCardType }>`
  ${({ type }) =>
    type === 'daily'
      ? photoStyles
      : css`
          height: 100%;
        `};
`
