const _white = 'invert(100%) sepia(100%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%)'

const gray = {
  0: 'invert(100%) sepia(0%) saturate(7448%) hue-rotate(359deg) brightness(97%) contrast(109%);',
  20: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(340deg) brightness(103%) contrast(98%);',
  50: 'invert(99%) sepia(1%) saturate(8%) hue-rotate(201deg) brightness(106%) contrast(96%);',
  100: 'invert(100%) sepia(97%) saturate(403%) hue-rotate(188deg) brightness(113%) contrast(92%);',
  200: 'invert(100%) sepia(80%) saturate(130%) hue-rotate(227deg) brightness(117%) contrast(84%);',
  300: 'invert(78%) sepia(8%) saturate(1%) hue-rotate(315deg) brightness(110%) contrast(100%);',
  400: 'invert(91%) sepia(0%) saturate(0%) hue-rotate(48deg) brightness(91%) contrast(88%);',
  500: 'invert(79%) sepia(2%) saturate(0%) hue-rotate(15deg) brightness(91%) contrast(91%);',
  600: 'invert(58%) sepia(30%) saturate(22%) hue-rotate(357deg) brightness(92%) contrast(88%);',
  700: 'invert(51%) sepia(2%) saturate(142%) hue-rotate(318deg) brightness(83%) contrast(89%);',
  800: 'invert(15%) sepia(1%) saturate(5090%) hue-rotate(322deg) brightness(104%) contrast(60%);',
  900: 'invert(12%) sepia(0%) saturate(242%) hue-rotate(174deg) brightness(103%) contrast(84%);',
  1000: 'invert(0%) sepia(19%) saturate(51%) hue-rotate(343deg) brightness(96%) contrast(72%);',
}

const teal = {
  100: 'invert(96%) sepia(2%) saturate(527%) hue-rotate(40deg) brightness(103%) contrast(93%)',
  300: 'invert(95%) sepia(23%) saturate(313%) hue-rotate(26deg) brightness(95%) contrast(91%)',
  400: 'invert(57%) sepia(10%) saturate(6094%) hue-rotate(49deg) brightness(101%) contrast(84%)',
  500: 'invert(43%) sepia(49%) saturate(6456%) hue-rotate(68deg) brightness(91%) contrast(91%)',
  600: 'invert(21%) sepia(18%) saturate(4005%) hue-rotate(74deg) brightness(100%) contrast(104%)',
}

const red = {
  100: 'invert(98%) sepia(3%) saturate(1451%) hue-rotate(33deg) brightness(111%) contrast(97%);',
  200: 'invert(95%) sepia(14%) saturate(1681%) hue-rotate(295deg) brightness(115%) contrast(102%);',
  300: 'invert(79%) sepia(47%) saturate(264%) hue-rotate(313deg) brightness(98%) contrast(93%);',
  400: 'invert(39%) sepia(86%) saturate(1422%) hue-rotate(333deg) brightness(86%) contrast(95%);',
  500: 'invert(23%) sepia(58%) saturate(2223%) hue-rotate(341deg) brightness(99%) contrast(95%);',
  600: 'invert(10%) sepia(73%) saturate(4642%) hue-rotate(352deg) brightness(107%) contrast(94%);',
}

const orange = {
  100: 'invert(83%) sepia(4%) saturate(1649%) hue-rotate(324deg) brightness(119%) contrast(106%);',
  200: 'invert(97%) sepia(5%) saturate(2436%) hue-rotate(310deg) brightness(107%) contrast(103%);',
  300: 'invert(92%) sepia(39%) saturate(812%) hue-rotate(313deg) brightness(104%) contrast(105%);',
  400: 'invert(77%) sepia(71%) saturate(2755%) hue-rotate(3deg) brightness(99%) contrast(101%);',
  500: 'invert(70%) sepia(69%) saturate(4229%) hue-rotate(11deg) brightness(104%) contrast(107%);',
  600: 'invert(71%) sepia(67%) saturate(4258%) hue-rotate(358deg) brightness(91%) contrast(89%);',
  brand: 'invert(79%) sepia(35%) saturate(5458%) hue-rotate(5deg) brightness(106%) contrast(100%);',
}

const green = {
  100: 'invert(98%) sepia(2%) saturate(693%) hue-rotate(25deg) brightness(103%) contrast(92%);',
  200: 'invert(96%) sepia(7%) saturate(951%) hue-rotate(32deg) brightness(101%) contrast(82%);',
  300: 'invert(80%) sepia(14%) saturate(1289%) hue-rotate(57deg) brightness(86%) contrast(84%);',
  400: 'invert(51%) sepia(100%) saturate(558%) hue-rotate(47deg) brightness(97%) contrast(84%);',
  500: 'invert(43%) sepia(73%) saturate(2264%) hue-rotate(54deg) brightness(100%) contrast(99%);',
  600: 'invert(40%) sepia(40%) saturate(758%) hue-rotate(51deg) brightness(99%) contrast(92%);',
}

const blue = {
  100: 'invert(100%) sepia(39%) saturate(4031%) hue-rotate(179deg) brightness(109%) contrast(97%);',
  400: 'invert(21%) sepia(91%) saturate(2663%) hue-rotate(225deg) brightness(78%) contrast(106%);',
}

const color = {
  primary: orange[400],
  text: gray[1000],
  textInverted: _white,
  error: red[400],
  layoutBg: _white,

  checkbox: {
    bgEmpty: gray[0],
    bgEmptyDisabled: gray[200],
  },

  dropdownSelect: {
    optionIcon: teal[400],
  },

  input: {
    icon: gray[500],
    iconDark: gray[800],
    iconError: red[400],
  },
}

export const filters = {
  ...color,
  gray,
  teal,
  orange,
  red,
  green,
  blue,
}
