// @ts-strict-ignore
import { useState } from 'react'

import { useTheme } from '@emotion/react'

import ShowOnTrigger from '../ShowOnTrigger'

import { ArrowWrapper, Container } from './TooltipShards'
import { AutoplacementData, TooltipPlacement, TooltipSize } from './TooltipTypes'

interface Props {
  placement: TooltipPlacement
  label: React.ReactNode
  children: React.ReactNode
  size: TooltipSize
  trigger?: 'click' | 'hover' | 'programmatic'
  show?: boolean
}

const Tooltip: React.FC<Props> = ({ placement, label, size, trigger = 'hover', show: programmaticShow, children }) => {
  const theme = useTheme()
  const [autoplacement, setAutoplacement] = useState<AutoplacementData | null>(null)
  const [isHovered, setHover] = useState(false)

  return (
    <ShowOnTrigger
      placement={placement}
      type={trigger}
      show={programmaticShow || (trigger === 'hover' && isHovered)}
      trigger={children}
      contentAsTrigger={false}
      content={
        <Container onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} size={size}>
          {label}
        </Container>
      }
      triggerIndicator={
        <ArrowWrapper size={size} placementData={autoplacement ?? placement}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' fill='none'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M6.58579 1.41421C7.36684 0.633164 8.63317 0.633165 9.41421 1.41421L16 8H0L6.58579 1.41421Z'
              fill={theme.colors.gray[0]}
            />
          </svg>
        </ArrowWrapper>
      }
      autoplacement={autoplacement}
      setAutoplacement={setAutoplacement}
    />
  )
}

export default Tooltip
