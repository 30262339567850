import { useCallback, useEffect, useMemo } from 'react'

import dayjs from 'dayjs'

import { useApolloClient } from '@apollo/client'

import { deleteCookie, getCookieAsParsedJson, setCookiesAsJson } from '~/utils/cookie'
import { getClosestRegionByGeolocation } from '~/utils/getClosestRegionByGeolocation'

import { COOKIE, DEFAULT_REGION } from '~/constants/common'
import {
  Region,
  RegionsQuery,
  SelectedRegionDocument,
  SelectedRegionQuery,
  SelectedRegionQueryVariables,
  useRegionsQuery,
  useSelectedRegionQuery,
} from '~/generated/graphql'
import { gt } from '~/locale'

import { smallModalPropsTemplate } from '../Modal'

type LeanRegion = Omit<Region, 'zones'>
type CookieRegion = SelectedRegionQuery['selectedRegion']

interface UseLocation {
  loading: boolean
  selectedRegion?: CookieRegion
  isRegionSelectedByUser: boolean
  regions: LeanRegion[]
  setRegion(region: LeanRegion): void
  setIsRegionSelectedByUser(value: boolean): void
}

export const locationLogger = getLogger('location', 'debug')

const getLocationCookie = () => {
  try {
    return getCookieAsParsedJson(undefined, COOKIE.LOCATION)
  } catch {
    return null
  }
}

export const useLocation = (): UseLocation => {
  const client = useApolloClient()
  const { data: selectedRegionData } = useSelectedRegionQuery()
  const { data: dataRegions, loading } = useRegionsQuery({
    nextFetchPolicy: 'cache-first',
  })
  const isDefaultRegion = useMemo(() => selectedRegionData?.selectedRegion?.id === DEFAULT_REGION.id, [selectedRegionData?.selectedRegion])

  const selectedRegion = useMemo(
    () =>
      isDefaultRegion
        ? {
            ...selectedRegionData?.selectedRegion,
            id: selectedRegionData?.selectedRegion?.id,
            name: gt.tp('Locations', DEFAULT_REGION.name),
          }
        : selectedRegionData?.selectedRegion,
    [selectedRegionData?.selectedRegion, isDefaultRegion]
  ) as CookieRegion

  const regions = useMemo(
    () =>
      dataRegions?.regions?.map(region =>
        region.id === DEFAULT_REGION.id ? { ...region, name: gt.tp('Locations', DEFAULT_REGION.name) } : region
      ),
    [dataRegions?.regions]
  )

  const isRegionSelectedByUser = !!selectedRegion && !selectedRegion.isProposed

  const setSelectedRegion = useCallback(
    (value: LeanRegion, isSelectedByUser: boolean = true) => {
      const { id, name } = value

      client.cache.writeQuery<SelectedRegionQuery, SelectedRegionQueryVariables>({
        query: SelectedRegionDocument,
        data: { selectedRegion: { id, name, isProposed: !isSelectedByUser, __typename: 'CookieRegion' } },
      })
      locationLogger(`Set SelectedRegion cache, region id: ${id}`)
    },
    [client.cache]
  )

  const validateCookie = (value: CookieRegion, regions: RegionsQuery['regions']) => {
    return !!value && regions.map(r => r.id).includes(value.id)
  }

  const setLocationCookie = useCallback((region: CookieRegion) => {
    if (!region) {
      deleteCookie(null, COOKIE.LOCATION)
      return
    }
    const { id, name, isProposed } = region
    setCookiesAsJson(COOKIE.LOCATION, { id, name, isProposed }, undefined, { expires: dayjs().add(10, 'years').toDate() })
    locationLogger(`Set Location cookie, region id: ${id}`)
  }, [])

  const setIsRegionSelectedByUser = (isSelectedByUser: boolean) => {
    const region = (regions || []).find(r => r.id === selectedRegion?.id)
    if (region) {
      setSelectedRegion(region, isSelectedByUser)
    }
  }

  useEffect(() => {
    if (isRegionSelectedByUser) {
      setLocationCookie(selectedRegion)
    }
  }, [isRegionSelectedByUser, selectedRegion, setLocationCookie])

  useEffect(() => {
    if (regions && !isRegionSelectedByUser) {
      const cookieLocation = getLocationCookie()
      const hasValidLocationCookie = validateCookie(cookieLocation, regions)
      if (hasValidLocationCookie) {
        setSelectedRegion(cookieLocation)
        locationLogger(`Location cookie is valid, set selected region id: ${cookieLocation.id}`)
      } else {
        getClosestRegionByGeolocation(regions).then(closestRegion => {
          // user might have already chosen their location while we were waiting for geolocation
          const locationCookie = getLocationCookie()
          if (locationCookie) return

          setSelectedRegion(closestRegion, false)
          locationLogger(`Set region by geolocation, set selected region id: ${closestRegion.id}`)
        })
      }
    }
  }, [regions, isRegionSelectedByUser, selectedRegion, setSelectedRegion])

  return {
    regions: regions || [],
    loading,
    selectedRegion,
    isRegionSelectedByUser,
    setRegion: setSelectedRegion,
    setIsRegionSelectedByUser,
  }
}

export const useLocationSync = ({ syncWith }: { syncWith: CookieRegion | null }) => {
  const { selectedRegion, setRegion, regions } = useLocation()

  useEffect(() => {
    if (syncWith && selectedRegion && selectedRegion.id !== syncWith.id) {
      const region = regions.find(region => region.id === syncWith.id)
      region && setRegion(region)
    }
  }, [syncWith, setRegion, selectedRegion, regions])
}

export const mobileModalProps = {
  ...smallModalPropsTemplate,
  closeIconPosition: 'outside' as const,
  centerVerticallyOnMobile: true,
  showOutsideIconOnMobile: true,
}

export const desktopModalProps = {
  ...mobileModalProps,
  position: 'anchored' as const,
}
