type MediaLogos = {
  image: string
  href: string
  alt: string
}

export const MEDIA_LOGOS: MediaLogos[] = [
  {
    image: '/assets/home-page/media/rzeczpospolita.png',
    href: 'https://sukces.rp.pl/kuchnia/art17718311-pawel-swiatczynski-maciej-zakowski-trzeba-isc-pod-prad',
    alt: 'Logo Rzeczpospolita',
  },
  {
    image: '/assets/home-page/media/vogue.png',
    href: 'https://www.vogue.pl/a/najciekawsze-debiuty-na-restaurant-week-2022-w-warszawie',
    alt: 'Logo Vogue',
  },
  {
    image: '/assets/home-page/media/onet.png',
    href: 'https://gotowanie.onet.pl/artykuly/polski-fine-dining-kwitnie-co-to-jest-i-gdzie-warto-zjesc-zobacz-nasze-rady/77p7chv',
    alt: 'Logo Onet',
  },
  {
    image: '/assets/home-page/media/forbes.png',
    href: 'https://www.forbes.pl/biznes/jak-restaurant-week-stal-sie-najwiekszym-festiwalem-kulinarnym-na-swiecie/326117z',
    alt: 'Logo Forbes',
  },
  {
    image: '/assets/home-page/media/wp.png',
    href: 'https://kuchnia.wp.pl/foodsoundsgood-startuja-rezerwacje-na-wiosenny-restaurant-week-6865074482063968a',
    alt: 'Logo WP',
  },
  {
    image: '/assets/home-page/media/wyborcza.png',
    href: 'https://warszawa.wyborcza.pl/warszawa/7,54420,29587931,ruszyl-restaurant-week-w-warszawie-gdzie-i-co-mozna-zjesc-za.html',
    alt: 'Logo Wyborcza',
  },
]
