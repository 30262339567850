// @ts-strict-ignore
import { useLastVisitedRestaurantsQuery, usePopularTagsQuery, useSelectedRegionQuery } from '~/generated/graphql'
import { gt } from '~/locale'

import Group from './Group'
import { RestaurantOption, TagOption } from './Options/OptionsView'

export default () => {
  const {
    data: { selectedRegion },
  } = useSelectedRegionQuery()

  const { data: restaurantsData } = useLastVisitedRestaurantsQuery({
    variables: {
      ids: [],
    },
    skip: true,
  })

  const { data: tagsData } = usePopularTagsQuery({
    variables: {
      region_id: selectedRegion.id,
    },
    skip: true,
  })

  const groups = [
    {
      label: 'Popular categories',
      items:
        tagsData &&
        tagsData.popularTags
          .slice(0, 3)
          .sort((tagA, tagB) => tagA.position - tagB.position)
          .map(tag => <TagOption key={`popular-categories-${tag.id}`} label={tag.name} data={{ details: tag }} />),
      options: {
        horizontal: true,
      },
    },
    {
      label: 'Last visited',
      items:
        restaurantsData &&
        restaurantsData.restaurants.nodes.map(restaurant => (
          <RestaurantOption key={`last-visited-${restaurant.id}`} data={{ details: restaurant }} />
        )),
    },
  ]

  return (
    <>
      <Group label={gt.tp('Search', 'Search for your favourite restaurant')} data={{ options: [] }}>
        <></>
      </Group>
      {groups
        .filter(({ items }) => items && !!items.length)
        .map(({ items, label, options }) => (
          <Group key={`group-${label}`} label={label} data={options}>
            {items}
          </Group>
        ))}
    </>
  )
}
