// @ts-strict-ignore
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Body2, Heading5 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Dropdown from '~/shared/atoms/Dropdown'
import Icon from '~/shared/atoms/Icon'
import { PriceTag } from '~/shared/atoms/PriceTag'
import Tag from '~/shared/atoms/Tag'

import { DropdownSelect, Item, PlainTextTagBar, StyledStarIcon, StyledTagBar, TagBarIconContainer } from './TagBarShards'
import { Props } from './TagBarTypes'
import { getAddress, useOpeningTime, useTags } from './TagBarUtils'

const OpeningTimeDropdown: React.FC<Pick<Props, 'restaurant'>> = ({ restaurant }) => {
  const [show, setShow] = useState(false)
  const { currentTitle, items } = useOpeningTime(restaurant, gt)
  const currentDayIndex = parseInt(restaurant.openingHoursToday?.day, 10) - 1
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const DropdownTitle = isMobile ? Body2 : Heading5

  return (
    <Dropdown
      trigger='programmatic'
      show={show}
      selectedKey={currentDayIndex}
      onChange={() => null}
      onClickOutside={() => setShow(false)}
      items={items}>
      <DropdownSelect active={show} onClick={() => setShow(v => !v)}>
        <DropdownTitle>{currentTitle}</DropdownTitle>
        <Icon icon='arrow' />
      </DropdownSelect>
    </Dropdown>
  )
}

const TagBarView: React.FC<Props> = ({
  restaurant,
  reservableExtra,
  sections = ['hours', 'rating', 'address', 'price'],
  variant = 'plainText',
  maxBaseTagCount = 1,
  ...props
}) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const tags = useTags({ restaurant, reservableExtra }, maxBaseTagCount)
  const priceTag = tags?.find(tag => typeof tag !== 'string' && tag?.type === 'Price')
  const size = isMobile || variant === 'default' ? 'small' : props?.size

  if (!tags.length) {
    return null
  }
  const ratingElement = sections.includes('rating') && restaurant?.ratingCount > 0 && (
    <Item>
      <TagBarIconContainer>
        <StyledStarIcon icon='star' />
      </TagBarIconContainer>
      <span>{`${restaurant?.rating} (${restaurant?.ratingCount})`}</span>
    </Item>
  )
  const priceTagElement =
    (sections.includes('price') && priceTag && typeof priceTag !== 'string' && (
      <>
        <Item>
          <PriceTag position={priceTag.position} size={size} variant={variant} />
        </Item>
        {variant === 'plainText' ? `•${'\u00A0'}` : null}
      </>
    )) ||
    null

  if (variant === 'plainText') {
    const tagList = []
    tags.length &&
      tags
        .filter(tag => typeof tag === 'string' || (tag && tag?.type !== 'Price'))
        .forEach((tag, i) => {
          const label = typeof tag === 'string' ? tag : tag?.name
          i === 0 ? tagList.push(label) : tagList.push(` •${'\u00A0' + label}`)
        })

    sections.includes('address') &&
      restaurant &&
      restaurant.address &&
      tagList.push(' • ' + getAddress(restaurant.address, restaurant.zone))
    return (
      <PlainTextTagBar size={size}>
        {ratingElement}
        {priceTagElement}
        {[...tagList]}
      </PlainTextTagBar>
    )
  }
  return (
    <>
      <StyledTagBar size={size} variant={variant}>
        {ratingElement}
        {priceTagElement}
        {tags.length &&
          tags
            .filter(tag => typeof tag === 'string' || tag?.type !== 'Price')
            .map(tag => {
              const label = typeof tag === 'string' ? tag : tag?.name
              const key = typeof tag === 'string' ? tag : tag?.id
              return (
                <Item key={key}>
                  <Tag size={size} label={label} variant={variant} />
                </Item>
              )
            })}
        {sections.includes('address') && restaurant && restaurant.address && (
          <Item>
            <Tag size={size} label={getAddress(restaurant.address, restaurant.zone)} variant={variant} />
          </Item>
        )}
        {sections.includes('hours') && restaurant && (
          <Item>
            <OpeningTimeDropdown restaurant={restaurant} />
          </Item>
        )}
      </StyledTagBar>
    </>
  )
}

export default TagBarView
