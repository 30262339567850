import { keyframes } from '@emotion/react'

const expand = (startHeight: number, targetHeight: number) => keyframes`
  from {
    height: ${startHeight}rem;
  }
  to {
    height: ${targetHeight}rem;
  }`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }`

/* for Firefox */
const scrollText = keyframes`
    from { transform: translateY(100%); }
    to { transform: translateY(-100%); }
  `

export const animations = {
  expand,
  fadeIn,
  scrollText,
}
