import { Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { ModalMargin } from '../LocationMenuTypes'

import { StyledActions, StyledContent, StyledInfobox, StyledRegionName, Wrapper } from './LocationModalShards'

interface LocationModalViewProps {
  region: string
  onChangeClick(): void
  onCloseClick(): void
  margin?: ModalMargin
}

const LocationModalView = ({ onChangeClick, onCloseClick, region, margin }: LocationModalViewProps) => {
  return (
    <Wrapper margin={margin}>
      <StyledContent>
        <StyledInfobox>
          <Heading6>
            {gt.tp('LocationModal', 'You seem to be in the region of:')}
            <StyledRegionName>{region}</StyledRegionName>
          </Heading6>
        </StyledInfobox>
        <StyledActions>
          <Button
            color='green'
            width='full'
            height='small'
            label={`${gt.tp('LocationModal', 'Confirm and close')}`}
            onClick={onCloseClick}
          />
          <Button
            color='secondary'
            width='full'
            height='small'
            label={`${gt.tp('LocationModal', 'Change location')}`}
            onClick={onChangeClick}
          />
        </StyledActions>
      </StyledContent>
    </Wrapper>
  )
}

export default LocationModalView
