import styled from '@emotion/styled'

import { mediaQueryUp } from '~/utils/grid'

export const AwardsSection = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 2.5rem 0;
`

export const Awards = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem 0;

  ${mediaQueryUp('m')} {
    gap: 1rem;
    justify-content: space-between;
  }
`

export const Award = styled.li`
  width: 10.125rem;
  height: 4.75rem;

  ${mediaQueryUp('m')} {
    width: 13.625rem;
    height: 6.3125rem;
  }

  img {
    pointer-events: none;
  }
`
