import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import { StyledVerifyAccountModal } from '../VerifyAccountModal'

export const Wrapper = styled(StyledVerifyAccountModal)`
  padding: 1rem;

  ${mediaQueryUp('s')} {
    padding: 1rem;
  }

  ${mediaQueryUp('m')} {
    padding: 2.5rem;
  }
`

export const Actions = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaQueryUp('m')} {
    margin-top: 0.5rem;
  }
`

export const Logo = styled.div`
  display: block;
  position: relative;
  height: 1.5rem;
  width: 100%;
  margin: 2.75rem auto 0.5rem;
`

export const UpperHeading = styled(Heading6)`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
`
