// @ts-strict-ignore
import dayjs, { Dayjs } from 'dayjs'
import get from 'lodash/get'

import { ALPHABET } from '~/constants'
import { ChefsMenuAssignmentFragment, DateRange, ReservationModalFragment, Scalars, Slot } from '~/generated/graphql'
import { gt } from '~/locale'

import { processEnv } from './env'

export const formatPrice = (price: number | string) => {
  const numPrice = typeof price === 'string' ? parseFloat(price) : price
  const envCurrency = processEnv.CURRENCY

  return numPrice?.toLocaleString('pl-PL', {
    style: 'currency',
    currency: envCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const dayjsToString = (day: dayjs.Dayjs) => {
  return day.format('YYYY-MM-DD')
}

export const getTimeFromSlot = (slot: number) => {
  if (slot === 0) {
    return gt.tp('FestivalModal', 'Walk-in')
  }
  const hours = Math.floor(slot / 60)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor(slot % 60)
    .toString()
    .padStart(2, '0')
  return `${hours}:${minutes}`
}

export const getSlotFromTime = (time: Dayjs) => time.hour() * 60 + time.minute()

export const getPossibleSlots = (startSlot: number, endSlot: number, interval: number) => {
  const slots = []
  while (startSlot <= endSlot) {
    slots.push(startSlot)
    startSlot += interval
  }
  return slots
}

export const getNearestSlot = (
  slots: Pick<Slot, 'slot' | 'date'>[] | undefined,
  date: Dayjs = dayjs()
): Pick<Slot, 'slot' | 'date'> | null => {
  if (!slots) {
    return null
  }

  for (const slot of slots) {
    const slotDate = dayjs.parseZone(slot.date)
    const timeDiff = slotDate.diff(date, 'minute') + slot.slot

    if (timeDiff >= 0) {
      return slot
    }
  }

  return null
}

export const getPossibleDailySlots = dailyReservable => {
  if (!dailyReservable) {
    return undefined
  }
  return get(dailyReservable, 'slotDefinitions')[0].possibleSlots
}

export const getPossibleFERSlots = FERReservable => {
  return get(FERReservable, 'festivalEdition.slots')?.map(slot => slot.slotStart)
}

export const getEventReservableSlots = reservable => {
  if (!reservable) {
    return undefined
  }
  return get(reservable, 'slots').map(slot => slot.slotStart)
}

export const isSlotToday = (slot: number) => slot > 0 && slot < 1440

export const getEventName = (
  reservable: ReservationModalFragment['reservable'],
  reservableExtraAssignments?: ChefsMenuAssignmentFragment[]
) => {
  if (reservableExtraAssignments?.length > 0) {
    return gt.tp('ReservationCard', '#ChefsMenu')
  }

  switch (reservable.__typename) {
    case 'FestivalEditionRestaurant':
      return reservable.festivalEdition.festival.name
    case 'Event':
      return reservable.title
    default:
      return ''
  }
}

export const getPreview = (reservable: ReservationModalFragment['reservable']) => {
  return reservable.__typename === 'Event'
    ? reservable.eventRestaurant.imageFiles[0]?.preview || reservable.imageFiles?.[0]?.preview
    : reservable.restaurant.imageFiles[0]?.preview
}

export type GetReservationDateTimeParams = { date: any; slot: number }

export const getReservationDateTime = (reservation: GetReservationDateTimeParams, options?: { weekday?: boolean; year?: boolean }) => {
  const startsAt = getTimeFromSlot(reservation.slot)
  const date = dayjs.parseZone(reservation.date)
  const weekday = options.weekday ? date.clone().format('ddd, ') : ''
  const dayMonth = date.clone().format('D MMMM')
  const year = options.year ? date.clone().format(' YYYY') : ''
  return `${weekday + dayMonth + year}, ${startsAt}`
}

export type GetRestaurantNameParams = { restaurant?: { name: string }; eventRestaurant?: { name: string } }

export const getRestaurantName = (reservable: GetRestaurantNameParams) => {
  if (!reservable) {
    return ''
  }
  return reservable.eventRestaurant?.name || reservable.restaurant?.name || ''
}

export const getDescriptivePriceRange = position => {
  if (position !== undefined) {
    switch (position) {
      case 0:
        return gt.tp('PriceRange', 'less than 40 PLN')
      case 1:
        return gt.tp('PriceRange', '40 - 60 PLN')
      case 2:
        return gt.tp('PriceRange', '60 - 80 PLN')
      case 3:
        return gt.tp('PriceRange', '80 - 100 PLN')
      case 4:
        return gt.tp('PriceRange', '100 - 150 PLN')
      case 5:
        return gt.tp('PriceRange', 'more than 150 PLN')
      default:
        return gt.tp('PriceRange', 'no data')
    }
  }
  return gt.tp('PriceRange', 'no data')
}

export const getFERDateRange = (startsAt: Scalars['ISO8601DateTime'], endsAt: Scalars['ISO8601DateTime']): DateRange => ({
  startsOn: startsAt.substring(0, 10),
  endsOn: endsAt.substring(0, 10),
})

export const getDailyDateRange = (startsAt: dayjs.Dayjs) => ({
  startsOn: dayjsToString(startsAt),
  endsOn: dayjsToString(startsAt.clone().add(1, 'month')),
})

export const formatMenuLabel = (index: number, label: string, forceCustomLabels = false) =>
  forceCustomLabels ? label : `Menu ${ALPHABET[index]}`
