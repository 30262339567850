import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const StyledTile = styled.div<{
  gridColumn: string
  horizontal: boolean
  withBorder: boolean
}>`
  grid-column: ${({ gridColumn }) => gridColumn};
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: ${({ theme, withBorder }) => withBorder && `1px solid ${theme.colors.gray[200]}`};
  font-family: Poppins, sans-serif;

  ${mediaQueryUp('l')} {
    grid-column: ${({ horizontal }) => horizontal && '1 / -1'};
    flex-direction: ${({ horizontal }) => horizontal && 'row'};
  }
`

export const ContentContainer = styled.div<{
  horizontal: boolean
}>`
  display: flex;
  flex-direction: column;

  ${mediaQueryUp('l')} {
    width: ${({ horizontal }) => horizontal && '100%'};
  }
`
