import { Form } from 'formik'

import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const StyledLoginModal = styled.div`
  display: flex;
  border: none;
  height: inherit;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};

  ${mediaQueryUp('m')} {
    height: fit-content;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
  }
`

export const StyledFormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  padding-top: 2.5rem;

  ${mediaQueryUp('m')} {
    justify-content: flex-start;
    padding: 4rem;
  }

  @media (min-width: 950px) {
    width: 54%;
  }

  ${mediaQueryUp('l')} {
    padding: 4rem;
  }
`

export const StyledImageWrapper = styled(StyledFormWrapper)`
  display: none;

  @media (min-width: 950px) {
    display: block;
    width: 46%;
    position: relative;
  }
`

export const OtherLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.green[500]};
  white-space: 'nowrap';
`

export const StyledToastContainer = styled.div`
  width: 100%;
  white-space: normal;

  & > * {
    margin: -0.5rem 0 -1rem;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
`

export const StyledFormLinkContainer = styled.div`
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  width: fit-content;
`
