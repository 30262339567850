// @ts-strict-ignore
import { useRouter } from 'next/router'

import { inUserPanel, paths } from '~/constants'
import { User, useSignOutMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'

import {
  ButtonsContainer,
  MailLabel,
  MenuButtonContainer,
  MenuButtonIcon,
  MenuButtonLabel,
  Separator,
  SignOutButtonContainer,
  UserContainer,
  UserMenuContainer,
  UsernameLabel,
} from './UserMenuShards'
import { USER_MENU_ROUTES } from './UserMenuUtils'

interface UserMenuProps extends React.InputHTMLAttributes<HTMLInputElement> {
  user: Pick<User, 'firstName' | 'lastName' | 'email'>
  hideUser?: boolean
  width?: 'full' | 'fit'
  onClick?: () => void
}

const MenuButton = ({ label, icon, href, onClick }) => {
  const router = useRouter()

  const content = (
    <MenuButtonContainer active={href && href === router.asPath} onClick={onClick}>
      <MenuButtonIcon icon={icon} />
      <MenuButtonLabel as='div'>{label}</MenuButtonLabel>
    </MenuButtonContainer>
  )

  if (href) {
    return <Link href={href}>{content}</Link>
  }

  return <>{content}</>
}

const UserMenuView = ({ user, hideUser = false, width = 'fit', onClick }: UserMenuProps) => {
  const { tp } = gt
  const router = useRouter()
  const [signOut] = useSignOutMutation({
    onCompleted: () => (inUserPanel(router.route) ? router.push(paths.home) : router.reload()),
  })

  return (
    <UserMenuContainer width={width}>
      {!hideUser && (
        <>
          <UserContainer>
            <UsernameLabel as='div'>
              {user.firstName} {user.lastName}
            </UsernameLabel>
            <MailLabel title={user.email}>{user.email}</MailLabel>
          </UserContainer>
          <Separator />
        </>
      )}
      <ButtonsContainer>
        {USER_MENU_ROUTES.map(routeObj => (
          <MenuButton
            key={routeObj.href}
            label={tp('UserMenuItem', routeObj.label)}
            icon={routeObj.icon}
            href={routeObj.href}
            onClick={onClick}
          />
        ))}
      </ButtonsContainer>
      <Separator />
      <SignOutButtonContainer onClick={() => signOut()}>
        <MenuButton onClick={onClick} label={tp('User', 'Logout')} icon={'logout'} href={undefined} />
      </SignOutButtonContainer>
    </UserMenuContainer>
  )
}

export default UserMenuView
