import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

export const NoSlotsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  background-color: ${({ theme }) => theme.colors.gray[50]};
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  height: fit-content;
  white-space: normal;

  ${mediaQueryUp('l')} {
    margin-top: 0;
    padding: 2.375rem 4rem;
  }

  & button {
    margin-top: 1rem;
  }

  ${Heading6} {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray[700]};
    width: 80%;
    margin: 0 auto;
  }
`
