import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`

export const Badge = styled.span<{ background?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  height: calc(0.8rem + 4px);
  width: auto;
  min-width: calc(0.8rem + 4px);
  padding: 0.1rem 0.2rem 0;
  border-radius: 1rem;
  background: ${({ background, theme }) => background || theme.colors.orange[400]};
  color: ${({ theme }) => theme.colors.gray[0]};
  font-size: ${({ theme }) => theme.text.fontSize.xxs};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wider};
  border: 2px solid ${({ theme }) => theme.colors.gray[0]};
`
