import { Heading3, Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { StyledVerifyAccountModal } from './VerifyAccountModalShards'

interface VerifyAccountModalViewProps {
  handleClose: () => void
}

const VerifyAccountModalView = ({ handleClose }: VerifyAccountModalViewProps) => {
  return (
    <StyledVerifyAccountModal>
      <Heading6 fontWeight='regular'>{gt.tp('CheckYourEmailModal', 'Thank you for joining us!')}</Heading6>
      <Heading3 fontWeight='semiBold'>{gt.tp('CheckYourEmailModal', 'Verify your account with a confirmation link')}</Heading3>
      <Heading6 fontWeight='regular'>{gt.tp('CheckYourEmailModal', "We've sent a confirmation link to your email address.")}</Heading6>
      <Button
        label={gt.tp('ForgotPasswordModal', 'Okay, close')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        onClick={handleClose}
      />
    </StyledVerifyAccountModal>
  )
}

export default VerifyAccountModalView
