import styled from '@emotion/styled'

import { TagVariant } from '../Tag'
import { StyledTag } from '../Tag/TagShards'

interface StyledLabelFragmentProps {
  isFaded: boolean
  isActive: boolean
}

export const StyledPriceTag = styled(StyledTag)<{
  variant: TagVariant
  isActive: boolean
}>`
  ${({ theme, size, variant }) =>
    (variant === 'filter' || (variant === 'default' && size === 'large')) &&
    `
    height: 2.25rem;
    padding: .5rem 1rem;
    border-radius: .375rem;
    border: 1px solid ${theme.colors.gray[200]};
    background: ${theme.colors.gray[0]};
    font-weight: ${theme.text.fontWeight.regular};
    font-size: ${theme.text.fontSize.base};
    &:hover {
      border-color: ${theme.colors.gray[400]};
    };
  `}
  ${({ theme, size, variant }) =>
    variant === 'plainText' &&
    `
      font-size: ${size === 'small' ? theme.text.fontSize.s : theme.text.fontSize.m};
      color: ${theme.colors.gray[800]};
  `}
  ${({ theme, isActive }) =>
    isActive &&
    `
    &, &:hover {
      border-color: ${theme.colors.orange[400]};
    }`}
`

export const StyledLabelFragment = styled.span<StyledLabelFragmentProps>`
  transition: 0.3s;
  ${({ theme, isActive, isFaded }) =>
    isActive ? `color: ${isFaded ? theme.colors.orange[300] : theme.colors.orange[400]};` : `color: ${isFaded && theme.colors.gray[500]};`};
`

export const ButtonWrapper = styled.button`
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`
