import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1, Heading3, Heading6 } from '~/theme/utils/typography'
import { Separator } from '~/utils/grid'

import { SearchWrapper } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelShards'
import Icon from '~/shared/atoms/Icon'

export const SelectSeparator = styled(Separator)`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: block;

  ${mediaQueryUp('l')} {
    display: none;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
`

export const CollapseHeading = styled(Heading6)`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
`

export const CollapseDescription = styled(Body1)`
  color: ${({ theme }) => theme.colors.gray[800]};
`

const ICON_SIZE = '1rem'

export const MenuIconWrapper = styled.div`
  position: relative;
  pointer-events: auto;
  grid-area: menu;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};

  &::after {
    content: '';
    position: absolute;
    width: calc(${ICON_SIZE} * 3);
    height: calc(${ICON_SIZE} * 3);
    top: calc(${ICON_SIZE} * -1);
    left: calc(${ICON_SIZE} * -1);
  }
`

export const MenuIcon = styled(Icon)`
  display: block;
  width: ${ICON_SIZE};
  height: fit-content;
  color: ${({ theme }) => theme.colors.gray[900]};
`

export const ProductContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
`

export const ProductGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'slider'
    'header'
    'banner'
    'select'
    'content'
    'extra-content';
  grid-template-columns: 100%;
  gap: 0;

  @media (min-width: 732px) {
    width: 700px;
    margin: 0 auto;
    grid-template-columns: 700px;
  }

  ${mediaQueryUp('l')} {
    max-width: 1280px;
    width: 100%;
    grid-template-areas:
      'header header'
      'banner banner'
      'slider select'
      'content select'
      'extra-content select';
    grid-template-columns: minmax(450px, 1fr) 25.75rem;
    column-gap: 1.25rem;
    row-gap: 0;
    grid-template-rows: repeat(3, minmax(0, min-content));
    margin: 0 auto;
  }

  @media (min-width: 1280px) {
    padding: 0;
  }
`

export const HeaderContainer = styled.div`
  grid-area: header;
  width: 100%;
  padding: 1rem 0 0;
  min-height: 0;
  height: fit-content;

  @media (min-width: 732px) {
    padding-left: 0;
    padding-right: 0;
  }

  ${mediaQueryUp('l')} {
    padding: 2.5rem 0;
  }
`

export const FestivalBannerContainer = styled.div`
  width: 100%;
  height: fit-content;
  grid-area: banner;
  cursor: pointer;
  position: relative;
  max-width: 1280px;
  transform: translateX(-1rem);
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  // ${mediaQueryUp('m')} {
  //   transform: translateX(-2.875rem);
  // }

  @media (min-width: 732px) {
    transform: unset;
  }

  ${mediaQueryUp('l')} {
    margin-top: 0;
    margin-bottom: 2.5rem;

    & img {
      width: 100%;
    }
  }
`

export const SelectContainer = styled.div`
  grid-area: select;
  padding: 1.5rem 1rem;
  display: block;
  max-width: 100%;

  @media (min-width: 732px) {
    padding: 1.5rem 0;
  }

  ${mediaQueryUp('l')} {
    padding: 0;
    padding-bottom: 1.5rem;
    width: 25.75rem;
    overflow: visible;
  }
`

export const RestaurantSelectContainer = styled(SelectContainer)`
  padding: 1.5rem 0;
`

export const SliderContainer = styled.div`
  grid-area: slider;
  width: 100vw;
  overflow: hidden;
  position: relative;
  height: fit-content;
  min-height: 0;

  ${mediaQueryUp('m')} {
    margin: 2.5rem auto 0;
    width: 100%;
  }

  ${mediaQueryUp('l')} {
    max-width: 100%;
    margin: 0;
  }
`

export const RestaurantSliderContainer = styled(SliderContainer)`
  transform: translateX(-1rem);

  ${mediaQueryUp('m')} {
    // transform: translateX(-2.875rem);
    width: 100vw;
    margin-top: 0;
  }

  @media (min-width: 732px) {
    transform: unset;
    width: 100%;
    margin-top: 2.5rem;
  }

  ${mediaQueryUp('l')} {
    margin-top: 0;
  }
`

export const ContentContainer = styled.div`
  grid-area: content;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0;

  & > * > h2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  & > * > h3,
  & > * > h4 {
    //  margin-top: 2.25rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 732px) {
    padding: 0;
  }

  ${mediaQueryUp('l')} {
    padding: 3rem 0 0;
    width: 100%;
  }
`

export const RestaurantContentContainer = styled(ContentContainer)`
  padding: 0;
`

export const FERsSection = styled.div`
  padding: 0 0 2rem;

  ${mediaQueryUp('l')} {
    padding-bottom: 3rem;
  }
`

export const ChefsMenuSection = styled.div`
  padding: 0 0 2rem;

  & > h2 {
    margin-bottom: 2.5rem;
    max-width: 32rem;
  }

  ${mediaQueryUp('l')} {
    padding-bottom: 3rem;
  }

  & > h4,
  & > h3 {
    margin-bottom: 1.5rem;

    @media (min-width: 732px) {
      width: 700px;
      margin: 0 auto 1.5rem;
    }

    ${mediaQueryUp('l')} {
      width: 100%;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const ExtraContentContainer = styled.div`
  grid-area: extra-content;

  @media (min-width: 732px) {
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  ${mediaQueryUp('l')} {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.25rem;
    margin: 0;
    padding-bottom: 3rem;
  }

  ${mediaQueryUp('xl')} {
    padding: 2rem 0;
  }
`

// export const SimilarRestaurantsContainer = styled.div`
//   padding: 1.5rem 1rem;

//   @media (min-width: 732px) {
//     padding: 1.5rem 0;
//     margin: 0 auto;
//     width: 700px;
//   }

//   ${mediaQueryUp('l')} {
//     padding: 3.5rem 0;
//     width: 100%;
//     max-width: 1280px;
//   }
// `

export const ModalContainer = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.gray[0]};

  ${mediaQueryUp('m')} {
    min-height: unset;
    width: unset;
    background-color: transparent;
  }
`

export const SlotsContainer = styled.div`
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.gray[50]};

  ${mediaQueryUp('m')} {
    padding: 16px 24px 32px 24px;
    border-radius: 0 0 16px 16px;
  }

  ${SearchWrapper} {
    ${mediaQueryUp('m')} {
      padding: 1.6rem 0.2rem;
    }
  }
`

export const SlotsHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.black[200]};

  ${mediaQueryUp('m')} {
    gap: 16px;
    padding: 24px 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }
`

export const SlotsTitle = styled.h2`
  font-size: ${({ theme }) => theme.text.fontSize.xl};
`

export const ModalInnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  width: 100%;
  margin: 0 auto;
  box-shadow:
    0px 0px 1px 0px #0000000a,
    0px 2px 6px 0px #0000000a,
    0px 10px 20px 0px #0000000a;

  ${mediaQueryUp('m')} {
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-radius: 16px;
    position: sticky;
    position: -webkit-sticky;
    top: 1.5rem;
    z-index: 2;
  }
`

export const OrSeparator = styled.div`
  height: 1px;
  width: calc(100% - 2rem);
  margin-left: 2rem;
  margin-top: calc(1.5rem - 0.25rem);
  margin-bottom: -1.5rem;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  position: relative;

  ${mediaQueryUp('m')} {
    margin-left: auto;
    margin-right: auto;
    // transform: translateX(2.875rem);
  }

  @media (min-width: 732px) {
    transform: translateX(0);
  }

  & div {
    text-align: center;
    height: 3rem;
    background: ${({ theme }) => theme.colors.gray[0]};
    position: absolute;
    cursor: default;
    top: -1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50% - 2rem);
    width: 4rem;
    z-index: 2;
    font-weight: ${({ theme }) => theme.text.fontWeight.medium};
    font-size: ${({ theme }) => theme.text.fontSize.base};
    line-height: ${({ theme }) => theme.text.lineHeight.tight};
  }

  ${mediaQueryUp('l')} {
    display: none;
  }
`

export const ModalHeader = styled.div`
  padding: 2rem 0 0 1rem;
`

export const ModalTitle = styled(Heading3)`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
`

export const ModalDescription = styled.span`
  color: ${({ theme }) => theme.colors.gray[700]};
`
