// @ts-strict-ignore
import { getStaticFilePath } from '~/utils/helpers'

import { isRestaurantOptionProps, isTagOptionProps } from '~/shared/molecules/SmartSearch/SmartSearchUtils'
import TagBarView from '~/shared/molecules/TagBar'

import { RestaurantOptionProps, SmartSearchOptionProps, TagOptionProps } from '../SmartSearchTypes'

import { RestaurantName, RestaurantOptionContainer, StyledImage, TagOptionContainer, TagOptionPill } from './OptionsShards'

export const TagOption = ({ label, innerProps, innerRef }: TagOptionProps) => {
  return (
    <TagOptionContainer ref={innerRef} {...innerProps}>
      <TagOptionPill text={label} />
    </TagOptionContainer>
  )
}

export const RestaurantOption = ({ data, innerRef, innerProps, isFocused }: RestaurantOptionProps) => {
  const restaurant = data.details
  const img = restaurant.imageFiles.find(img => img.position === 0)
  const { name } = restaurant

  return (
    <RestaurantOptionContainer ref={innerRef} isFocused={isFocused} {...innerProps}>
      <StyledImage src={img ? img.thumbnail : getStaticFilePath('assets/svg/tile-placeholder.svg')} alt={name} />
      <div>
        <RestaurantName>{name}</RestaurantName>
        <TagBarView size='small' restaurant={restaurant} maxBaseTagCount={3} sections={['price']} />
      </div>
    </RestaurantOptionContainer>
  )
}

export default (props: SmartSearchOptionProps) => {
  if (isTagOptionProps(props)) {
    return <TagOption {...props} />
  }

  if (isRestaurantOptionProps(props)) return <RestaurantOption {...props} />

  return null
}
