import { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash'

import { gt } from '~/locale'

import { DescriptionText, ExpandTextButton } from './ChefsMenuDescriptionShards'
import { toggleExpandButton } from './ChefsMenuDescriptionUtils'

interface DescriptionViewProps {
  description: string
}

const DescriptionView = ({ description }: DescriptionViewProps) => {
  const [expandText, setExpandText] = useState(false)
  const [showReadMoreBtn, setShowReadMoreBtn] = useState(false)
  const descriptionText = useRef(null)

  useEffect(() => {
    const btn = descriptionText?.current
    toggleExpandButton(btn, setShowReadMoreBtn)
    const debouncedHandleResize = debounce(() => {
      toggleExpandButton(btn, setShowReadMoreBtn)
    }, 500)
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])

  return (
    <>
      <DescriptionText ref={descriptionText} as='div' fontWeight='regular' expandText={expandText}>
        {description}
      </DescriptionText>
      {showReadMoreBtn && (
        <ExpandTextButton onClick={() => setExpandText(!expandText)} as='button' fontWeight='medium'>
          {expandText ? gt.tp('ProductDetails', 'Read less') : gt.tp('ProductDetails', 'Read more')}
        </ExpandTextButton>
      )}
    </>
  )
}

export default DescriptionView
