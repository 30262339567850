// @ts-strict-ignore
import { css } from '@emotion/react'

interface FontDefinition {
  name: string
  version: string
  type: string
  style: string
  weight: number[]
  format: string[]
}

const definition: FontDefinition[] = [
  {
    name: 'Poppins',
    version: 'v19',
    type: 'latin-ext_latin',
    style: 'normal',
    weight: [400, 500, 600],
    format: ['woff', 'woff2'],
  },
  {
    name: 'Merriweather',
    version: 'v30',
    type: 'latin-ext_latin',
    style: 'normal',
    weight: [300, 400],
    format: ['woff', 'woff2'],
  },
  {
    name: 'SofiaPro',
    version: 'v30',
    type: 'latin-ext_latin',
    style: 'normal',
    weight: [400, 600],
    format: ['woff', 'woff2'],
  },
]

const fonts = definition.reduce((acc, font) => {
  // Build file name
  const fileName = `${font.name.toLowerCase()}-${font.version}-${font.type}`

  font.weight.forEach(weight => {
    const fontBaseName = `${fileName}-${weight}`

    const formats = font.format.reduce((acc, format) => {
      const url = `url('/fonts/${fontBaseName}.${format}') format('${format}')`
      acc.push(url)

      return acc
    }, [])

    acc.push(`
      @font-face {
        font-family: ${font.name};
        font-style: ${font.style};
        font-weight: ${weight};
        font-display: swap;
        src: local(''),
          ${formats.join(', ')};
      }
    `)
  })

  return acc
}, [])

export const fontFace = css`
  ${fonts}
`
