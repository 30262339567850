import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1, Heading5, Heading6 } from '~/theme/utils/typography'

import { Container as RestaurantSliderContainer } from '~/components/Restaurant/RestaurantPage/RestaurantSlider/RestaurantSliderShards'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.gray[0]};
  width: 100%;
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  max-width: 100vw;
  overflow: hidden;

  ${mediaQueryUp('m')} {
    margin: 0 auto;
    width: calc(100vw - 6rem);
    max-width: 500px;
    height: fit-content;
    border-radius: 1rem;
    padding: 1.5rem;
  }

  ${mediaQueryUp('l')} {
    max-width: 1018px;
    width: 100%;
    padding: 1.5rem;
  }
`

export const SelectWrapper = styled(Wrapper)`
  ${mediaQueryUp('m')} {
    width: fit-content;
    padding: 0;
  }
`

export const Grid = styled.div`
  & > div:nth-of-type(2) {
    grid-area: select;
  }

  ${mediaQueryUp('m')} {
    display: grid;

    grid-template-columns: 100%;
    grid-template-areas: 'profile';
  }

  ${mediaQueryUp('l')} {
    gap: 1.5rem;
    grid-template-columns: minmax(300px, 1fr) 25.75rem;
    grid-template-areas: 'profile select';
    padding-left: 1rem;
  }
`

export const Profile = styled.div`
  grid-area: profile;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  h5,
  h6 {
    margin-bottom: 0.5rem;

    ${mediaQueryUp('l')} {
      margin-bottom: 1rem;
    }
  }
`

export const ImageContainer = styled.div`
  max-width: 100vw;
  overflow: hidden;
  position: relative;
  height: auto;
  aspect-ratio: 16 / 9;
  margin-bottom: 1.5rem;
  order: 1;

  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray[0]};

    ${mediaQueryUp('m')} {
      display: none;
    }
  }

  ${RestaurantSliderContainer} {
    aspect-ratio: unset;
    height: 100%;
  }

  .alice-carousel {
    height: 100%;

    ${mediaQueryUp('l')} {
      min-width: 400px;
    }

    > div {
      height: 100%;
    }

    &__wrapper {
      height: 100%;
    }
  }

  .alice-carousel__stage-item {
    width: fill-available !important;
    width: -webkit-fill-available !important;
  }

  ${mediaQueryUp('m')} {
    border-radius: 0.75rem;
  }

  ${mediaQueryUp('l')} {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    order: 2;
  }
`

export const Details = styled.div`
  padding: 0 1rem;
  order: 2;
  overflow: hidden;

  & > div:first-of-type {
    margin-bottom: 1.5rem;
  }

  ${mediaQueryUp('m')} {
    padding: 0;
  }

  ${mediaQueryUp('l')} {
    order: 1;
  }

  & ${Heading5} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

export const PartnersBox = styled.div`
  color: ${({ theme }) => theme.colors.gray[700]};
  line-height: ${({ theme }) => theme.text.lineHeight.stretched};
  padding: 1rem;

  & ${Heading6} {
    text-align: center;
  }
`

export const Menus = styled.div`
  padding: 0 1rem;
  order: 4;

  ${mediaQueryUp('m')} {
    padding: 0;
    & > div:last-of-type {
      margin-top: 2rem;
    }
  }
`

export const MenuLabel = styled.div`
  background: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[700]};
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  margin: 1.5rem 0;
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wider};
  font-size: ${({ theme }) => theme.text.fontSize.s};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};

  ${mediaQueryUp('l')} {
    margin-top: 0;
  }
`

export const UpperHeading = styled(Body1)`
  color: ${({ theme }) => theme.colors.green[600]};
  text-transform: uppercase;
`

export const CheckoutCard = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  padding: 2rem;
  grid-area: select;

  ${Heading6} {
    color: ${({ theme }) => theme.colors.gray[700]};
    margin-bottom: 0.5rem;
  }

  & button {
    margin-top: 0.5rem;
  }
`

export const MenuPreface = styled(Body1)`
  margin-bottom: 1rem;
`
