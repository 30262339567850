import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body2, Heading6 } from '~/theme/utils/typography'

import Link from '~/shared/atoms/Link'
import { StyledContent } from '~/shared/molecules/LocationMenu/LocationModal/LocationModalShards'

export const Wrapper = styled.div`
  width: 21.5rem;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: ${({ theme }) => theme.commons.shadow.m};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  ${mediaQueryUp('m')} {
    border-radius: 0.5rem;
    overflow: hidden;
  }
`

export const ReservationStyledContent = styled(StyledContent)`
  display: flex;
  padding: 1rem;
`

export const StyledInfobox = styled.div`
  padding-right: 3rem;
  white-space: nowrap;

  ${Heading6} {
    margin-bottom: 0.375rem;
  }

  ${Body2} {
    line-height: ${({ theme }) => theme.text.lineHeight.normal};
    margin-bottom: 0.25rem;
    max-width: 14.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const StyledEventName = styled.div`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.text.fontSize.s};
  line-height: ${({ theme }) => theme.text.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wider};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[600]};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 15rem;
`

export const StyledImage = styled.img`
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  object-fit: cover;
`

export const StyledLinks = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
  width: 100%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
`

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  font-size: ${({ theme }) => theme.text.fontSize.m};
  line-height: ${({ theme }) => theme.text.lineHeight.loose};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
`
