import dynamic from 'next/dynamic'

import { MapWrapper } from './MapShards'

export interface MapViewProps {
  latitude: number
  longitude: number
}

const MapView = ({ latitude, longitude }: MapViewProps) => {
  if (!latitude || !longitude) return null
  const MapContent = dynamic(() => import('./MapUtils'), {
    loading: () => <p>A map is loading</p>,
    ssr: false,
  })
  return (
    <MapWrapper id='map'>
      <MapContent latitude={latitude} longitude={longitude} />
    </MapWrapper>
  )
}

export default MapView
