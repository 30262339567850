// @ts-strict-ignore
import cookie, { CookieSerializeOptions } from 'cookie'
import { NextResponse } from 'next/server'
import { Primitive } from 'type-fest'

const getExistingCookieStrings = res => {
  const existingHeaders = res.getHeader('Set-Cookie')
  let existingCookieStrings = []

  if (typeof existingHeaders === 'string') {
    existingCookieStrings = [existingHeaders]
  } else if (Array.isArray(existingHeaders)) {
    existingCookieStrings = existingHeaders
  }
  return existingCookieStrings
}

export const ssrAppendCookieStringToHeader = (cookieString: string, res) => {
  const existingCookieStrings = getExistingCookieStrings(res)

  res.setHeader('Set-Cookie', [cookieString, ...existingCookieStrings])
}

export const setCookies = (key: string, value: Primitive, res: NextResponse = undefined, options: CookieSerializeOptions = {}) => {
  const _opts = {
    path: '/',
    ...options,
  }
  const cookieString = cookie.serialize(key, value.toString(), _opts)
  if (res) {
    ssrAppendCookieStringToHeader(cookieString, res)
  } else {
    document.cookie = cookieString
  }
}

// TODO: better value type
export const setCookiesAsJson = (key: string, value: any, res: any, options: CookieSerializeOptions = {}) => {
  const defaultOptions: CookieSerializeOptions = {
    sameSite: 'lax',
    secure: true,
  }

  const finalOptions = { ...defaultOptions, ...options }

  setCookies(key, JSON.stringify(value), res, finalOptions)
}

export const getCookie = (req, key: string) => {
  let cookieString
  if (req && req.headers && req.headers.cookie) {
    cookieString = req.headers.cookie
  } else if (typeof document !== 'undefined') {
    cookieString = document.cookie
  }
  return cookieString && cookie.parse(cookieString)[key] ? cookie.parse(cookieString)[key] : null
}

export const getCookieAsParsedJson = (req, key: string) => {
  const cookie = getCookie(req, key)
  return cookie ? JSON.parse(cookie) : null
}

export const deleteCookie = (res, key: string, options = {}) => {
  const expiredOptions = { path: '/', expires: new Date(), ...options }
  if (res) {
    res.setHeader('Set-Cookie', cookie.serialize(key, ''), expiredOptions)
  } else {
    document.cookie = cookie.serialize(key, '', expiredOptions)
  }
}
