import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading3, Heading6 } from '~/theme/utils/typography'

export const StyledMarketingConsentModal = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  padding-top: 5rem;

  ${mediaQueryUp('s')} {
    padding: 3.5rem;
    padding-bottom: 2.5rem;
  }

  ${mediaQueryUp('m')} {
    border-radius: 0.75rem;
    max-width: 35rem;
  }

  & > ${Heading3}, & > ${Heading6} {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  & > ${Heading6} {
    color: ${({ theme }) => theme.colors.gray[700]};
  }

  & > button {
    margin-top: 0.5rem;
  }
`
