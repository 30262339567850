// @ts-strict-ignore
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const breakpoints = {
  s: '375px',
  m: '600px',
  l: '1205px',
  xl: '1440px',
}

export const mediaQueryIsTablet = `(max-width: ${breakpoints.l})`

export const mediaQueryUp = bp => `@media (min-width: ${breakpoints[bp]})`
export const mediaQueryUpVal = val => `(min-width: ${val}px)`
export const mediaQueryDownVal = val => `(max-width: ${val}px)`

export const mediaQueryIsMobile = `(max-width: ${breakpoints.m})`

export const BackgroundWrapper = styled.div`
  width: 100vw;
  background: ${({ theme }) => theme.colors.gray[20]};
`

export const Separator = styled.div<{ insideGrid?: boolean; maxWidth?: string }>`
  height: 1px;
  background: ${({ theme }) => theme.colors.gray[100]};
  width: 100vw;
  position: relative;
  display: block;
  transform: translateX(-1rem);

  ${({ insideGrid = false }) =>
    insideGrid &&
    css`
      @media (min-width: 732px) {
        transform: translateX(calc((100vw - 700px) / -2));
      }
    `}

  ${mediaQueryUp('l')} {
    transform: translateX(-3.75rem);
    width: 100vw;
  }

  ${mediaQueryUp('xl')} {
    transform: ${({ maxWidth }) => `translateX(calc((100vw - ${maxWidth || '1280'}px)/-2))`};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-width: 100vw;
    z-index: 1;
    height: 1rem;
    background-color: transparent;
    box-shadow: ${({ theme }) => theme.commons.shadow.xxs};
  }
`

export const UniversalContainer = styled.div<{ extraPaddingOnMobile?: boolean; gray?: boolean }>`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1440px;
  margin: 0 auto;

  ${({ extraPaddingOnMobile }) =>
    extraPaddingOnMobile &&
    `
    ${mediaQueryUp('m')} {
      padding-left: 2.875rem;
      padding-right: 2.875rem;
    }
  `}

  ${({ gray, theme }) =>
    gray &&
    `
    background: ${theme.colors.gray[20]};
  `}

  ${mediaQueryUp('l')} {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  ${mediaQueryUp('xl')} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

export const Container = styled.div<{ paddingTop: number }>`
  width: 100%;
  padding: ${({ paddingTop = 0 }) => `${paddingTop}px 16px 0`};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;

  ${mediaQueryUp('s')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 20px 0`};
  }

  ${mediaQueryUp('m')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 46px 0`};
    grid-column-gap: 16px;
  }

  ${mediaQueryUp('l')} {
    padding: ${({ paddingTop }) => `${paddingTop}px 60px 0`};
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 24px;
  }

  ${mediaQueryUp('xl')} {
    width: 1200px;
    margin: 0 auto;
    padding: ${({ paddingTop }) => `${paddingTop}px 120px 0`};
    grid-template-columns: repeat(12, 1fr);
  }
`
