import styled from '@emotion/styled'

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[0]};

  .leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    z-index: 0;
  }
`
