import { Heading3, Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { StyledCheckYourEmailModal } from './CheckYourEmailModalShards'

interface CheckYourEmailModalViewProps {
  handleClose: () => void
}

const CheckYourEmailModalView = ({ handleClose }: CheckYourEmailModalViewProps) => {
  return (
    <StyledCheckYourEmailModal>
      <Heading3 fontWeight='semiBold'>{gt.tp('CheckYourEmailModal', 'Check your email')}</Heading3>
      <Heading6 fontWeight='regular'>{gt.tp('CheckYourEmailModal', "We've sent a password reset link to your email address")}</Heading6>
      <Button
        label={gt.tp('ForgotPasswordModal', 'Okay, close')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        onClick={handleClose}
      />
    </StyledCheckYourEmailModal>
  )
}

export default CheckYourEmailModalView
