import dayjs from 'dayjs'

import { dayjsToString } from '~/utils/graphqlDataFormatters'

import { FestivalEdition } from '~/generated/graphql'
import { smallModalPropsTemplate } from '~/shared/molecules/Modal'

export const festivalModalProps = {
  ...smallModalPropsTemplate,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
}

export const ALL_DAY_SLOT = 0

export const getDateRange = (startsAt: FestivalEdition['startsAt']) => {
  const festivalStartsAt = dayjs.parseZone(startsAt.substring(0, 10), 'YYYY-MM-DD')
  const today = dayjs.parseZone()
  const startsOn = festivalStartsAt.isBefore(today) ? today : festivalStartsAt
  const endsOn = startsOn.clone().add(60, 'day')

  return {
    startsOn: dayjsToString(startsOn),
    endsOn: dayjsToString(endsOn),
  }
}
