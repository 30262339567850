import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading4 } from '~/theme/utils/typography'

export const StyledSearchSlotsModal = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  display: flex;
  flex-direction: column;

  ${mediaQueryUp('m')} {
    border-radius: 0.5rem;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const RestaurantName = styled.span`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const SlotsText = styled(Heading4)`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
`
