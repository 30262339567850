// @ts-strict-ignore
import dayjs from 'dayjs'

import { setCookies } from '~/utils/cookie'

import localeModules from '>/locale/dist'
import { gt } from '~/locale'

import { COOKIE } from './../constants/common'

const logDebug = getLogger('locale:gt', 'debug')
const logWarn = getLogger('locale:gt', 'warn')

export type LocaleType = undefined | boolean | keyof typeof localeModules

export async function loadLocales(locale: LocaleType, localeMessages?: Record<string, string>) {
  if (typeof locale === 'boolean') {
    return false
  }

  const languagePack = localeModules[locale]
  const stringLocale = String(locale)

  if (localeMessages) {
    gt.update(stringLocale, localeMessages)
    logDebug(`Locale ${stringLocale} has been preloaded`)
    if (typeof locale === 'number') {
      return `${locale}`
    }

    return locale
  }

  if (!languagePack) {
    logWarn(`Module ${stringLocale} not found`)
    throw new Error('Unsupported locale')
  }

  logDebug(`Start to load ${stringLocale} locale`)
  const { default: messages } = await languagePack()

  logDebug(`Locale ${stringLocale} has been loaded`)
  gt.update(stringLocale, messages)

  if (typeof window !== 'undefined') {
    setCookies(COOKIE.NEXT_LOCALE, stringLocale, null, { expires: dayjs().add(10, 'years').toDate() })
  }

  if (typeof locale === 'number') {
    return `${locale}`
  }

  return locale
}
