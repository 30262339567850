// @ts-strict-ignore
import ReservationOfferCard from '~/components/Reservation/ReservationOfferCard'
import { ReservationOfferCardProps } from '~/components/Reservation/ReservationOfferCard/ReservationOfferCardTypes'
import RestaurantCard from '~/components/Restaurant/RestaurantCard'
import { RestaurantListElementFragment } from '~/generated/graphql'

import { CardWrapper } from './UniversalCollectionShards'
import { LeanRestaurant } from './UniversalCollectionTypes'

export const toRestaurant = (restaurants: Array<LeanRestaurant>): JSX.Element[] =>
  restaurants.map(restaurant => {
    return (
      <CardWrapper key={restaurant.id}>
        <RestaurantCard subtitle={restaurant.description} restaurant={restaurant as RestaurantListElementFragment} role='presentation' />
      </CardWrapper>
    )
  })

export const toReservationOffer = (offers: ReservationOfferCardProps[]): JSX.Element[] =>
  offers.map(offer => {
    return (
      <CardWrapper key={offer.id}>
        <ReservationOfferCard {...offer} role='presentation' />
      </CardWrapper>
    )
  })
