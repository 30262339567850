// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { breakpoints, mediaQueryIsTablet } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import Dropdown, { DropdownItem } from '~/shared/atoms/Dropdown'
import Icon from '~/shared/atoms/Icon'

import { useModal } from '../Modal'

import { ButtonIcon, Container } from './LocationMenuShards'
import { ModalMargin } from './LocationMenuTypes'
import { desktopModalProps, mobileModalProps, useLocation } from './LocationMenuUtils'
import LocationModal from './LocationModal'
import { extraPagePaddingForXl } from './LocationModal/LocationModalShards'

interface Props {
  showModal?: boolean
}

const LocationMenu: React.FC<Props> = ({ showModal = true }) => {
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const [showList, setShowList] = useState<boolean>(false)
  const { selectedRegion, regions = [], setRegion, isRegionSelectedByUser, setIsRegionSelectedByUser } = useLocation()
  const [modalMargin, setModalMargin] = useState<ModalMargin | null>(null)
  const modal = useModal()
  const menuRef = useRef(null)
  const handleChange = (id: string) => {
    setRegion(regions.find(r => r.id === id))
    setShowList(false)
  }

  const items: DropdownItem[] = regions.map(region => ({
    label: region.name,
    type: 'button',
    key: region.id,
  }))

  useEffect(() => {
    if (menuRef?.current && !isTablet && !modalMargin) {
      setTimeout(() => {
        const newRect = menuRef.current.getBoundingClientRect()

        const top = newRect.bottom + 16
        const maxContentWidth = parseInt(breakpoints.xl, 10)
        const pageMargin = (window.innerWidth - maxContentWidth) / 2
        const left = window.innerWidth > maxContentWidth ? newRect.left - pageMargin - extraPagePaddingForXl : newRect.left

        setModalMargin({ top, left })
      })
    }
  }, [menuRef, modalMargin, isTablet])

  useEffect(() => {
    !isRegionSelectedByUser &&
      showModal &&
      modal.open(
        isTablet ? mobileModalProps : desktopModalProps,
        <LocationModal
          onCloseClick={() => {
            modal.close()
            setIsRegionSelectedByUser(true)
          }}
          onChangeClick={() => {
            modal.close()
            setIsRegionSelectedByUser(true)
            setShowList(true)
          }}
          region={selectedRegion.name}
          margin={modalMargin ?? undefined}
        />
      )
  }, [isRegionSelectedByUser, showModal, modalMargin, isTablet])

  if (!selectedRegion) {
    return null
  }

  return (
    <div ref={menuRef}>
      <Dropdown
        placement='down'
        items={items}
        selectedKey={selectedRegion.id}
        show={showList}
        onClickOutside={() => setShowList(false)}
        onChange={handleChange}
        trigger='programmatic'>
        <Container onClick={() => setShowList(v => !v)}>
          <Heading6 fontWeight='medium'>{selectedRegion.name}</Heading6>
          <ButtonIcon active={showList}>
            <Icon icon='arrow' />
          </ButtonIcon>
        </Container>
      </Dropdown>
    </div>
  )
}

export default LocationMenu
