// @ts-strict-ignore
import { paths, RESERVABLE_TYPES } from '~/constants'

export const getProductProfileUrl = (product, type) => {
  if (type === RESERVABLE_TYPES.EXTRA) {
    const { slug } = product
    return paths.singleChefsMenu({ slug })
  }
  if (type === RESERVABLE_TYPES.FER) {
    const { slug } = product.restaurant
    return paths.singleRestaurant({ slug })
  }
}
