import { ReactNode } from 'react'

import { Body1, Body2, Heading6 } from '~/theme/utils/typography'

import { fontWeight } from '~/theme/text'

import { LinkSize } from './LinkTypes'

type FontWeight = keyof typeof fontWeight

export const getLinkLabel = (size: LinkSize): React.FC<{ fontWeight?: FontWeight; children?: ReactNode }> =>
  ({
    small: Body2,
    regular: Body1,
    big: Heading6,
  })[size]

export const getLinkFontWeight = (size: LinkSize): FontWeight =>
  (
    ({
      small: 'semiBold',
      regular: 'medium',
      big: 'regular',
    }) as Record<LinkSize, FontWeight>
  )[size]
