// @ts-strict-ignore
import React from 'react'

import { BorderedButton, Button, DropdownIconWrapper, Title } from './DropdownShards'
import { CustomStyles, DropdownItem } from './DropdownTypes'

export const dropdownItem: Record<DropdownItem['type'], React.FC> = {
  button: Button,
  title: Title,
  withTopBorder: BorderedButton,
}

export const elementHeight = 38.5

export const menuVerticalOffset = 8

export const calculateListHeight = (listSize: number, elementHeight: number) =>
  `${Math.min(listSize, 6) * elementHeight + elementHeight * 0.6}px`

export const toListElement = ({
  item,
  onChange,
  selectedKey,
  isVisible,
  customStyles,
}: {
  item: DropdownItem
  onChange?: (key: string | number) => void
  selectedKey: string | number
  isVisible: boolean
  customStyles: CustomStyles
}) => {
  const { type = 'button', label, key, icon } = item
  const handleChange = () => onChange(key)
  const Item: React.FC<any> = dropdownItem[type]
  const isSelected = isVisible && key === selectedKey

  return (
    <Item key={key} selected={isSelected} onClick={handleChange} hasIcon={!!icon} customStyles={customStyles}>
      {icon && (
        <DropdownIconWrapper selected={isSelected} unfilterableColor={customStyles.unfilterableIconColor}>
          {icon}
        </DropdownIconWrapper>
      )}
      {label && <span>{label}</span>}
    </Item>
  )
}
