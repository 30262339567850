import styled from '@emotion/styled'

import { breakpoints, mediaQueryUp, pagePaddings } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import { ModalMargin } from '../LocationMenuTypes'

const pageMargin = `((100vw - ${breakpoints.xl}) / 2)`
export const extraPagePaddingForXl = (pagePaddings.xl - pagePaddings.l) * 16

export const Wrapper = styled.div<{ margin?: ModalMargin }>`
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: ${({ theme }) => theme.commons.shadow.m};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-radius: 0.75rem;
  margin: auto;

  ${mediaQueryUp('l')} {
    margin-top: ${({ margin }) => `${margin?.top}px` || 0};
    margin-left: ${({ margin }) => `${margin?.left}px` || 0};
  }

  ${mediaQueryUp('xl')} {
    margin-left: calc(${pageMargin} + ${extraPagePaddingForXl}px + (${({ margin }) => `${margin?.left}px` || 0}));
  }
`

export const StyledContent = styled.div`
  width: 100%;
  padding: 1.5rem;

  ${mediaQueryUp('s')} {
    width: fit-content;
  }
`

export const StyledInfobox = styled.div`
  white-space: nowrap;

  ${mediaQueryUp('s')} {
    padding-right: 3rem;
  }

  ${Heading6} {
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
  }
`

export const StyledRegionName = styled.div`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
`

export const StyledActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  padding-top: 0.625rem;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};

  & > * {
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  ${mediaQueryUp('s')} {
    flex-wrap: nowrap;
  }
`
