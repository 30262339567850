var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"local"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { processEnv } from '~/utils/env'

import config from '~/config'

if (config().sentryDsn) {
  Sentry.init({
    dsn: config().sentryDsn,
    enabled: !!config().sentryDsn,
    environment: config().sentryEnv,
    maxBreadcrumbs: 50,
    debug: false,
    release: config().sentryRelease,
    beforeSend(event, hint) {
      const error = hint.originalException
      // Modify fingerprint for graphQL errors
      if (error && Object.getOwnPropertyNames(error).includes('graphQLErrors')) {
        event.fingerprint = ['{{ default }}', '{{ transaction }}', String((error as Error).message)]
      }
      return event
    },
    tracesSampler: _samplingContext => {
      switch (processEnv.NEXT_ENV) {
        case 'production':
          return 0.1
        default:
          return 1.0
      }
    },
  })
}
