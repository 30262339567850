import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const MediaSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 2.5rem 0;
`

export const Header = styled.h1`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  text-transform: uppercase;
  letter-spacing: 0.03rem;
`

export const Logos = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  ${mediaQueryUp('m')} {
    gap: 1rem;
    justify-content: flex-start;
  }
`

export const Logo = styled.li`
  width: 10.1875rem;
  height: 5rem;
  cursor: pointer;

  ${mediaQueryUp('m')} {
    width: 12.3125rem;
    height: 6rem;
  }
`
