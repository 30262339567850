import { StyledLoader, StyledPill } from './PillShards'
import { PillViewProps } from './PillTypes'

const PillView = ({ text, disabled = false, loading = false, ...props }: PillViewProps) => (
  <StyledPill loading={loading} disabled={loading || disabled} {...props}>
    {loading ? <StyledLoader color={loading ? 'gray' : 'white'} className={props.className} /> : text}
  </StyledPill>
)

export default PillView
