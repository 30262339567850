// @ts-strict-ignore
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Heading6 } from '~/theme/utils/typography'

export const Wrapper = styled.div`
  width: 100%;

  & > ${Heading6} {
    margin-bottom: 0.5rem;
  }
`

export const Content = styled.div<{ active?: boolean; error?: boolean; disabled?: boolean }>`
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme, error }) => (error ? theme.colors.gray[700] : theme.colors.gray[1000])};
  border-radius: 0.5rem;
  border: 1px solid
    ${({ theme, active, error }) => {
      if (error) return theme.colors.red[400]
      return active ? theme.colors.orange[400] : theme.colors.gray[200]
    }};
  transition: 0.2s ease-in-out border-color 0.2s;
  height: 3rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};

  ${Heading6} {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.commons.shadow.xxs};
    border-color: ${({ theme, active }) => (active ? theme.colors.orange[400] : theme.colors.gray[500])};
  }
`

export const SelectIconWrapper = styled.div<{ unfilterableColor?: boolean }>`
  display: flex;
  justify-content: center;
  margin-right: 0.875rem;

  img {
    transition: color 0.2s;
    width: 1.25rem;

    ${({ unfilterableColor, theme }) =>
      !unfilterableColor &&
      css`
        filter: ${theme.filters.gray[700]};

        &:hover {
          filter: ${theme.filters.gray[800]};
        }
      `}
  }
`

export const ActionWrapper = styled.div<{ active }>`
  width: 0.875rem;
  height: 0.75rem;
  background: ${({ theme }) => theme.colors.gray[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  margin-left: 0.5rem;

  img {
    width: 0.5rem;
    filter: ${({ theme }) => theme.filters.gray[500]};
    transform: rotate(${({ active }) => (active ? 180 : 0)}deg);
    transition: 0.1s ease-in-out;
  }
`
