// @ts-strict-ignore
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { AutoplacementData } from '../Tooltip/TooltipTypes'

import { ShowOnTriggerPlacement } from './ShowOnTriggerTypes'
import { getWrapperPositionStyle } from './ShowOnTriggerUtils'

export const Trigger = styled.div`
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  position: relative;

  &::before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 300%;
    background: transparent;
    pointer-events: none;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${mediaQueryUp('m')} {
      width: 300%;
    }
  }
`

export const Wrapper = styled.div<{
  rect?: DOMRect
  contentWidth?: number
  placementData: ShowOnTriggerPlacement | AutoplacementData
  show: boolean
  showOnHover: boolean
  verticalOffset?: number
}>`
  position: absolute;
  ${({ rect, placementData, contentWidth, verticalOffset }) =>
    rect && getWrapperPositionStyle(rect, placementData, contentWidth, verticalOffset)};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 20;

  ${({ show }) =>
    show &&
    css`
      opacity: 100;
      pointer-events: all;
    `}

  ${({ showOnHover }) =>
    showOnHover &&
    css`
      &:hover {
        opacity: 100;
        pointer-events: all;
      }
    `}
`
