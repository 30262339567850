import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Wrapper = styled.div`
  padding-bottom: 1rem;
`

export const BannersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin: auto;
  padding: 1.5rem 0;
  overflow: hidden;

  ${mediaQueryUp('l')} {
    padding: 3rem 0 2.5rem;
  }
`

export const CollectionWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 1rem 0;

  ${mediaQueryUp('l')} {
    padding: 2.5rem 0;
  }
`
