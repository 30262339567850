import { fontWeight } from '~/theme/text'

import { InputHeight, InputWidth } from './InputTypes'

export const inputWidth: Record<InputWidth, string> = {
  fit: 'fit-content',
  full: '100%',
}

export const inputHeight: Record<InputHeight, string> = {
  normal: '48px',
  big: '52px',
}

export const inputFontWeight: Record<InputHeight, number> = {
  normal: fontWeight.regular,
  big: fontWeight.medium,
}
