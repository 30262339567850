import { components, ContainerProps } from 'react-select'

import styled from '@emotion/styled'

import { getListStyles } from '~/shared/atoms/Dropdown/DropdownShards'
import { menuVerticalOffset } from '~/shared/atoms/Dropdown/DropdownUtils'
import { Content } from '~/shared/atoms/Select/SelectShards'
import { SmartSearchOption } from '~/shared/molecules/SmartSearch/SmartSearchTypes'

export const StyledControl = styled(Content)`
  max-height: 3rem;
`

export const StyledMenu = styled.div`
  ${({ theme }) => getListStyles({ show: true, theme })};
  padding: 0;
  margin-top: ${menuVerticalOffset}px;
  max-width: 100%;
  overflow-x: hidden;
`

export const SelectContainer = styled((props: ContainerProps<SmartSearchOption, false>) => <components.SelectContainer {...props} />)`
  z-index: 20;
`
