// @ts-strict-ignore
import { getDescriptivePriceRange } from '~/utils/graphqlDataFormatters'

import { TagFragmentFragment } from '~/generated/graphql'
import { gt } from '~/locale'

export const getRestaurantDetails = (chef: string, establishmentYear: number, tags: TagFragmentFragment[]) => {
  return [
    {
      title: gt.tp('RestaurantPage', 'Cuisine'),
      content: tags?.find(tag => tag.type === 'Cuisine')?.name,
    },
    { title: gt.tp('RestaurantPage', 'Chef'), content: chef },
    {
      title: gt.tp('RestaurantPage', 'Atmosphere'),
      content: tags?.find(tag => tag.type === 'Atmosphere')?.name,
    },
    {
      title: gt.tp('RestaurantPage', 'Year opened'),
      content: establishmentYear,
    },
    {
      title: gt.tp('RestaurantPage', 'Average price for two'),
      content: getDescriptivePriceRange(tags?.find(tag => tag.type === 'Price')?.position),
    },
  ]
}

export const toggleExpandButton = (btn, setShowReadMoreBtn) => {
  if (btn.offsetHeight < btn.scrollHeight || btn.offsetWidth < btn.scrollWidth) {
    setShowReadMoreBtn(true)
  } else {
    setShowReadMoreBtn(false)
  }
}
