import { NextRequest, NextResponse } from 'next/server'

import { COOKIE } from '~/constants'

const PUBLIC_FILE = /\.(.*)$/

export const landingPageRoutes = ['/restaurantweek', '/chefsmenu']

export async function middleware(req: NextRequest) {
  const response = NextResponse.next()

  if (process.env.CLIENT_VERSION?.includes('old') && req.nextUrl.href.includes('/rc/_next')) {
    return NextResponse.rewrite(req.nextUrl.href.replace('/rc/_next', '/_next'))
  }

  if (req.nextUrl.pathname.endsWith('/sw.js')) {
    response.headers.set('Service-Worker-Allowed', '/')
    return response
  }

  if (req.nextUrl.pathname.startsWith('/_next') || req.nextUrl.pathname.includes('/api/') || PUBLIC_FILE.test(req.nextUrl.pathname)) {
    return
  }

  if (landingPageRoutes.some(route => req.nextUrl.pathname.startsWith(route))) {
    return NextResponse.redirect(`${process.env.CLUB_URL}${req.nextUrl.pathname}`, 307)
  }

  // @ts-ignore wrong typings
  const nextLocale = req.cookies.get(COOKIE.NEXT_LOCALE)
  if (nextLocale && nextLocale !== req.nextUrl.locale) {
    const search = req.nextUrl.searchParams.toString()
    return NextResponse.redirect(new URL(`/${nextLocale}${req.nextUrl.pathname}${search ? '?' + search : ''}`, req.url))
  }

  return response
}
