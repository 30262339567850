import { Form } from 'formik'

import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading3, Heading6 } from '~/theme/utils/typography'

export const StyledForgotPasswordModal = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  padding: 1rem;
  padding-top: 2.5rem;

  ${mediaQueryUp('s')} {
    padding: 2rem;
  }

  ${mediaQueryUp('m')} {
    border-radius: 0.75rem;
    max-width: 31rem;
  }

  & > ${Heading3} {
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
    width: 80%;
  }

  & > ${Heading6} {
    color: ${({ theme }) => theme.colors.gray[700]};
    margin-bottom: 0.75rem;
    width: 80%;
  }
`

export const StyledForm = styled(Form)`
  & > button {
    margin-top: 2rem;
  }
`

export const StyledToastContainer = styled.div`
  width: 100%;
`
