import { ReactNode } from 'react'
import AppleSignin, { AppleAuthResponse } from 'react-apple-signin-auth'

import { noop } from '~/utils/function'

import LoginButton from '~/components/User/LoginButton'
import config from '~/config'
import { AppleSignInMutation, useAppleSignInMutation } from '~/generated/graphql'
import { gt } from '~/locale'

type AppleLoginButtonProps = {
  onLogin: (res: AppleSignInMutation | null | undefined) => void
  label?: string
}

export const AppleLoginButton = ({ onLogin, label }: AppleLoginButtonProps) => {
  const [appleSignIn] = useAppleSignInMutation()
  return (
    <AppleSignin
      render={(props: { onClick: () => void; children: ReactNode }) => {
        return <LoginButton icon='apple' label={label ?? gt.tp('Login', 'Sign in with Apple')} {...props} />
      }}
      authOptions={{
        clientId: config().appleServiceId,
        scope: 'email name',
        redirectURI: config().clubUrl + '/callback/apple',
        usePopup: true,
      }}
      onError={noop}
      onSuccess={async (res: AppleAuthResponse) => {
        const user = res.user?.name
        const signInResponse = await appleSignIn({
          variables: { appleIdentityToken: res.authorization.id_token, firstName: user?.firstName, lastName: user?.lastName },
        })

        onLogin(signInResponse.data)
      }}
      uiType='light'
    />
  )
}
