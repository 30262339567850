import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'

import { LeanReservableExtraFragment } from '~/generated/graphql'
import { gt } from '~/locale'

import { ContentWrapper, StyledCollapse, Wrapper } from './ProductDetailsPanelShards'
import { RestaurantDetails } from './ProductDetailsPanelTypes'
import { getContent, sectionData } from './ProductDetailsPanelUtils'

interface ProductDetailsPanelViewProps {
  restaurant: RestaurantDetails
  reservableExtra?: LeanReservableExtraFragment
  className?: string
  defaultShow?: (keyof typeof sectionData)[]
  sections: (keyof typeof sectionData)[]
}

const ProductDetailsPanelView = ({ restaurant, reservableExtra, className, defaultShow, sections }: ProductDetailsPanelViewProps) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })

  return (
    <Wrapper className={className}>
      {sections.map(section => {
        const content = getContent(section, restaurant, reservableExtra, isMobile)
        return (
          content && (
            <StyledCollapse
              key={section}
              title={gt.tp('ProductPage', sectionData[section].title)}
              icon={sectionData[section].icon}
              size='small'
              defaultShow={defaultShow?.includes(section)}>
              <ContentWrapper>{content}</ContentWrapper>
            </StyledCollapse>
          )
        )
      })}
    </Wrapper>
  )
}

export default ProductDetailsPanelView
