// @ts-strict-ignore
import { useMediaQuery } from 'react-responsive'

import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import { breakpoints } from '~/theme/utils/grid'
import { getTimeFromSlot, isSlotToday } from '~/utils/graphqlDataFormatters'

import { defaultSearchSettings, MAX_NUMBER_OF_ATTENDEES, MIN_NUMBER_OF_ATTENDEES } from '~/constants'
import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import { SelectCalendar } from '~/shared/atoms/Select'
import ReactSelect from '~/shared/atoms/SelectionDropdown/SelectionDropdownContainer'
import { ISingleSelectOption } from '~/shared/atoms/SelectionDropdown/SelectionDropdownUtils'
import { useModal } from '~/shared/molecules/Modal'
import SmartSearchContainer from '~/shared/molecules/SmartSearch'
import { SmartSearchOption } from '~/shared/molecules/SmartSearch/SmartSearchTypes'

import { ContactOption } from '../../../components/Listing/ListingPageContent/ListingPageContentTypes'

import ExtraGuestsModal from './ExtraGuestsModal'
import { modalProps } from './ExtraGuestsModal/ExtraGuestsModalUtils'
import { Container, NameInput, NameInputInner, Row, SearchIconWrapper, SubmitButton, Wrapper } from './SearchShards'
import { SearchSettings } from './SearchTypes'
import { getDefaultDailySlots, peopleCount } from './SearchUtils'

type BaseProps = {
  availableDates?: dayjs.Dayjs[]
  highlightedDates?: dayjs.Dayjs[]
  minDate?: dayjs.Dayjs
  value: SearchSettings
  onChange(value: SearchSettings): void
  loading: boolean
  onSubmit?(): void
  hideOnSelect?: boolean
  fieldsToHide?: Array<string>
  variant?: 'equal-width' | 'shrink-middle' | 'expand-last'
  hasWrapper?: boolean
  selectableSlots?: Array<number>
  onCalendarPrevClick?(): void
  onCalendarNextClick?(): void
  onCalendarClose?(): void
  calendarLoading?: boolean
  orientationBreakpoint?: string
  hideYear?: boolean
  showExtraGuest?: boolean
  minPeopleCount?: number
  maxPeopleCount?: number
  forceSearchParams?: boolean
  contactOptions?: Array<ContactOption>
}

type Props =
  | (BaseProps & { withSmartSearch: true; onSmartSearchChange: (option: SmartSearchOption) => void })
  | (BaseProps & { withSmartSearch?: false; onSmartSearchChange?: never })

const Search: React.FC<Props> = ({
  onChange,
  value,
  loading,
  onSubmit,
  availableDates,
  highlightedDates,
  minDate,
  hideOnSelect,
  fieldsToHide = [],
  variant,
  hasWrapper = false,
  selectableSlots = getDefaultDailySlots(dayjs().isSame(value.date, 'day')),
  onCalendarPrevClick,
  onCalendarNextClick,
  onCalendarClose,
  calendarLoading,
  orientationBreakpoint,
  hideYear,
  showExtraGuest = false,
  withSmartSearch,
  onSmartSearchChange,
  minPeopleCount = MIN_NUMBER_OF_ATTENDEES,
  maxPeopleCount = MAX_NUMBER_OF_ATTENDEES,
  forceSearchParams: forceParams = true,
  contactOptions,
}) => {
  const router = useRouter()
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })
  const modal = useModal()
  const handleChange = (key: keyof SearchSettings, keyValue) => {
    if (key === 'date' && value.slot && !isSlotToday(value.slot)) {
      onChange({
        ...value,
        slot: selectableSlots[0],
        date: keyValue,
      })
    } else {
      onChange({
        ...value,
        [key]: keyValue,
      })
    }
  }

  const handlePeopleCountChange = (selectedOption: ISingleSelectOption | null) => {
    if (selectedOption?.value === maxPeopleCount + 1) {
      modal.open(modalProps, <ExtraGuestsModal handleClose={modal.close} contactOptions={contactOptions} />)
      return
    }
    handleChange('peopleCount', selectedOption?.value)
  }

  const showField = (field: string) => !fieldsToHide.includes(field)
  const dateCnt = showField('date') && (
    <SelectCalendar
      availableDates={availableDates}
      highlightedDates={highlightedDates}
      minDate={minDate}
      value={value.date}
      onChange={value => handleChange('date', value)}
      hideOnChange={hideOnSelect}
      onPrevMonthClick={onCalendarPrevClick}
      onClose={onCalendarClose}
      onNextMonthClick={onCalendarNextClick}
      loading={calendarLoading}
      style={{ maxHeight: '3rem' }}
      hideYear={hideYear}
    />
  )

  const slotOptions: ISingleSelectOption[] = selectableSlots.map(slot => ({ value: +slot, label: getTimeFromSlot(+slot) }))
  const defaultSlot = defaultSearchSettings(router.query).slot
  const peopleCountOptions: ISingleSelectOption[] = peopleCount(gt, showExtraGuest, minPeopleCount, maxPeopleCount).map(
    ({ key, label }) => ({ label: label.toString(), value: +key })
  )

  const slotValue = selectableSlots.includes(value.slot)
    ? value.slot
    : selectableSlots.find(slot => slot >= defaultSearchSettings({ forceParams }).slot)

  const dateInSeparateRow = (!variant || isMobile) && showField('date')

  return (
    <Wrapper hasWrapper={hasWrapper}>
      <Container orientationBreakpoint={orientationBreakpoint || breakpoints.m} variant={variant}>
        {dateInSeparateRow && dateCnt}
        <Row>
          {!dateInSeparateRow && dateCnt}
          {showField('slot') && slotOptions && (
            <ReactSelect
              value={slotOptions.find(option => option.value === slotValue)}
              onChange={v => handleChange('slot', v?.value)}
              options={selectableSlots.map(slot => ({ value: +slot, label: getTimeFromSlot(+slot) }))}
              placeholder={!value.slot && forceParams ? slotOptions[0].label || getTimeFromSlot(defaultSlot) : gt.tp('Search', 'Any time')}
            />
          )}
          {showField('peopleCount') && (
            <ReactSelect
              options={peopleCountOptions}
              onChange={handlePeopleCountChange}
              value={peopleCountOptions.find(option => option.value === value.peopleCount)}
              placeholder={peopleCountOptions[0].label || defaultSearchSettings({ forceParams })?.peopleCount.toString()}
            />
          )}
        </Row>
        {showField('name') && (
          <NameInput>
            {withSmartSearch ? (
              <SmartSearchContainer onOptionPress={onSmartSearchChange} />
            ) : (
              <NameInputInner
                height='normal'
                width='full'
                placeholder={`${gt.tp('Search', 'Restaurant, kitchen, dish...')}`}
                value={value.name}
                onChange={v => handleChange('name', v.target.value)}
                iconLeft={
                  <SearchIconWrapper>
                    <Icon icon='search' />
                  </SearchIconWrapper>
                }
              />
            )}
          </NameInput>
        )}
        {showField('submit') && (
          <SubmitButton loading={loading} width='full' color='green' label={`${gt.tp('Search', 'Discover')}`} onClick={onSubmit} />
        )}
      </Container>
    </Wrapper>
  )
}

export default Search
