import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1, Body2 } from '~/theme/utils/typography'

export const MODAL_BP = 1080
export const breakpointMediaQuery = '(min-width: 1080px)'

export const ImageWrapper = styled.div`
  grid-area: photo;
  position: relative;
  border-radius: 0.5rem;
  width: 100vw;
  height: auto;
  aspect-ratio: 3 / 2;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  margin-bottom: 0.5rem;

  ${mediaQueryUp('m')} {
    width: 100%;
    max-width: 100%;
    border-radius: 0.5rem;
  }
`

export const UpperHeading = styled(Body1)`
  color: ${({ theme }) => theme.colors.orange[600]};
  text-transform: uppercase;
`

export const Section = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  & ${mediaQueryUp('l')} {
    width: 18.75rem;
    max-width: 18.75rem;
  }

  @media (min-width: 1280px) {
    width: 25.75rem;
  }
`

export const Upper = styled(Section)`
  padding-bottom: 2rem;
  gap: 0.5rem;
`

export const Lower = styled(Section)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
  padding-top: 1.5rem;
  gap: 1rem;

  ${Body2} {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`
