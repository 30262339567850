// @ts-strict-ignore
import { ButtonWrapper, StyledLabelFragment, StyledPriceTag } from './PriceTagShards'
import { PriceTagViewProps } from './PriceTagTypes'
import { DOLLAR_SIGNS, getPriceRange } from './PriceTagUtils'

export const PriceTagView = ({ position, size = 'small', variant = 'default', onClick, isActive, ...props }: PriceTagViewProps) => {
  const priceRange = getPriceRange(position)
  const content = (
    <StyledPriceTag isActive={isActive} variant={variant} size={variant === 'filter' ? 'large' : size} {...props}>
      <StyledLabelFragment isFaded={false} isActive={isActive}>
        {DOLLAR_SIGNS.substring(0, priceRange)}
      </StyledLabelFragment>
      <StyledLabelFragment isFaded={true} isActive={isActive}>
        {DOLLAR_SIGNS.substring(priceRange)}
      </StyledLabelFragment>
    </StyledPriceTag>
  )

  if (onClick) return <ButtonWrapper onClick={onClick}>{content}</ButtonWrapper>

  return content
}
