import { processEnv } from '~/utils/env'

import { StatusMessages } from '~/components/Checkout/SuccessPage/StatusBox/StatusBoxUtils'
import { gt as GT } from '~/locale'

type Copy = {
  rwReservationStatusMessages: (gt: typeof GT) => StatusMessages
  giftStatusMessages: (gt: typeof GT) => StatusMessages
  beforeYouVisitTitle: (gt: typeof GT) => string
  beforeYouVisitDefaultDescription: (gt: typeof GT) => string
  VOUCHER_FAQ_QUESTIONS_LIST: { rw: { title: string; description: string }[] }
}

export type ClubConfig = {
  aboutPageEnabled: boolean
  redirectHeroToLandingEnabled: boolean
  sideEventListingPageEnabled: boolean
  careerPageEnabled: boolean
  chmEnabled: boolean
  blogEnabled: boolean
  blikEnabled: boolean
  d2dEnabled: boolean
  extraMarketingConsentEnabled: boolean
  footerAwardsEnabled: boolean
  otherFestivalsEnabled: boolean
  restaurantsListingEnabled: boolean
  logoCentered: string
  logoLeft: string
  directCardPaymentEnabled: boolean
  klarnaEnabled: boolean
  paypoEnabled: boolean
  showFooterCopyright: boolean
  useRWInsteadOfRC: boolean
  copy: Copy
  visaMobileEnabled: boolean
}

export const getClubConfig = () => {
  return CLUB_CONFIG
}

export const redirectIfFeatureDisabledInConfig = {
  notFound: true as const,
}

const VOUCHER_FAQ_QUESTIONS_LIST = {
  rw: [
    {
      title: 'When and in what form will I receive my reservation code?',
      description:
        'Immediately after purchasing your invitation, you will receive a beautiful gift PDF with a code for RestaurantWeek® Spring 2025 edition.',
    },
    {
      title: 'How to use the code?',
      description:
        'Use the code in the payment window at the end of the booking. Paste the code in the last booking step after expanding the Add Discount Code field. The value of the code reduces the final amount to be paid. The code can be used for a larger reservation by paying the difference.',
    },
    {
      title: 'Until when is the code valid?',
      description:
        'The code can only be used during the RestaurantWeek® Spring 2025 Festival. The code is unique and one-time use and expires on April 16, 2025. Only one code can be used per reservation. The code cannot be combined with other promotions/codes. Codes are not refundable in any form or convertible into money. Terms and conditions for refunds are set forth in the terms and conditions of Early Reservations.',
    },
  ],
}

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Booking confirmed!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

const beforeYouVisitTitle = (gt: typeof GT) => gt.tp('SuccessPage', 'Read before you visit')

const beforeYouVisitDefaultDescription = (gt: typeof GT) =>
  gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  )

export const CLUB_CONFIG: ClubConfig = {
  aboutPageEnabled: true,
  careerPageEnabled: true,
  blogEnabled: true,
  chmEnabled: true,
  blikEnabled: true,
  d2dEnabled: true,
  extraMarketingConsentEnabled: true,
  footerAwardsEnabled: true,
  redirectHeroToLandingEnabled: false,
  sideEventListingPageEnabled: true,
  restaurantsListingEnabled: true,
  otherFestivalsEnabled: true,
  logoCentered: '/assets/logo/logo-club.svg',
  logoLeft: '/assets/logo/logo-club.svg',
  directCardPaymentEnabled: true,
  klarnaEnabled: false,
  paypoEnabled: true,
  showFooterCopyright: true,
  useRWInsteadOfRC: false,
  copy: {
    rwReservationStatusMessages: rwReservationStatusMessages,
    giftStatusMessages: giftStatusMessages,
    beforeYouVisitTitle: beforeYouVisitTitle,
    beforeYouVisitDefaultDescription: beforeYouVisitDefaultDescription,
    VOUCHER_FAQ_QUESTIONS_LIST: VOUCHER_FAQ_QUESTIONS_LIST,
  },
  visaMobileEnabled: true,
}

export const isDemo = processEnv.NEXT_ENV === 'demo'
