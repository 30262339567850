// @ts-strict-ignore
import { GroupBase } from 'react-select'
import AsyncSelect from 'react-select/async'
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types'

import { hasWindow } from '~/utils/helpers'

import { gt } from '~/locale'

import Group from './Group'
import NoOptionsMessage from './NoOptionsMessage'
import Option from './Options/OptionsView'
import { SelectContainer } from './SmartSearchShards'
import { SmartSearchOption } from './SmartSearchTypes'
import { Control, IconWrapper, Menu, Placeholder } from './SmartSearchUtils'

interface Props {
  search: (inputValue: string) => Promise<OptionsOrGroups<SmartSearchOption, GroupBase<SmartSearchOption>>>
  onOptionPress: (option: SmartSearchOption) => void
}

export default ({ search, onOptionPress }: Props) => (
  <AsyncSelect
    cacheOptions={false}
    loadingMessage={() => gt.tp('Search', 'Searching...')}
    loadOptions={search}
    formatGroupLabel={group => gt.tp('Search', group.label)}
    placeholder={gt.tp('Search', 'Restaurant, kitchen, dish...')}
    isMulti={false}
    onChange={onOptionPress}
    styles={{
      valueContainer: base => ({
        ...base,
        paddingLeft: 0,
      }),
      menuList: base => ({
        ...base,
        maxWidth: '100%',
      }),
    }}
    openMenuOnClick={false}
    tabSelectsValue={false}
    menuPortalTarget={hasWindow() && (document.querySelector('#__next') || document.querySelector('#root'))}
    controlShouldRenderValue={false}
    components={{
      Group,
      Option,
      NoOptionsMessage,
      Control,
      DropdownIndicator: IconWrapper,
      Placeholder,
      Menu,
      SelectContainer,
    }}
  />
)
