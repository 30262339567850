import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6, lineClamp } from '~/theme/utils/typography'

export const DescriptionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 2rem;

  ${mediaQueryUp('m')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-gap: 2.5rem;
    margin-bottom: 2.5rem;
  }
`

export const ItemCategory = styled.span`
  color: ${({ theme }) => theme.colors.gray[700]};
`

export const ItemContent = styled.span`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const Item = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};

  ${mediaQueryUp('m')} {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    & > * {
      width: 100%;
      font-weight: ${({ theme }) => theme.text.fontWeight.regular};
    }
  }
`

export const DescriptionText = styled(Heading6)<{ expandText: boolean }>`
  color: ${({ theme }) => theme.colors.gray[700]};
  ${({ expandText }) =>
    !expandText &&
    `
    ${lineClamp(3)}
  `}
`

export const ExpandTextButton = styled(Heading6)`
  color: ${({ theme }) => theme.colors.green[500]};
  margin-top: 0.5rem;

  ${mediaQueryUp('m')} {
    margin-top: 0.75rem;
  }
`
