import { ClubConfig } from '~/utils/clubConfig'
import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

import { paths } from '~/constants'

export const joinTheClubSchema = yup.object().shape({
  email: validationFields.email,
})

type FooterLink = {
  label: string
  href?: string
  enabledForFeatureFlag?: keyof ClubConfig
  onClick?: () => void
}

export const linkData: Array<{ title: string; links: FooterLink[] }> = [
  {
    title: 'Restaurant Club',
    links: [
      { label: 'Festivals', href: paths.festivals },
      { label: 'About us', href: paths.about, enabledForFeatureFlag: 'aboutPageEnabled' },
      { label: 'Partners', href: paths.partners },
      { label: 'Your profile', href: paths.userInformation },
      { label: 'Book a table', href: paths.restaurants, enabledForFeatureFlag: 'd2dEnabled' },
      { label: '#ChefsMenu', href: paths.chefsMenuOffers, enabledForFeatureFlag: 'chmEnabled' },
    ],
  },
  {
    title: 'Company',
    links: [
      // { label: 'For restaurants', href: paths.forRestaurateurs },
      { label: 'Help', href: paths.faq },
      { label: 'Career', href: paths.career, enabledForFeatureFlag: 'careerPageEnabled' },
      // { label: 'Become our Partner!', href: paths.partners },
    ],
  },
  {
    title: 'Information',
    links: [
      { label: 'Privacy Policy', href: paths.privacyPolicy },
      { label: 'Terms', href: paths.regulations },
      { label: 'Contact', href: paths.faq },
      { label: 'Cookies', onClick: () => window.OneTrust.ToggleInfoDisplay() },
    ],
  },
]

export const benefitsData = [
  'Discounts - the ability to use them',
  'Festivals - information when festivals start',
  'Gifts - from Festival Partners',
  'Contests - prizes to be won',
]
