// @ts-strict-ignore
import { ReactNode } from 'react'

import Image from 'next/image'

import { CSSInterpolation } from '@emotion/css'
import { css } from '@emotion/react'

import { imgProxyLoader } from '~/utils/imgproxy'

import { ImageFile } from '~/generated/graphql'

import { Row, RowImage } from './RestaurantGalleryShards'
import { GalleryRow } from './RestaurantGalleryTypes'

export const styleColumns: Record<GalleryRow, CSSInterpolation> = {
  single: css`
    grid-template-columns: 1fr;
  `,
  doubleShort: css`
    grid-template-columns: repeat(2, 1fr);
  `,
  doubleHigh: css`
    grid-template-columns: repeat(2, 1fr);

    img {
      aspect-ratio: 0.7;
    }
  `,
  triple: css`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    & > :nth-child(1) {
      grid-row: 1;
      grid-column: 1;
    }

    & > :nth-child(2) {
      grid-row: 1 / 3;
      grid-column: 2;
    }

    & > :nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }
  `,
}

const ROW_PATTERN: GalleryRow[] = ['single', 'doubleShort', 'single', 'doubleHigh', 'triple']

const ROW_WEIGHT: Record<GalleryRow, number> = {
  single: 1,
  doubleShort: 2,
  doubleHigh: 2,
  triple: 3,
}

const DIMENSIONS: Record<GalleryRow, { width: number; height: number }> = {
  single: {
    width: 1064,
    height: 709,
  },
  doubleShort: {
    width: 520,
    height: 346,
  },
  doubleHigh: {
    width: 540,
    height: 743,
  },
  triple: {
    width: 520,
    height: 359,
  },
}

export const getRows = (imageFiles: Pick<ImageFile, 'original' | 'id' | 'preview'>[]): ReactNode[] => {
  let images = [...imageFiles]
  const rows = [] as ReactNode[]
  const fullPattern = [...Array(images.length)].map(() => ROW_PATTERN).flat()

  for (let i = 0; i < fullPattern.length && images.length > 0; i++) {
    let currentPattern = fullPattern[i]
    let currentWeight = ROW_WEIGHT[currentPattern]

    while (currentWeight > images.length) {
      currentPattern = Object.entries(ROW_WEIGHT).find(([k, v]) => v === currentWeight - 1)[0] as GalleryRow
      currentWeight = ROW_WEIGHT[currentPattern]
    }

    const rowImages = images.slice(0, currentWeight)
    images = images.slice(currentWeight, images.length)

    rows.push(
      <Row type={currentPattern} key={i}>
        {rowImages.map((image, index) => {
          let { height, width } = DIMENSIONS[currentPattern]

          // Check for the second image in triple pattern and modify height
          // Height is 2 rows + gap
          if (currentPattern === 'triple' && index === 1) {
            height = height * 2 + 32
          }

          return (
            <RowImage key={image?.id}>
              <Image
                loader={imgProxyLoader}
                src={image?.original || image?.preview}
                alt='Restaurant Image'
                objectFit='cover'
                height={height}
                width={width}
              />
            </RowImage>
          )
        })}
      </Row>
    )
  }

  return rows
}
