import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  & > * {
    width: 100%;
  }

  ${mediaQueryUp('m')} {
    gap: 2.5rem;
  }
`

export const Heading = styled.div<{ fontWeight: 'medium' }>``

export const Taglist = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  column-gap: 0.75rem;
`
