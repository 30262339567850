import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

const MENU_WIDTH = '375px'

export const Wrapper = styled.div`
  display: flex;
  padding: 0;
  margin: -1.5rem -1.75rem;
  flex-wrap: wrap;
  width: ${MENU_WIDTH};
  max-width: 90vw;

  box-shadow: ${({ theme }) => theme.commons.shadow.xl};

  ${mediaQueryUp('l')} {
    width: calc(${MENU_WIDTH} * 2);
  }
`

export const Menu = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[100]};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: ${MENU_WIDTH};
  border-radius: 0.5rem;
  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  ${Heading6} {
    margin-bottom: 0.5rem;
  }
`

export const Position = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.gray[700]};
  white-space: pre-wrap;
`
