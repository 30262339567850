import { useMediaQuery } from 'react-responsive'

import { useRouter } from 'next/router'

import { mediaQueryIsMobile } from '~/theme/utils/grid'

import { mobileNavigationPaths } from '~/constants'

const useMobileNavigation = () => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const router = useRouter()
  const path = router.asPath.split('?')[0]

  const inPath = mobileNavigationPaths.some(mobilePath => path === mobilePath || path.startsWith(mobilePath))

  return isMobile && (inPath || path === '/')
}

export default useMobileNavigation
