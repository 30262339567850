// @ts-strict-ignore
import { useEffect, useState } from 'react'

import useUser from '~/utils/hooks/useUser'

import { loginModalProps } from '~/components/Restaurant/RestaurantCard/RestaurantCardUtils'
import LoginModal from '~/components/User/LoginModal'
import { useCreateFavouriteRestaurantMutation, useDestroyFavouriteRestaurantMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import { useModal } from '~/shared/molecules/Modal'
import { ActionButtonTitle } from '~/shared/molecules/ProductPageTitle/ProductPageTitleShards'

import { IconContainer, LikeButton, LikeContainer, LikeIcon, LoaderContainer, StyledLoader } from './LikeShards'

interface Props {
  restaurantId: string
  favorited: boolean
  hasTitle?: boolean
}

export const LikeView: React.FC<Props> = ({ restaurantId, favorited, hasTitle = false }) => {
  const { user } = useUser() || {}
  const modal = useModal()
  const [create, { loading: createLoading }] = useCreateFavouriteRestaurantMutation()
  const [destroy, { loading: destroyLoading }] = useDestroyFavouriteRestaurantMutation()
  const [isFavorited, setFavoritedStatus] = useState<boolean>()

  useEffect(() => {
    setFavoritedStatus(favorited)
  }, [favorited])

  const toggleFavourite = async () => {
    const action = isFavorited ? destroy : create
    await action({
      variables: { restaurantId },
      onCompleted: ({ createFavorite, destroyFavorite }) => {
        const updatedStatus = (createFavorite ?? destroyFavorite).restaurant.favorited
        setFavoritedStatus(updatedStatus)
      },
    })
  }

  const handleLikeToggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    user ? toggleFavourite() : modal.open(loginModalProps, <LoginModal handleClose={modal.close} />)
  }
  const isLoading = createLoading || destroyLoading

  return (
    <LikeContainer onClick={handleLikeToggle}>
      <LikeButton isVisible={!hasTitle} isActive={isFavorited}>
        <LoaderContainer hasTitle={hasTitle} isVisible={isLoading}>
          <StyledLoader color={isFavorited ? 'green' : 'gray'} hasTitle={hasTitle} />
        </LoaderContainer>
        <IconContainer hasTitle={hasTitle} isVisible={!isLoading}>
          <LikeIcon isFavorited={isFavorited} icon={isFavorited ? 'rounded-heart-filled' : 'rounded-heart-outlined'} />
        </IconContainer>
      </LikeButton>
      {hasTitle && (
        <ActionButtonTitle active={isFavorited} fontWeight='medium'>
          {gt.tp('RestaurantPageTitle', 'Favorite')}
        </ActionButtonTitle>
      )}
    </LikeContainer>
  )
}
