import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import badMutable from 'dayjs/plugin/badMutable'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isMoment from 'dayjs/plugin/isMoment'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

import 'dayjs/locale/en'
import 'dayjs/locale/pl'
import 'dayjs/locale/cs'

import dayjsParsezone from './dayjs-parsezone'
import { processEnv } from './env'

const extendDayjs = async locale => {
  dayjs.extend(dayjsParsezone)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(weekYear)
  dayjs.extend(weekOfYear)
  dayjs.extend(isMoment)
  dayjs.extend(localeData)
  dayjs.extend(badMutable)
  dayjs.extend(utc)
  dayjs.locale(locale || processEnv.DEFAULT_LANG || 'en')

  if (locale !== processEnv.DEFAULT_LANG) {
    await import(`dayjs/locale/${locale}.js`).then(() => {
      dayjs.locale(locale)
    })
  }
}

export default extendDayjs
