import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { UniversalContainer } from '~/theme/utils/grid'

import { paths } from '~/constants'
import {
  CollectionCategoryEnum,
  HomepageQueryQuery,
  MainPageBannerFragment,
  useBannersQuery,
  useHomepageQueryLazyQuery,
} from '~/generated/graphql'
import { useLocation } from '~/shared/molecules/LocationMenu/LocationMenuUtils'

import Banners from './Banners'
import Hero, { HeroShortcut } from './Hero'
import { BannersWrapper, CollectionWrapper, Wrapper } from './HomePageShards'
import { getHomePageData, MAIN_PAGE_TOP_COLLECTIONS_SLUG } from './HomePageUtils'
import MediaSection from './MediaSection'
import UniversalCollection from './UniversalCollection'

type CollectionEdges = NonNullable<HomepageQueryQuery['collections']['edges']>
type HomePageCollection = NonNullable<CollectionEdges[0]>['node']

interface Props {
  shortcuts: HeroShortcut[]
  banners: MainPageBannerFragment[]
  collections: HomePageCollection[]
  search: {
    availableDates?: dayjs.Dayjs[]
    highlightedDates?: dayjs.Dayjs[]
    minDateISOString?: string
    defaultPeopleCount: number
  }
}

const isNotNull = <T extends unknown>(resource: T | null): resource is NonNullable<T> => !!resource

const HomePage: React.FC<Props> = ({ shortcuts, search: { minDateISOString, ...search }, ...props }) => {
  const [fetchHomePageData, { loading }] = useHomepageQueryLazyQuery()
  const { data: bannersData } = useBannersQuery({
    ssr: false,
    variables: {
      section_slug: 'main-page-top',
    },
  })
  const { selectedRegion } = useLocation()
  const [collections, setCollections] = useState(props.collections)

  const toCollection = (collection: NonNullable<HomePageCollection>) => {
    const { title, category, restaurants, reservableExtras, festivalEdition } = collection
    const toData = <T extends Record<string, any>>(d: T[]) => d.map(data => ({ reservableExtra: { ...data } }))
    const slug = festivalEdition?.festival?.slug
    const url = slug ? paths.festivalListing(slug) : paths.singleCollection(collection)

    return (
      <UniversalContainer>
        <CollectionWrapper key={collection.id}>
          <UniversalCollection
            reservationOffers={category === CollectionCategoryEnum.Reservableextra && reservableExtras ? toData(reservableExtras) : []}
            restaurants={category === CollectionCategoryEnum.Restaurant && restaurants ? restaurants : undefined}
            rows={1}
            showMoreUrl={url}
            title={title || ''}
          />
        </CollectionWrapper>
      </UniversalContainer>
    )
  }

  const fetchRegionData = async () => {
    if (!selectedRegion?.id) return

    await fetchHomePageData({
      variables: {
        region_id: selectedRegion?.id,
        section_slug: MAIN_PAGE_TOP_COLLECTIONS_SLUG,
      },
    }).then(({ data }) => {
      if (!data) {
        return
      }

      const homePageData = getHomePageData(data)
      setCollections(homePageData.collections)
    })
  }

  useEffect(() => {
    if (loading) {
      return
    }

    fetchRegionData()
  }, [selectedRegion?.id])

  return (
    <Wrapper>
      <Hero
        shortcuts={shortcuts}
        search={{
          ...search,
          minDate: dayjs.parseZone(minDateISOString),
        }}
      />
      <UniversalContainer>
        <MediaSection />
        {bannersData?.banners && (
          <BannersWrapper>
            <Banners banners={bannersData.banners.nodes?.filter(isNotNull) ?? []} autoPlay />
          </BannersWrapper>
        )}
      </UniversalContainer>
      {collections.filter(isNotNull).map(toCollection)}
    </Wrapper>
  )
}

export default HomePage
