import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { UserMenuContainer } from '~/components/User/UserMenu/UserMenuShards'

export const Container = styled.div<{ isTablet: boolean }>`
  display: ${({ isTablet }) => (isTablet ? 'flex' : 'none')};
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: fit-content;
  background: ${({ theme }) => theme.colors.gray[0]};
  padding-top: 2.5rem;

  ${mediaQueryUp('m')} {
    padding-top: 0;
    border-radius: 1rem;
  }

  ${UserMenuContainer} {
    padding-bottom: 1.5rem;

    hr {
      margin: 0.5rem 0.75rem;
      width: calc(100% - 1.5rem);
    }
  }
`

export const Separator = styled.hr`
  border-top: ${({ theme }) => `6px solid ${theme.colors.gray[100]}`};
  width: 100%;
  margin: 0;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 1.5rem 0;
  height: fit-content;
`
