import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

export const useRouterWithLoading = () => {
  const router = useRouter()
  const [isNavigating, setIsNavigating] = useState(false)

  useEffect(() => {
    const setNavigating = () => setIsNavigating(true)
    const setNavigatingComplete = () => setIsNavigating(false)

    router.events.on('routeChangeStart', setNavigating)
    router.events.on('routeChangeComplete', setNavigatingComplete)
    router.events.on('routeChangeError', setNavigatingComplete)

    return () => {
      router.events.off('routeChangeStart', setNavigating)
      router.events.off('routeChangeComplete', setNavigatingComplete)
      router.events.off('routeChangeError', setNavigatingComplete)
    }
  }, [router])

  return {
    ...router,
    isNavigating,
  }
}
