import styled from '@emotion/styled'

export const Label = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  margin-right: 0.2rem;
`

export const ExtendLabel = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.xxs};
  margin-right: 0.2rem;
`

export const ExtendButtonLabel = styled.button`
  color: orange;
  cursor: pointer;
`
