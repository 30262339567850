// @ts-strict-ignore
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsTablet } from '~/theme/utils/grid'

import LoginModal from '~/components/User/LoginModal'
import RegisterModal from '~/components/User/RegisterModal'
import UserMenu from '~/components/User/UserMenu'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { useModal } from '../../Modal'
import { modalProps } from '../HeaderUtils'
import TopBarMenu from '../TopBarMenu'

import { Buttons, Container, Separator } from './MobileHamburgerNavShards'

const MobileHamburgerNav = ({ user, menu }) => {
  const { tp } = gt
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const modal = useModal()

  return (
    <Container isTablet={isTablet}>
      {user ? (
        <>
          <UserMenu user={user} width={'full'} onClick={modal.close} />
          <Separator />
        </>
      ) : (
        <Buttons>
          <Button
            height='big'
            color='primary'
            width='full'
            label={tp('Header', 'Sign up')}
            onClick={() => modal.open(modalProps, <RegisterModal handleClose={modal.close} />)}
          />
          <Button
            height='big'
            color='secondary'
            width='full'
            label={tp('Header', 'Login')}
            onClick={() => modal.open(modalProps, <LoginModal handleClose={modal.close} />)}
          />
        </Buttons>
      )}

      {menu || <TopBarMenu onClose={modal.close} />}
    </Container>
  )
}

export default MobileHamburgerNav
