import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { StyledTile } from '~/shared/atoms/Tile/TileShards'

export const CardWrapper = styled.div`
  display: flex;
  width: fit-content;
`

export const SliderContainer = styled.div<{
  type: 'restaurant' | 'offer'
}>`
  & ${StyledTile} {
    width: 19rem;
  }

  .alice-carousel {
    &__stage {
      display: flex;
      align-items: stretch;
    }

    &__stage-item:not(:last-child) {
      height: auto;

      ${CardWrapper} {
        padding-right: 1.25rem;
        height: 100%;
      }
    }
  }

  & > *:not(:last-child) {
    margin-bottom: 2.25rem;
  }

  ${({ type }) =>
    type === 'offer' &&
    css`
      .alice-carousel__stage-item {
        max-width: calc(305px + 1.25rem);

        &:last-child {
          max-width: 305px;
        }
      }
    `}
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
  width: fit-content;
  height: fit-content;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: auto;
  align-items: center;
`

export const LowerBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

export const Heading = styled.div`
  font-weight: 600;
  color: ${({ theme }) => `${theme.colors.gray[1000]}`};
`

export const RedirectLabel = styled.a`
  font-weight: 600;
  color: ${({ theme }) => `${theme.colors.gray[900]}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[400]}`};

  ${mediaQueryUp('m')} {
    margin-right: 1.5rem;
  }
`

export const Arrow = styled.div<{
  left: boolean
  active: boolean
}>`
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  box-shadow: ${({ theme, active }) => (active ? theme.commons.shadow.xs : 'none')};
  cursor: pointer;

  ${mediaQueryUp('m')} {
    display: flex;
  }

  img {
    height: 1.5rem;
    filter: ${({ theme, active }) => (active ? theme.filters.gray[1000] : theme.filters.gray[500])};
    transition: filter 0.3s;
    ${({ left }) => left && 'transform: rotate(180deg)'};
    pointer-events: none;
    user-select: none;
  }
`
