import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Body1, Heading4, Heading5, Heading6 } from '~/theme/utils/typography'
import { formatMenuLabel } from '~/utils/graphqlDataFormatters'

import { FullMenuFragment } from '~/generated/graphql'

import { Content, Description, Title, Wrapper } from './MenuShards'

interface Props {
  menu: FullMenuFragment
  index: number
  withTitle?: boolean
  forceLabels?: boolean
}

const MenuView = ({ menu, withTitle = true, index, forceLabels }: Props) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const { id, label, positions } = menu
  return (
    <Wrapper>
      {withTitle && (
        <Title as={isMobile ? Heading5 : Heading4} fontWeight={'semiBold'} key={id}>
          {formatMenuLabel(index, label, forceLabels)}
        </Title>
      )}
      {positions.map(position => (
        <div key={position.name}>
          <Description as={isMobile ? Body1 : Heading6} fontWeight='semiBold'>
            {position.name}:
          </Description>
          <Content as={isMobile ? Body1 : Heading6} fontWeight='regular'>
            {position.content}
          </Content>
        </div>
      ))}
    </Wrapper>
  )
}

export default MenuView
