import React from 'react'
import BaseSelect, { Props } from 'react-select'

import { Control, IconWrapper, Menu, Option } from './SelectionDropdownUtils'

export const Select = <Option extends unknown, IsMulti extends boolean = boolean>({
  styles,
  name,
  ...props
}: Props<Option, IsMulti>): JSX.Element => (
  <BaseSelect
    name={name}
    components={{
      IndicatorSeparator: () => null,
      Menu,
      Control,
      DropdownIndicator: IconWrapper,
      Option,
    }}
    isSearchable={false}
    styles={styles}
    {...props}
  />
)
