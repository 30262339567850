export const AWARDS_LOGOS = [
  {
    image: '/assets/footer/rewards/food_business_awards.png',
    alt: 'Logo Food Business Award',
  },
  {
    image: '/assets/footer/rewards/aig_multimedia.png',
    alt: 'Logo Aig Multimedia Award',
  },
  {
    image: '/assets/footer/rewards/e-gazele.png',
    alt: 'Logo E-gazele Award',
  },
  {
    image: '/assets/footer/rewards/brief.png',
    alt: 'Logo Brief Award',
  },
  {
    image: '/assets/footer/rewards/concept.png',
    alt: 'Logo Environmentally friendly concept Award',
  },
]
