import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Heading5 } from '~/theme/utils/typography'

import PillView from '~/shared/atoms/Pill'

export const TagOptionContainer = styled.div`
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
`

export const TagOptionPill = styled(PillView)`
  margin-left: 0;
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;
`

export const RestaurantOptionContainer = styled.div<{ isFocused?: boolean }>(({ theme, isFocused }) => {
  const hoverBgColor = theme.colors.orange[200]
  return css`
    display: flex;
    padding: 0.5rem 1rem;
    max-width: 100%;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: ${isFocused ? hoverBgColor : 'transparent'};

    &:hover {
      background-color: ${hoverBgColor};
    }
    &:first-child {
      margin-top: 0.5rem;
    }
  `
})

export const StyledImage = styled.img`
  width: 4.8rem;
  min-width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.5rem;
  margin-right: 1.6rem;
  object-fit: cover;
`

export const RestaurantName = styled(Heading5)`
  font-weight: 500;
  margin-bottom: 0.4rem;
`
