import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Wrapper = styled.div``

export const Title = styled.div<{ fontWeight: string }>`
  margin-bottom: 1rem;

  ${mediaQueryUp('m')} {
    margin-bottom: 1.25rem;
  }
`

export const Description = styled.div<{ fontWeight: string }>`
  margin-bottom: 1rem;
  max-width: 500px;

  ${mediaQueryUp('m')} {
    margin-bottom: 0.75rem;
  }
`

export const Content = styled(Description)`
  color: ${({ theme }) => theme.colors.gray[600]};
  white-space: pre-wrap;
`
