import { Heading3 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import FormattedPrice from '~/shared/atoms/FormattedPrice/FormattedPriceView'

import { UpperHeading } from './ChefsMenuDetailsModalShards'

type Props = {
  price: number
  additionalPriceInfo?: string
}

export const ChefsMenuSelectHeader = ({ price, additionalPriceInfo }: Props) => {
  const { tp } = gt

  return (
    <>
      <UpperHeading>{tp('Checkout', 'Limited offer')}</UpperHeading>
      <Heading3 fontWeight='medium'>{tp('Checkout', 'Book a table')}</Heading3>
      <FormattedPrice price={price} withGuest={true} additionalPriceInfo={additionalPriceInfo} />
    </>
  )
}
