// @ts-strict-ignore
import get from 'lodash/get'

import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'

import { errors } from '~/constants/errors'
import { gt } from '~/locale'

const sentryCapture = async code => {
  Sentry.captureException(new Error(`error code unknown: ${code}`))
  await Sentry.flush(2000)
}

export const formatErrorMessages = (error: ApolloError) => {
  const { networkError, graphQLErrors } = error
  const messages = []
  if (networkError) messages.push(gt.tp('Error', errors.network_error))
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const code = get(error, 'extensions.code')
      if (code === 'validation') {
        const fieldNames = Object.keys(error.extensions.details)
        fieldNames.forEach(name =>
          error.extensions.details[name].forEach(errorObject => {
            const message = get(errors, `validation.${name}.${errorObject.error}`) || errors.unknown_error
            messages.push(message)
            if (message === errors.unknown_error) sentryCapture(errorObject.error)
          })
        )
      } else if (get(errors, code)) {
        messages.push(get(errors, code))
      } else if (code) {
        messages.push(errors.unknown_error)
        sentryCapture(code)
      }
    })
  }

  return messages.map(message => gt.tp('Error', message))
}

export const getErrorCodes = (error: ApolloError) => {
  const { networkError, graphQLErrors } = error
  const messages = []

  if (networkError) {
    messages.push('network_error')
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const code = get(error, 'extensions.code')

      if (code === 'validation') {
        const fieldNames = Object.keys(error.extensions.details)
        fieldNames.forEach(name =>
          error.extensions.details[name].forEach(errorObject => {
            messages.push(errorObject.error)
            if (errorObject.error.includes('unknown')) {
              sentryCapture(errorObject.error)
            }
          })
        )
      } else {
        if (code) {
          messages.push(code)
          sentryCapture(code)
        }
      }
    })
  }

  return messages
}
