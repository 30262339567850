// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'

import _ from 'lodash'

import { useRouterWithLoading } from '~/utils/hooks/useRouterWithLoading'

import { StyledLoader, Viewport, Wrapper } from './LoadingCursorShards'

const LoadingCursorView = () => {
  const [cursorPos, setCursorPos] = useState({ x: null, y: null })
  const router = useRouterWithLoading()
  const isVisible = router.isNavigating
  const ref = useRef<HTMLDivElement | null>(null)

  const hide = () => {
    setCursorPos({ x: null, y: null })
  }

  const handleMouseMove = (e: MouseEvent) => {
    const x = e.clientX + 12
    const y = e.clientY - 12
    ;(_.isNull(cursorPos.x) || _.isNull(cursorPos.y)) && setCursorPos({ x, y })

    if (ref.current) {
      ref.current.style.left = `${x}px`
      ref.current.style.top = `${y}px`
    }
  }

  useEffect(() => {
    isVisible && ref.current
      ? window.addEventListener('mousemove', handleMouseMove)
      : window.removeEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isVisible, ref.current])

  useEffect(() => {
    if (!router.isNavigating) {
      hide()
    }
  }, [router.isNavigating])

  return (
    <Viewport>
      <Wrapper isVisible={isVisible && cursorPos.x && cursorPos.y} ref={ref}>
        <StyledLoader color='gray' />
      </Wrapper>
    </Viewport>
  )
}

export default LoadingCursorView
