import { StylesConfig } from 'react-select'

export const contentStyles = {
  maxWidth: '100%',
  margin: 0,
  padding: 0,
  height: '3rem',
  justifyContent: 'flex-start',
  fontWeight: 500,
  flexGrow: 1,
}

export const selectStyles: StylesConfig<any> = {
  valueContainer: base => ({
    ...base,
    width: '100%',
    flexGrow: 1,
    marginBottom: 0,
    textAlign: 'left',
    fontSize: '15px',
    fontWeight: 500,
    paddingLeft: '1rem',
  }),
  menu: base => ({
    ...base,
    zIndex: 30,
    boxShadow: 'none',
    border: 0,
    marginTop: 0,
  }),
  placeholder: base => ({
    ...base,
    color: '#000',
  }),
  indicatorsContainer: base => ({
    ...base,
    marginRight: '1rem',
  }),
  control: base => ({
    ...base,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  }),
  option: () => ({
    boxShadow: 'none',
    padding: 0,
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    margin: 0,
    padding: '.5rem',
    flexGrow: 1,
    border: 0,
  }),
}
