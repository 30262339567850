// @ts-strict-ignore
import * as yup from 'yup'

import { errors } from '~/constants/errors'
import { gt as GT } from '~/locale'

export const validationMessages = (gt: typeof GT) => ({
  mixed: {
    required: gt.tp('Error', 'This field is required'),
  },
  string: {
    email: gt.tp('Error', errors.validation.email.invalid),
    min: ({ min }): string =>
      gt.tnp(
        'Error',
        'This field must be at least {{ count }} character long',
        'This field must be at least {{ count }} characters long',
        min,
        { count: min }
      ),
    max: ({ max }): string =>
      gt.tnp(
        'Error',
        'This field must be no longer than {{ count }} character',
        'This field must be no longer than {{ count }} characters',
        max,
        { count: max }
      ),
  },
})

yup.setLocale(validationMessages(GT))

export default yup
