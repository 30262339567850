import styled from '@emotion/styled'

import { Body2 } from '~/theme/utils/typography'

import { BasicIcon } from '../Icon'

import { TagSize, TagVariant } from './TagTypes'

export const StyledTag = styled(Body2)<{
  size?: TagSize
  variant?: TagVariant
}>`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  background: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme }) => theme.colors.gray[800]};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  pointer-events: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme, size }) =>
    size === 'large' &&
    `
    font-size: ${theme.text.fontSize.l};
    line-height: ${theme.text.lineHeight.relaxed};
  `}

  ${({ theme, size, variant }) =>
    size === 'large' &&
    (variant === 'default' || variant === 'green') &&
    `
      font-weight: ${theme.text.fontWeight.medium};
      color: ${theme.colors.gray[1000]};
      background: ${theme.colors.gray[100]};
      border: none;
      border-radius: .25rem;
      padding: .5rem;
  `}

  ${({ theme, variant }) => {
    if (variant === 'plainText') {
      return `
        color: ${theme.colors.gray[800]};
        font-weight: ${theme.text.fontWeight.regular};
        background: none;
        border: none;
        padding: 0;
        margin: 0 .25rem;
      `
    }
    if (variant === 'filter') {
      return `
      height: 2.5rem;
      background: ${theme.colors.gray[0]};
      color: ${theme.colors.gray[1000]};
      border: 1px solid ${theme.colors.gray[300]};
      border-radius: .375rem;
      padding: .5rem .75rem;
      font-weight: ${theme.text.fontWeight.regular};
      pointer-events: auto;
      cursor: pointer;
      transition: .3s;
      &:hover {
        border: 1px solid ${theme.colors.orange[400]};
      }
    `
    }
    if (variant === 'green') {
      return `
        color: ${theme.colors.green[400]};
        background: ${theme.colors.green[100]};
      `
    }
  }}
`

export const StyledXIcon = styled(BasicIcon)`
  height: 0.75rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
`
