import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Body2, Heading6 } from '~/theme/utils/typography'

import { Props } from './InputTypes'
import { inputFontWeight, inputHeight, inputWidth } from './InputUtils'

export const InputFieldWrapper = styled.div`
  width: 100%;
  margin: 0.5rem;
  position: relative;
`

export const StyledError = styled.div`
  font-weight: ${({ theme }) => theme.text.fontWeight.regular};
  font-size: ${({ theme }) => theme.text.fontSize.s};
  line-height: ${({ theme }) => theme.text.lineHeight.tight};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
  color: ${({ theme }) => theme.colors.error};
`

export const Wrapper = styled.label<{
  props: Props
}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Heading6} {
    color: ${({ theme }) => theme.colors.gray[1000]};
  }

  ${Body2} {
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  ${Body2}:last-child {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.red[400]};
  }
`

export const StyledInput = styled.input`
  width: 100%;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Container = styled.div<{
  props: Props
}>`
  white-space: nowrap;
  overflow: hidden;

  ${StyledInput}::placeholder {
    color: ${({ theme }) => theme.colors.gray[600]};
    opacity: 1;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray[500]};
  }

  & img.icon-eye-show,
  & img.icon-eye-hide {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:focus-within,
  &:active {
    box-shadow: ${({ theme }) => `0 0 4px 0 ${theme.colors.orange[400]}`};
    border-color: ${({ theme }) => theme.colors.orange[400]};

    & img.icon-eye-show,
    & img.icon-eye-hide {
      opacity: 1;
    }
  }

  ${({ theme, props }) => {
    const { width, height, disabled, error } = props

    return css`
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border: 1px solid ${theme.colors.gray[300]};
      background: ${theme.colors.gray[0]};
      color: ${theme.colors.gray[1000]};
      width: ${inputWidth[width]};
      height: ${inputHeight[height]};
      justify-content: space-between;
      border-radius: 0.5rem;
      transition:
        border-color 0.2s,
        background 0.2s,
        box-shadow 0.2s;

      input {
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: ${theme.text.fontSize.m};
        font-weight: ${inputFontWeight[height]};
        line-height: ${theme.text.lineHeight.loose};
        letter-spacing: ${theme.text.letterSpacing.normal};
      }

      input::-webkit-input-placeholder {
        font-weight: ${inputFontWeight[height]};
      }

      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }

      ${disabled &&
      css`
        background: ${theme.colors.gray[100]};
        border-color: ${theme.colors.gray[300]};
        pointer-events: none;
      `}

      ${error &&
      !disabled &&
      css`
        border: 1px solid ${theme.colors.red[400]};

        &:focus-within {
          box-shadow: ${`0 0 4px 0 ${theme.colors.red[400]}`};
          border-color: ${theme.colors.red[400]};
        }

        &:hover {
          border: 1px solid ${theme.colors.red[400]};
        }
      `}
    `
  }};
`

export const IconWrapper = styled.span<{
  filter?: string
}>`
  display: flex;
  align-items: center;

  img {
    ${({ filter }) =>
      filter &&
      css`
        filter: ${filter};
      `};
    transition: color 0.2s;
    height: 1.25rem;
    width: auto;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`
