import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import { Trigger } from '~/shared/atoms/ShowOnTrigger/ShowOnTriggerShards'

export const Row = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  & > div {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    min-width: 110px;

    & > div {
      width: 100%;
    }
  }
`

export const Container = styled.div<{ variant?: 'equal-width' | 'shrink-middle' | 'expand-last'; orientationBreakpoint: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Trigger} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  & > div {
    width: 100%;
  }

  @media (min-width: ${({ orientationBreakpoint }) => orientationBreakpoint}) {
    flex-direction: row;

    & > *:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    & > div {
      width: unset;
    }

    ${Row} {
      & > div {
        flex: unset;
        width: 9rem;
      }
    }

    button {
      width: 10rem;
      margin-left: 0.5rem;
    }

    ${({ variant }) =>
      !variant &&
      css`
        & > div:first-of-type {
          width: 10rem;
        }
      `}

    ${({ variant }) =>
      variant === 'shrink-middle' &&
      css`
        & > div:first-of-type > div:nth-child(2) {
          max-width: 100%;
          min-width: 5rem;
        }
      `}

  ${({ variant }) =>
      variant === 'expand-last' &&
      css`
        & > div:first-of-type > div:nth-child(3) {
          min-width: 7rem;
        }
      `}
  }
`

export const SearchIconWrapper = styled.div`
  display: flex;

  img {
    filter: ${({ theme }) => theme.filters.gray[700]};
  }
`

export const Wrapper = styled.div<{ hasWrapper: boolean }>`
  width: 100%;
  ${({ theme, hasWrapper }) =>
    hasWrapper &&
    css`
      background: ${theme.colors.gray[0]};
      padding: 1rem 1rem 0.5rem;
      border-radius: 0.75rem;
      box-shadow: ${theme.commons.shadow.l};
      margin: 0 auto;
    `}
`

export const NameInput = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 100%;
`

export const NameInputInner = styled(Input)`
  width: 100%;
  height: 100%;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: ${({ theme }) => theme.text.fontSize.l};
`
