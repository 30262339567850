import { useRouter } from 'next/router'

import { Heading6 } from '~/theme/utils/typography'

import { paths } from '~/constants'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { NoSlotsWrapper } from './NoSlotsBoardShards'

interface Props {
  type?: 'chefsmenu' | 'restaurant'
  closeSelf?: () => void
}
const NoSlotsBoard = ({ type = 'restaurant', closeSelf }: Props) => {
  const router = useRouter()

  return (
    <NoSlotsWrapper>
      <Heading6 fontWeight='medium'>{gt.tp('NoSlots', 'No tables are currently available.')}</Heading6>
      <Button
        onClick={() => {
          router.push(type === 'chefsmenu' ? paths.chefsMenuOffers : paths.restaurants)
          closeSelf && closeSelf()
        }}
        color='primary'
        width='full'
        label={type === 'chefsmenu' ? gt.tp('NoSlots', 'Back to offers') : gt.tp('NoSlots', 'Back to restaurants')}
      />
    </NoSlotsWrapper>
  )
}

export default NoSlotsBoard
