import { StyledIcon, StyledLoginButton } from './LoginButtonShards'

interface ButtonProps {
  icon?: string
  label?: string
}
const LoginButton: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ label, icon, ...props }) => {
  return (
    <StyledLoginButton type='button' {...props}>
      {icon && <StyledIcon icon={icon} />}
      {label}
    </StyledLoginButton>
  )
}

export default LoginButton
