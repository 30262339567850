import { useEffect } from 'react'
import { DayPickerSingleDateController } from 'react-dates'

import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import Icon from '../Icon'
import Loader from '../Loader'

import { IconWrapper, LoaderWrapper, Wrapper } from './CalendarShards'
import { getInitialMonth, isDayIncluded } from './CalendarUtils'

interface Props {
  value?: dayjs.Dayjs
  onChange(value: dayjs.Dayjs): void
  availableDates: dayjs.Dayjs[]
  highlightedDates?: dayjs.Dayjs[]
  minDate?: dayjs.Dayjs
  focused?: boolean
  onPrevMonthClick?(): void
  onNextMonthClick?(): void
  loading?: boolean
}

const Calendar: React.FC<Props> = ({
  value,
  onChange,
  availableDates,
  minDate,
  highlightedDates = [],
  focused = true,
  onPrevMonthClick,
  onNextMonthClick,
  loading,
}) => {
  const { locale } = useRouter()
  // we need to lose timezone because of this bug in dayjs(), it shouldn't affect the calendar anyway
  // https://github.com/iamkun/dayjs/issues/1272
  const utcValue = value?.utc(true)

  useEffect(() => {
    dayjs.locale(locale)
  }, [locale])

  const isDayOutsideRange = (day: any) => !isDayIncluded({ dates: availableDates, day, minDate })

  const isDayHighlighted = (day: any) => isDayIncluded({ dates: highlightedDates, day, type: 'highlighted' })

  return (
    <Wrapper>
      {loading && (
        <LoaderWrapper>
          <Loader color={'gray'} />
        </LoaderWrapper>
      )}
      <DayPickerSingleDateController
        focused={focused}
        date={utcValue as any} // due to moment replaced by dayjs
        onDateChange={onChange as (value: any) => void}
        initialVisibleMonth={() =>
          getInitialMonth({
            availableDates,
            date: utcValue,
            minDate,
          }) as any
        }
        onFocusChange={() => {}}
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        isDayHighlighted={isDayHighlighted}
        isOutsideRange={isDayOutsideRange}
        daySize={36}
        verticalBorderSpacing={4}
        horizontalMonthPadding={0}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        navPrev={
          <IconWrapper style={{ left: '1rem' }}>
            <Icon icon='arrow' />
          </IconWrapper>
        }
        navNext={
          <IconWrapper style={{ right: '1rem', transform: 'rotate(180deg)' }}>
            <Icon icon='arrow' />
          </IconWrapper>
        }
      />
    </Wrapper>
  )
}

export default Calendar
