import styled from '@emotion/styled'

import Loader from '../Loader'

export const StyledPill = styled.button<{ loading: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  height: 2rem;
  border-radius: 6.25rem;
  color: ${({ theme }) => theme.colors.gray[0]};
  background-color: ${({ theme }) => theme.colors.orange[500]};
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  font-size: ${({ theme }) => theme.text.fontSize.base};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  &:hover {
    background-color: ${({ theme }) => theme.colors.orange[600]};
  }
  ${({ theme, disabled }) =>
    disabled &&
    `
    &, &:hover {
      color: ${theme.colors.gray[600]};
      background-color: ${theme.colors.gray[0]};
      border-color: ${theme.colors.gray[100]};
      cursor: auto;
    }
  `}
`

export const StyledLoader = styled(Loader)`
  width: 1.125rem;
  height: 1.125rem;
`
