// @ts-strict-ignore
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import Icon from '../Icon'

import { ButtonIcon, Container, Header, IconWrapper, Title } from './CollapseStyle'
import { CollapseSize } from './CollapseTypes'
import { titleAs } from './CollapseUtils'

interface Props {
  title?: React.ReactNode
  icon?: string
  size?: CollapseSize
  defaultShow?: boolean
  borderStyle?: 'border-top' | 'border-bottom' | 'none'
  children: React.ReactNode
  className?: string
  isOpen?: boolean
  onToggle?: () => void
}

const Collapse: React.FC<Props> = ({
  title,
  icon,
  children,
  size,
  defaultShow = false,
  borderStyle = 'border-bottom',
  className,
  isOpen,
  onToggle,
}) => {
  const [show, setShow] = useState<boolean>(defaultShow)
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })

  const handleToggle = () => {
    if (onToggle) {
      onToggle()
    } else {
      setShow(v => !v)
    }
  }

  const isCollapseOpen = isOpen !== undefined ? isOpen : show

  return (
    <Container borderStyle={borderStyle} className={className}>
      <Header onClick={handleToggle} borderStyle={borderStyle} size={size}>
        <Title as={isMobile ? Heading6 : titleAs[size]} fontWeight={isMobile ? 'semiBold' : 'medium'}>
          {icon && (
            <IconWrapper>
              <Icon icon={icon} />
            </IconWrapper>
          )}
          <span>{title}</span>
        </Title>
        <ButtonIcon active={isCollapseOpen}>
          <Icon icon='arrow' />
        </ButtonIcon>
      </Header>
      {isCollapseOpen && <div>{children}</div>}
    </Container>
  )
}

export default Collapse
