import { processEnv } from '~/utils/env'

const currencyMap: { [key: string]: string } = {
  PLN: 'zł',
  USD: '$',
}

const useCurrency = (): string => {
  const envCurrency = processEnv.CURRENCY
  return envCurrency && currencyMap[envCurrency] ? currencyMap[envCurrency] : 'zł'
}

export default useCurrency
