// @ts-strict-ignore
import { CSSProperties, ReactNode } from 'react'

import dayjs from 'dayjs'

import { Heading6 } from '~/theme/utils/typography'

import { shortMonths } from '~/constants/translated'
import { gt } from '~/locale'

import { CustomStyles } from '../Dropdown/DropdownTypes'
import Icon from '../Icon'

import { ActionWrapper, Content, SelectIconWrapper, Wrapper } from './SelectShards'

export const getLabel = (value: dayjs.Dayjs | undefined, hideYear: boolean): string => {
  const dateFormat = hideYear ? 'D MMM' : 'DD.MM.YYYY'

  if (!value) {
    return gt.tp('Calendar', 'Any date')
  }

  const today = dayjs()
  const isValueToday = today.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')

  if (isValueToday) {
    return gt.tp('Calendar', 'Today')
  } else {
    if (hideYear) {
      const day = value.date()
      const monthIndex = value.month()
      return `${day} ${gt.tp('Month', shortMonths[monthIndex])}`
    } else {
      return value.format(dateFormat)
    }
  }
}

interface SelectContainerProps {
  title: string | ReactNode
  onClick?: () => void
  active?: boolean
  icon?: string | ReactNode
  style?: CSSProperties
  error?: boolean
  description?: string
  disabled?: boolean
  customStyles?: CustomStyles
  className?: string
}

export const SelectContainer = ({
  icon,
  title,
  active = false,
  onClick,
  error,
  style,
  description,
  disabled,
  customStyles,
  className,
}: SelectContainerProps) => {
  return (
    <Wrapper className={className}>
      {description && <Heading6>{description}</Heading6>}
      <Content style={style} error={error} active={active} onClick={onClick} disabled={disabled}>
        {icon && (
          <SelectIconWrapper unfilterableColor={customStyles?.unfilterableIconColor}>
            {typeof icon === 'string' ? <Icon icon={icon} /> : icon}
          </SelectIconWrapper>
        )}
        <Heading6 as='div' fontWeight='medium'>
          {title}
        </Heading6>
        <ActionWrapper active={active}>
          <Icon icon='caret-down' />
        </ActionWrapper>
      </Content>
    </Wrapper>
  )
}
