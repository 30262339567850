export const shadow = {
  xxs: '0px 2px 2px rgba(0, 0, 0, 0.04)',
  xs: '0px 1px 2px rgba(0, 0, 0, 0.16)',
  s: '0px 2px 3px rgba(0, 0, 0, 0.16)',
  m: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  l: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  xl: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  // eslint-disable-next-line max-len
  xxl: '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
}
