import { CSSProperties, useState } from 'react'

import Dropdown, { DropdownItem } from '../Dropdown'
import { CustomStyles } from '../Dropdown/DropdownTypes'
import { ShowOnTriggerPlacement } from '../ShowOnTrigger/ShowOnTriggerTypes'

import { SelectContainer } from './SelectUtils'

interface Props {
  error?: boolean
  style?: CSSProperties
  selectedKey: string | number
  onChange(key: string | number): void
  items: DropdownItem[]
  hideOnSelect?: boolean
  title?: string
  placement?: ShowOnTriggerPlacement
  description?: string
  disabled?: boolean
  customStyles?: CustomStyles
  className?: string
}

const Select: React.FC<Props> = ({
  selectedKey,
  items,
  onChange,
  error,
  style,
  hideOnSelect,
  title,
  placement,
  description,
  disabled,
  customStyles,
  className,
}) => {
  const [show, setShow] = useState(false)
  const item = items.find(({ key }) => key === selectedKey)
  const toggleShow = () => setShow(v => !v)
  const handleChange = (key: string | number) => {
    if (hideOnSelect) {
      setShow(false)
    }

    onChange(key)
  }

  return (
    <Dropdown
      items={items}
      selectedKey={selectedKey}
      show={show}
      trigger='programmatic'
      onChange={handleChange}
      onClickOutside={() => setShow(false)}
      placement={placement}
      customStyles={customStyles}>
      <SelectContainer
        style={style}
        error={error}
        active={show}
        onClick={toggleShow}
        icon={item?.icon}
        title={item?.label || title}
        description={description}
        disabled={disabled}
        customStyles={customStyles}
        className={className}
      />
    </Dropdown>
  )
}

export default Select
