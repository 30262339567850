import { Heading5, Heading6 } from '~/theme/utils/typography'

import { TagFragmentFragment } from '~/generated/graphql'
import { gt } from '~/locale'
import Tag from '~/shared/atoms/Tag'

import { Heading, Taglist, Wrapper } from './FeaturesShards'

interface FeaturesViewProps {
  tags: TagFragmentFragment[]
  isMobile: boolean
}

const FeaturesView = ({ tags, isMobile }: FeaturesViewProps) => {
  const headingTag = isMobile ? Heading6 : Heading5
  const dietTags = tags.filter(tag => tag.type === 'Diet')
  const otherTags = tags.filter(tag => !['Price', 'Cuisine', 'Diet'].includes(tag.type))
  return (
    <Wrapper>
      {!!dietTags.length && (
        <div>
          <Heading as={headingTag} fontWeight='medium'>
            {gt.tp('RestaurantDetailsPanel', 'Be eco! #EatForTheEarth')}
          </Heading>
          <Taglist>
            {dietTags.map(tag => (
              <Tag variant='green' size='large' key={tag.id} label={tag.name} />
            ))}
          </Taglist>
        </div>
      )}
      {!!otherTags.length && (
        <div>
          <Heading as={headingTag} fontWeight='medium'>
            {gt.tp('RestaurantDetailsPanel', 'Features')}{' '}
          </Heading>
          <Taglist>
            {otherTags.map(tag => (
              <Tag size='large' key={tag.id} label={tag.name} />
            ))}
          </Taglist>
        </div>
      )}
    </Wrapper>
  )
}

export default FeaturesView
