import { components, ControlProps, DropdownIndicatorProps, MenuProps, OptionProps } from 'react-select'

import { Heading6 } from '~/theme/utils/typography'

import { StyledMenu } from '~/shared/molecules/SmartSearch/SmartSearchShards'

import { Button } from '../Dropdown/DropdownShards'
import Icon from '../Icon'
import { ActionWrapper, Content } from '../Select/SelectShards'

import { contentStyles } from './SelectionDropdownShards'

export interface ISingleSelectOption {
  value: number
  label: string
}

export const Menu = <Option extends unknown, IsMulti extends boolean>({ className, innerRef, ...props }: MenuProps<Option, IsMulti>) => {
  return (
    <components.Menu innerRef={innerRef} {...props}>
      <StyledMenu ref={innerRef}>{props.children}</StyledMenu>
    </components.Menu>
  )
}
export const IconWrapper = <Option extends unknown, IsMulti extends boolean>({
  innerProps,
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps<Option, IsMulti>) => (
  <ActionWrapper active={menuIsOpen} {...innerProps}>
    <Icon icon='caret-down' />
  </ActionWrapper>
)

export const Option = <OptionType extends unknown, IsMulti extends boolean>({
  children,
  innerRef,
  ...props
}: OptionProps<OptionType, IsMulti>) => (
  <components.Option innerRef={innerRef} {...props}>
    <Button selected={props.isSelected}>
      <Heading6 fontWeight='medium'>{children}</Heading6>
    </Button>
  </components.Option>
)

export const Control = <Option extends unknown, IsMulti extends boolean>({ children, ...props }: ControlProps<Option, IsMulti>) => (
  <components.Control {...props}>
    <Content ref={props.innerRef} disabled={props.isDisabled} active={props.isFocused} style={contentStyles}>
      {children}
    </Content>
  </components.Control>
)
