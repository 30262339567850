// @ts-strict-ignore
import { CSSProperties, useEffect, useRef, useState } from 'react'

import dayjs from 'dayjs'

import { Heading6 } from '~/theme/utils/typography'

import Calendar from '../Calendar'
import Icon from '../Icon'
import ShowOnTrigger from '../ShowOnTrigger'

import { ActionWrapper, Content, SelectIconWrapper } from './SelectShards'
import { getLabel } from './SelectUtils'

interface Props {
  availableDates?: dayjs.Dayjs[]
  highlightedDates?: dayjs.Dayjs[]
  minDate?: dayjs.Dayjs
  value?: dayjs.Dayjs
  onChange(value: dayjs.Dayjs): void
  error?: boolean
  icon?: React.ReactNode
  style?: CSSProperties
  hideOnChange?: boolean
  className?: string
  onPrevMonthClick?(): void
  onNextMonthClick?(): void
  onClose?(): void
  loading?: boolean
  hideYear?: boolean
}

const SelectCalendar: React.FC<Props> = ({
  availableDates,
  highlightedDates,
  minDate,
  value,
  onChange,
  style,
  error,
  icon,
  hideOnChange,
  className,
  onPrevMonthClick,
  onNextMonthClick,
  onClose,
  loading,
  hideYear,
}) => {
  const [show, setShow] = useState(false)
  const previousShowValue = useRef(show)
  const toggleShow = () => setShow(v => !v)
  const label = getLabel(value, hideYear)

  useEffect(() => {
    const isClosed = !show && onClose && previousShowValue.current
    if (isClosed) {
      onClose()
    }
    previousShowValue.current = show
  }, [show])

  const handleChange = (value: dayjs.Dayjs) => {
    if (hideOnChange) {
      setShow(false)
    }

    onChange(value)
  }

  return (
    <ShowOnTrigger
      placement='down'
      type='programmatic'
      show={show}
      verticalOffset={8}
      onClickOutside={() => setShow(false)}
      trigger={
        <Content className={className} style={style} error={error} active={show} onClick={toggleShow}>
          {icon && <SelectIconWrapper>{icon}</SelectIconWrapper>}
          <Heading6 as='div' fontWeight='medium'>
            {label}
          </Heading6>
          <ActionWrapper active={show}>
            <Icon icon='caret-down' />
          </ActionWrapper>
        </Content>
      }
      content={
        <Calendar
          value={value}
          onChange={handleChange}
          availableDates={availableDates}
          highlightedDates={highlightedDates}
          minDate={minDate}
          focused={show}
          onPrevMonthClick={onPrevMonthClick}
          onNextMonthClick={onNextMonthClick}
          loading={loading}
        />
      }
    />
  )
}

export default SelectCalendar
