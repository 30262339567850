// @ts-strict-ignore
import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { Heading2, Heading4 } from '~/theme/utils/typography'

import { ImageFile } from '~/generated/graphql'
import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'

import { Container, Content, Header, HeaderInfo } from './RestaurantGalleryShards'
import { getRows } from './RestaurantGalleryUtils'

type GalleryImageFile = Pick<ImageFile, 'original' | 'preview' | 'id'>

interface Props {
  title: string
  images: GalleryImageFile[]
  show: boolean
  onClose(): void
  closeOnEscapeKeyClick?: boolean
}

const RestaurantGallery: React.FC<Props> = ({ title, images, show, onClose, closeOnEscapeKeyClick = true }) => {
  const imageFiles = JSON.stringify(images)
  const rows = useMemo(() => getRows(JSON.parse(imageFiles) as GalleryImageFile[]), [imageFiles])
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && closeOnEscapeKeyClick) {
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      setMounted(false)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose, closeOnEscapeKeyClick])

  if (!mounted) {
    return null
  }

  return createPortal(
    <Container show={show}>
      <Header>
        <HeaderInfo>
          <Heading2 fontWeight='semiBold'>{gt.tp('RestaurantPage', 'Photo gallery')}</Heading2>
          <Heading4 fontWeight='regular'>{title}</Heading4>
        </HeaderInfo>
        <Link onClick={onClose} size='big' label={gt.tp('RestaurantPage', 'Close')} />
      </Header>
      <Content>{rows}</Content>
    </Container>,
    document.querySelector('#__next') || document.querySelector('#root')
  )
}

export default RestaurantGallery
