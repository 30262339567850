import styled from '@emotion/styled'

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gray[400]};
  transition: 0.3s ease;
`

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  color: ${({ theme }) => theme.colors.gray[900]};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.gray[800]};

    ${Line} {
      background: ${({ theme }) => theme.colors.gray[800]};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.gray[700]};

    ${Line} {
      background: ${({ theme }) => theme.colors.gray[700]};
    }
  }
`
