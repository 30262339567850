import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Body2Link, Heading6 } from '~/theme/utils/typography'

export const Container = styled.div<{ isTablet: boolean }>`
  display: flex;
  align-items: center;
  ${Heading6} {
    width: 100%;
    display: flex;
    flex-grow: 0;
  }
  width: 100%;
  gap: 1.5rem;

  ${({ isTablet }) =>
    isTablet &&
    css`
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      padding-top: 2rem;
      ${Heading6} {
        font-weight: 500;
        cursor: pointer;
      }
    `};
`

export const TopBarLink = styled(Body2Link)`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const Separator = styled.hr`
  border-top: ${({ theme }) => `2px solid ${theme.colors.gray[100]}`};
  width: 100%;
  margin: 0;
`
