// @ts-strict-ignore
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { BasicIcon } from '../Icon'

import { ToastSizeType, ToastType } from './ToastTypes'

const TOAST_MARGIN = 1

const TOAST_COLORS = {
  info: 'blue',
  success: 'green',
  error: 'red',
}

export const IconWrapper = styled.div<{ type?: ToastType }>`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ type }) =>
    type === 'info' &&
    css`
      align-self: flex-start;
      margin-top: 1rem;

      ${mediaQueryUp('m')} {
        align-self: unset;
        margin-top: unset;
      }
    `}
`

export const XIcon = styled(BasicIcon)`
  height: 0.875rem;
  width: 0.875rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
  cursor: pointer;
`

export const ToastIcon = styled(BasicIcon)`
  height: 1.25rem;
  width: 1.25rem;
`

export const Label = styled.div`
  margin: 0 0.625rem;
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  font-size: ${({ theme }) => theme.text.fontSize.base};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
`

export const StyledToast = styled.div<{ type: ToastType }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  margin: 0 0 ${TOAST_MARGIN}rem 0;
  box-shadow: ${({ theme }) => theme.commons.shadow.m};
  border-radius: 0.5rem;
  border-left: 3px solid ${({ theme, type }) => theme.colors[TOAST_COLORS[type].toString()][400]};
  color: ${({ theme, type }) => theme.colors[TOAST_COLORS[type].toString()][500]};
  background: ${({ theme, type }) =>
    type === 'success' ? theme.colors.gray[0] : type === 'error' ? theme.colors.red[100] : theme.colors.blue[100]};

  ${ToastIcon} {
    filter: ${({ theme, type }) => theme.filters[TOAST_COLORS[type].toString()][400]};
  }
  opacity: 0;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease forwards;
`

const getHeight = (size: ToastSizeType) => (size === 'large' ? 3.5 : 3)

export const Toasts = styled.div<{ count: number; size: ToastSizeType }>`
  min-height: 0;
  height: ${({ count, size }) => (count ? `${count * (1 + getHeight(size))}rem` : 0)};
  animation: ${({ count, size, theme }) => count && theme.animations.expand(0, getHeight(size) + TOAST_MARGIN)} 0.15s ease-in-out;
  transition: height 0.15s ease-in-out;

  ${StyledToast} {
    height: ${({ size }) => `${getHeight(size)}rem`};
  }

  ${Label} {
    margin-right: ${({ size }) => (size === 'large' ? '.625rem' : 0)};
  }
`
