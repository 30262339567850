import curry from 'lodash.curry'

import { interpolateString } from './interpolators'

type Interpolator = typeof interpolateString

export const ti = curry((interpolate: Interpolator, translate: (message: string) => string, message: string, scope: any) =>
  interpolate(translate(message), scope)
)

export const tin = curry(
  (
    interpolate: Interpolator,
    translate: (one: string, other: string, count: number) => string,
    one: string,
    other: string,
    count: number,
    scope: any
  ) => interpolate(translate(one, other, count), scope)
)

export const tip = curry(
  (interpolate: Interpolator, translate: (context: string, message: string) => string, context: string, message: string, scope: any) =>
    interpolate(translate(context, message), scope)
)

export const tinp = curry(
  (
    interpolate: Interpolator,
    translate: (context: string, one: string, other: string, count: number) => string,
    context: string,
    one: string,
    other: string,
    count: number,
    scope: any
  ) => interpolate(translate(context, one, other, count), scope)
)

// eslint-disable-next-line id-length
export const t = ti(interpolateString)
export const tn = tin(interpolateString)
export const tp = tip(interpolateString)
export const tnp = tinp(interpolateString)

// export const tc = ti(interpolateComponents);
// export const tcn = tin(interpolateComponents);
// export const tcp = tip(interpolateComponents);
// export const tcnp = tinp(interpolateComponents);
