import yup from '~/utils/validationMessages'

export const successModalProps = {
  closeIconPosition: 'inside' as const,
  fillScreenOnMobile: false,
  size: 'small' as const,
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  handleClick: () => null,
  position: 'fixed' as const,
  iconSize: 'big' as const,
}

export const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
})
