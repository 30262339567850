// @ts-strict-ignore
import { ContentContainer, StyledTile } from './TileShards'

interface Props {
  children: React.ReactNode
  gridColumn?: string
  horizontal?: boolean
  withBorder?: boolean
  photo?: string
  photoRatio?: string
  imageMargin?: string
  className?: string
  onClick?(): void
  onContextMenu?(e: React.SyntheticEvent<HTMLDivElement>): void
  onMouseEnter?(): void
  onMouseLeave?(): void
}

const Tile: React.FC<Props> = ({
  children,
  className,
  gridColumn,
  horizontal,
  withBorder,
  onClick,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
}) => (
  <StyledTile
    className={className}
    gridColumn={gridColumn}
    horizontal={horizontal}
    withBorder={withBorder}
    onClick={onClick}
    onContextMenu={onContextMenu}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}>
    <ContentContainer horizontal={horizontal}>{children}</ContentContainer>
  </StyledTile>
)

Tile.defaultProps = {
  gridColumn: 'span 1',
  horizontal: false,
  photoRatio: '9 / 16',
  withBorder: false,
}

export default Tile
