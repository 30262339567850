export function call<F extends (...params: any) => any>(fnc: F, ...args: Parameters<F>): null | ReturnType<F> {
  if (fnc && typeof fnc === 'function') {
    // eslint-disable-next-line prefer-spread
    return fnc.apply(null, args)
  }
  return null
}

export function isCallable(value: any) {
  return Boolean(value && typeof value === 'function')
}

export function noop(..._: any) {
  /* void */
}
