// @ts-strict-ignore
import { GroupBase } from 'react-select'
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types'

import debounce from 'debounce-promise'

import { useSearchLazyQuery, useSelectedRegionQuery } from '~/generated/graphql'
import { SmartSearchOption } from '~/shared/molecules/SmartSearch/SmartSearchTypes'

import SmartSearchView from './SmartSearchView'

export interface Props {
  onOptionPress: (option: SmartSearchOption) => void
}

const formatGQLResponseToOptions = ({ data }): OptionsOrGroups<SmartSearchOption, never> => {
  return data.search.map(result => ({
    value: result.id,
    details: result,
    label: result.name,
  }))
}

const groupOptionsByType = (
  options: OptionsOrGroups<SmartSearchOption, never>
): OptionsOrGroups<SmartSearchOption, GroupBase<SmartSearchOption>> => {
  const groups: Record<SmartSearchOption['details']['__typename'], SmartSearchOption[]> = options.reduce(
    (acc, option) => {
      const groupToAssign = option.details.__typename

      if (!acc[groupToAssign]) {
        acc[groupToAssign] = [option]
      } else {
        acc[groupToAssign].push(option)
      }
      return acc
    },
    {} as Record<SmartSearchOption['details']['__typename'], SmartSearchOption[]>
  )

  return Object.entries(groups)
    .map(([label, options]) => ({
      label,
      options,
      horizontal: label === 'Tag',
    }))
    .sort((groupA, groupB) => (groupB.label === 'Tag' ? 1 : -1))
}

export default ({ onOptionPress }: Props) => {
  const { data } = useSelectedRegionQuery()
  const regionId = data?.selectedRegion.id

  const [searchQuery] = useSearchLazyQuery({ variables: { regionId, text: '' } })

  const search = async (text: string) =>
    searchQuery({
      variables: {
        text,
      },
    })

  const searchAndFormatOptions = async (inputValue: string) => {
    const searchResults = await search(inputValue)

    const searchResultsOptions = formatGQLResponseToOptions(searchResults)
    return groupOptionsByType(searchResultsOptions)
  }

  return <SmartSearchView search={debounce(searchAndFormatOptions, 200)} onOptionPress={onOptionPress} />
}
