import { Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import type { ContactOption } from '../../../../components/Listing/ListingPageContent/ListingPageContentTypes'

import { StyledExtraGuestsModal } from './ExtraGuestsModalShards'

interface ExtraGuestsModalProps {
  handleClose: () => void
  contactOptions?: Array<ContactOption>
}

const ExtraGuestsModalView = ({ handleClose, contactOptions = ['email', 'phone'] }: ExtraGuestsModalProps) => {
  return (
    <StyledExtraGuestsModal>
      <Heading6 as='p' fontWeight='regular'>
        {gt.tp('ExtraGuestsModal', 'To book a table for more people contact us')}
      </Heading6>
      <Heading6 as='p' fontWeight='regular'>
        {contactOptions.includes('phone')
          ? gt.tp('ExtraGuestsModal', 'You can email info@restaurantclub.pl or call the helpline at 22 113 43 00.')
          : gt.tp('ExtraGuestsModal', 'You can email info@restaurantclub.pl')}
      </Heading6>
      <Button
        label={gt.tp('ExtraGuestsModal', 'Close')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        onClick={handleClose}
      />
    </StyledExtraGuestsModal>
  )
}

export default ExtraGuestsModalView
