import { Reservable, ReservableEnum } from '~/generated/graphql'

export const isDaily = <ReservablePartial extends Pick<Reservable, '__typename'>>(
  reservable: ReservablePartial
): reservable is ReservablePartial & { __typename: 'Daily' } => reservable.__typename === ReservableEnum.Daily

export const isFER = <ReservablePartial extends Pick<Reservable, '__typename'>>(
  reservable: ReservablePartial
): reservable is ReservablePartial & { __typename: 'FestivalEditionRestaurant' } =>
  reservable.__typename === ReservableEnum.FestivalEditionRestaurant

export const isEvent = <ReservablePartial extends Pick<Reservable, '__typename'>>(
  reservable: ReservablePartial
): reservable is ReservablePartial & { __typename: 'Event' } => reservable.__typename === ReservableEnum.Event
