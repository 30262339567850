import { css } from '@emotion/react'

import { fontFace } from './fontFace'

const base = css`
  * {
    border: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    font-size: 0.875rem;
    font-family: Poppins, sans-serif;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    width: 100vw;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 4px #d8d8d8;
    -webkit-box-shadow: inset 0 0 4px #d8d8d8;
    background-color: transparent;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover,
  a:active,
  a:focus {
    color: inherit;
    text-decoration: none;
  }

  input,
  textarea,
  button {
    &:focus,
    &:active {
      outline: none;
    }
  }

  button {
    background: none;
    box-shadow: none;
    padding: 0;
    font-family: inherit;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: transparent;
  }

  input::-ms-reveal {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  p {
    margin: 0;
  }

  ul,
  ol {
    padding: 0;
  }

  li {
    list-style: none;
  }

  strong {
    font-weight: 600;
  }

  .tooltip-animation {
    &-enter {
      opacity: 0;
      transform: scale(0.9);
    }

    &-enter-active {
      opacity: 1;
      transform: scale(1);
      transition:
        transform 0.2s cubic-bezier(0.4, 0, 0.35, 1.3),
        opacity 0.2s ease-in-out;
    }

    &-exit {
      opacity: 1;
      transform: scale(1);
    }

    &-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition:
        transform 0.2s cubic-bezier(0.5, -0.3, 0.6, 1),
        opacity 0.2s ease-in-out;
    }
  }
`

export default css`
  ${fontFace}
  ${base}
`
