export const waitForElement = <El extends Node>({ getEl, timeout = 3000 }: { getEl: () => El; timeout?: number }) => {
  const intervalSeconds = 100
  return new Promise<El>((resolve, reject) => {
    // eslint-disable-next-line prefer-const
    let timeoutTimer: NodeJS.Timeout
    const interval = setInterval(() => {
      const el = getEl()

      if (el) {
        clearInterval(interval)
        clearTimeout(timeoutTimer)
        resolve(el)
      }
    }, intervalSeconds)

    timeoutTimer = setTimeout(() => {
      clearInterval(interval)
      reject()
    }, timeout)
  })
}
