import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Heading6 } from '~/theme/utils/typography'

export const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Heading = styled(Heading6)`
  color: ${({ theme }) => theme.colors.dropdownSelect.placeholder};
  padding-left: 16px;
`

export const Options = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-direction: row;
      overflow-x: auto;
      padding: 0.5rem 1rem;

      &::-webkit-scrollbar {
        height: 0;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        height: 0;
        background-color: transparent;
      }
    `}
`
