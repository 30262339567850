// @ts-strict-ignore
import React from 'react'

import { GetServerSidePropsContext } from 'next'
import { DocumentContext } from 'next/document'
import Head from 'next/head'
import { GetServerSidePropsResult, Redirect } from 'next/types'

import { ApolloClient, DocumentNode, QueryOptions } from '@apollo/client'

import {
  ClientConfigDocument,
  ClientConfigQuery,
  FirstUpcomingReservationDocument,
  FirstUpcomingReservationQuery,
  RegionsDocument,
  RegionsQuery,
  UserDataDocument,
  UserDataQuery,
} from '~/generated/graphql'

import { getUserSSR } from './getUserSSR'
import initApollo, { passSsrCacheToClientScript } from './init-apollo'

const DEFAULT_DESTINATION = '/?showLogin=true'

async function loader<T>({
  apolloClient,
  query,
  log,
  options,
}: {
  apolloClient: ApolloClient<any>
  query: DocumentNode
  log: string
  options?: QueryOptions
}) {
  const logger = getLogger('bootstrap', 'debug')

  return apolloClient
    .query<T>({
      query,
      errorPolicy: 'ignore',
      ...options,
    })
    .then(({ data }) => {
      apolloClient.cache.writeQuery<T>({
        query,
        data: data,
      })
    })
    .then(() => {
      logger(log)
    })
}

interface GlobalBootstrapInput {
  ctx: GetServerSidePropsContext | DocumentContext
}

interface GlobalBootstrapOutput {
  apolloClient: ApolloClient<any>
}

export const globalBootstrap = async ({ ctx }: GlobalBootstrapInput): Promise<GlobalBootstrapOutput> => {
  const apolloClient = initApollo(null, ctx)

  const loadRegions = loader<RegionsQuery>({
    apolloClient,
    query: RegionsDocument,
    log: 'Regions has been loaded',
  })

  const loadUserData = loader<UserDataQuery>({
    apolloClient,
    query: UserDataDocument,
    log: 'User data has been loaded',
  })

  const loadClientConfig = loader<ClientConfigQuery>({
    apolloClient,
    query: ClientConfigDocument,
    log: 'Client config has been loaded',
  })

  await Promise.all([loadRegions, loadUserData, loadClientConfig])
  const userLoggedIn = !!(await getUserSSR({ apolloClient }))?.user

  if (userLoggedIn) {
    await loader<FirstUpcomingReservationQuery>({
      apolloClient,
      query: FirstUpcomingReservationDocument,
      log: 'First upcoming reservation has been loaded',
    })
  }

  return {
    apolloClient,
  }
}

interface GlobalBootstrapPropsInput {
  apolloClient: ApolloClient<any>
  redirectIfNoUser?: boolean | string
}

export const globalBoostrapPropsWrapper = async <T extends GetServerSidePropsResult<unknown>>(
  { apolloClient, redirectIfNoUser }: GlobalBootstrapPropsInput,
  pageProps: T
): Promise<T> => {
  const untypedPageProps = pageProps as any

  if (untypedPageProps?.props) {
    untypedPageProps.props.apolloState = apolloClient.cache.extract()
  }

  if (redirectIfNoUser) {
    const { user } = await getUserSSR({ apolloClient })
    const destination = typeof redirectIfNoUser === 'string' ? redirectIfNoUser : DEFAULT_DESTINATION
    const redirect: Redirect = {
      statusCode: 307,
      destination,
    }

    if (!user) {
      untypedPageProps.redirect = redirect
    }
  }

  return untypedPageProps
}

export const GlobalBootstrapWrapper = (Component: any): React.FC<any> => {
  const InnerComponent = ({ apolloState, ...props }) => {
    const cacheScript = passSsrCacheToClientScript(apolloState)

    return (
      <>
        <Head>{cacheScript}</Head>
        <Component {...props} />
      </>
    )
  }

  return InnerComponent
}
