// @ts-strict-ignore
import getConfig from 'next/config'

import { ParamBuilder, ResizeType } from '@bitpatty/imgproxy-url-builder'

const publicRuntimeConfig = (getConfig() || {}).publicRuntimeConfig || {}

class ProxyParamBuilder extends ParamBuilder {
  readonly path: string
  constructor(path: string) {
    super()
    this.path = path
  }

  /**
   * @deprecated Please use proxyBuild method instad
   */
  build = this.build

  proxyBuild(): string {
    const signature = {
      key: publicRuntimeConfig?.IMGPROXY_KEY,
      salt: publicRuntimeConfig?.IMGPROXY_SALT,
    }
    const hasSignature = Object.keys(signature).reduce((a, b) => !!a && !!b, true)

    const buildData = {
      path: this.path.replace('https://', 'gs://'),
      baseUrl: publicRuntimeConfig?.IMGPROXY_URL,
      plain: true,
      signature: null,
    }

    if (hasSignature) {
      buildData.signature = signature
    }

    return this.build(buildData)
  }
}

const imgProxy = (path: string): ProxyParamBuilder => {
  return new ProxyParamBuilder(path)
}

export const imgProxyLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  const result = imgProxy(src).resize({ type: ResizeType.FILL, width })
  quality && result.quality(quality)

  return result.proxyBuild()
}

export default imgProxy
