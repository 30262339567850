import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

import { gt } from '~/locale'

export const LOGIN_FORM_FIELDS: React.InputHTMLAttributes<HTMLInputElement>[] = [
  {
    title: gt.tp('LoginForm', 'Email'),
    name: 'email',
    type: 'email',
    required: true,
  },
  {
    title: gt.tp('LoginForm', 'Password'),
    name: 'password',
    type: 'password',
    required: true,
  },
]

export const loginSchema = yup.object().shape({
  email: validationFields.email,
  password: validationFields.password,
})

const modalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  handleClick: () => null,
  position: 'fixed' as const,
  iconSize: 'big' as const,
}

export const bigModalProps = {
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  size: 'big' as const,
  ...modalProps,
}

export const smallModalProps = {
  closeIconPosition: 'inside' as const,
  fillScreenOnMobile: false,
  size: 'small' as const,
  ...modalProps,
}
