import { SingleReservableExtraQuery } from '~/generated/graphql'

import ChefsMenuPage from '../ChefsMenuPage'

import { ChefsMenuWrapper } from './ChefsMenuModalShards'

interface Props {
  reservableExtra: SingleReservableExtraQuery['reservableExtra']
  handleClose?: () => void
}

const ChefsMenuModalView = ({ reservableExtra, handleClose }: Props) => {
  return (
    <ChefsMenuWrapper>
      <ChefsMenuPage reservableExtra={reservableExtra} closeModal={handleClose} />
    </ChefsMenuWrapper>
  )
}

export default ChefsMenuModalView
