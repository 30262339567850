import React from 'react'

import { DefaultSeo } from 'next-seo'

import { getStaticFilePath } from '~/utils/helpers'

import { MetadataType } from './HeadTypes'
import { getDefaultMetadatas } from './HeadUtils'

const HeadSEO: React.FC<{ metadata?: MetadataType }> = ({ metadata }) => {
  const title = metadata?.title || getDefaultMetadatas().title
  const description = metadata?.description || getDefaultMetadatas().description

  return (
    <DefaultSeo
      title={title}
      description={description}
      canonical={metadata?.canonical}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: getStaticFilePath('favicon.png'),
        },
        {
          rel: 'apple-touch-icon',
          href: getStaticFilePath('favicon.png'),
        },
      ]}
      openGraph={{
        title,
        description,
        images: [
          {
            url: metadata?.imageUrl || getDefaultMetadatas().imageUrl,
            alt: metadata?.imageAlt || getDefaultMetadatas().imageAlt,
            type: metadata?.imageUrl ? 'image/jpg' : 'image/png',
          },
        ],
        site_name: getDefaultMetadatas().siteName,
      }}
    />
  )
}

export default HeadSEO
