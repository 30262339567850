import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  color: ${({ theme }) => theme.colors.gray[1000]};
  cursor: pointer;
`

export const ButtonIcon = styled.button<{ active: boolean }>`
  background: transparent;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  cursor: pointer;

  img {
    height: 0.6875rem;
    width: auto;
    filter: ${({ theme }) => theme.filters.gray[800]};
    transform: rotate(${({ active }) => (active ? 90 : 270)}deg);
    transition: 0.1s ease;
  }
`
