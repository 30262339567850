// @ts-strict-ignore
import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import { breakpoints, mediaQueryIsTablet } from '~/theme/utils/grid'
import { Heading1, Heading2, Heading3, Heading5, Heading6 } from '~/theme/utils/typography'
import { dayjsToString, getSlotFromTime } from '~/utils/graphqlDataFormatters'
import { pushUIEvent } from '~/utils/gtag'
import { GTMEventAction } from '~/utils/gtag-utils'

import { useOnSmartSearchOptionSelect } from '~/components/Home/HomePage/HomePageUtils'
import { DEFAULT_SLOT_COMPUTED, defaultSearchSettings, paths } from '~/constants'
import { useClientConfigQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import { Badge } from '~/shared/atoms/Badge'
import Link from '~/shared/atoms/Link'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'
import LocationMenu from '~/shared/molecules/LocationMenu'
import Search, { SearchSettings } from '~/shared/molecules/Search'

import {
  Container,
  DesktopLeftImage,
  DesktopRightImage,
  LocationMenuWrapper,
  MobileBottomImage,
  ModalWrapper,
  SearchWrapper,
  Shortcut,
  ShortcutsContainer,
  ShortcutsList,
  ShortcutsTitle,
  SubTitle,
  Title,
  Wrapper,
} from './HeroShards'
import { HeroShortcut } from './HeroTypes'

interface Props {
  shortcuts: HeroShortcut[]
  search: {
    availableDates?: dayjs.Dayjs[]
    highlightedDates?: dayjs.Dayjs[]
    minDate?: dayjs.Dayjs
    defaultPeopleCount: number
  }
}

export const pushUI = (location: string, peopleCount?: number) =>
  pushUIEvent({
    eventAction: GTMEventAction.Navigate,
    data: {
      location,
      ...(peopleCount && { peopleCount }),
    },
  })

const useSearchValueWithDefaultDailyDate = () => {
  const { data } = useClientConfigQuery()
  const defaultReservableFrom = dayjs.parseZone(data?.clientConfigs.d2dDefaultReservableFrom)

  const [searchValue, setSearchValue] = useState<SearchSettings>(
    defaultSearchSettings({ defaultDate: defaultReservableFrom, defaultSlot: getSlotFromTime(defaultReservableFrom) })
  )

  useEffect(() => {
    const defaultReservableFrom = dayjs.parseZone(data?.clientConfigs.d2dDefaultReservableFrom)

    setSearchValue(searchValue => ({ ...searchValue, date: defaultReservableFrom, slot: getSlotFromTime(defaultReservableFrom) }))
  }, [data?.clientConfigs.d2dDefaultReservableFrom])

  return useMemo(() => [searchValue, setSearchValue] as const, [searchValue])
}

const Hero: React.FC<Props> = ({ shortcuts, search: { defaultPeopleCount: _, ...search } }) => {
  const router = useRouter()
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const [searchValue, setSearchValue] = useSearchValueWithDefaultDailyDate()
  const [isSubmitting, setSubmittingState] = useState(false)

  const handleSubmit = () => {
    setSubmittingState(true)
    const params = {
      ...searchValue,
      date: searchValue?.date ? searchValue.date.format('YYYY-MM-DD') : undefined,
    }
    const paramsAllString = Object.entries(params)
      .filter(([, v]) => !!v)
      .map(([k, v]) => [k, v.toString()])
    const paramsQuery = new URLSearchParams(paramsAllString).toString()
    pushUI(paths.restaurants, params.peopleCount)
    router
      .push({
        pathname: paths.restaurants,
        query: paramsQuery,
      })
      .catch(() => {
        setSubmittingState(false)
      })
  }

  const onSmartSearchOptionSelect = useOnSmartSearchOptionSelect({ searchValue })

  return (
    <Wrapper>
      <Container>
        <DesktopLeftImage src='/assets/home-page/hero-desktop-left.svg' />
        <DesktopRightImage src='/assets/home-page/hero-desktop-right.svg' />
        {isTablet && (
          <LocationMenuWrapper>
            <Heading6 fontWeight='regular' as='span'>
              {gt.tp('HomePage', 'Your location')}:
            </Heading6>
            <LocationMenu />
          </LocationMenuWrapper>
        )}
        <Title fontWeight='semiBold' as={isTablet ? Heading3 : Heading1}>
          {gt.tp('HomePage', 'Discover your favorite restaurant')}
        </Title>
        <SubTitle fontWeight='medium' as={isTablet ? Heading6 : Heading5}>
          {gt.tp('HomePage', 'Book your tables together with show-stopping menus from top chefs!')}
        </SubTitle>
        <SearchWrapper>
          <Search
            {...search}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={handleSubmit}
            hasWrapper={true}
            orientationBreakpoint={breakpoints.l}
            withSmartSearch
            onSmartSearchChange={onSmartSearchOptionSelect}
            hideOnSelect
            loading={isSubmitting}
          />
        </SearchWrapper>
        {shortcuts.length > 0 && (
          <ShortcutsContainer>
            <ShortcutsTitle fontWeight='medium'>{gt.tp('HomePage', 'Shortcuts:')}</ShortcutsTitle>

            <ShortcutsList>
              {shortcuts.map(({ url, title, badge, enabledForFeatureFlag }) => (
                <VisibleIfFeatureFlag name={enabledForFeatureFlag} hasValue={true} key={url}>
                  <Link href={url}>
                    <Shortcut onClick={() => pushUI(url)}>
                      <Heading5 as='div' fontWeight='medium'>
                        {gt.tp('HomePage', title)}
                      </Heading5>
                      {badge && <Badge color={badge.color} label={gt.tp('HomePage', badge.label)} size='small' />}
                    </Shortcut>
                  </Link>
                </VisibleIfFeatureFlag>
              ))}
            </ShortcutsList>
          </ShortcutsContainer>
        )}
        <MobileBottomImage src='/assets/home-page/hero-mobile-bottom.svg' />
      </Container>
    </Wrapper>
  )
}

export const HeroModalContent: React.FC<Props & { onClose(): void }> = ({
  shortcuts,
  search: { defaultPeopleCount, ...search },
  onClose,
}) => {
  const router = useRouter()
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const [searchValue, setSearchValue] = useState<SearchSettings>({
    peopleCount: defaultPeopleCount,
  })
  const [isSubmitting, setSubmittingState] = useState(false)

  const handleSubmit = () => {
    setSubmittingState(true)
    const params = {
      ...searchValue,
      date: searchValue?.date
        ? searchValue.date.format('YYYY-MM-DD')
        : dayjsToString(defaultSearchSettings({ defaultSlot: DEFAULT_SLOT_COMPUTED }).date),
    }
    const paramsAllString = Object.entries(params)
      .filter(([, v]) => !!v)
      .map(([k, v]) => [k, v.toString()])
    const paramsQuery = new URLSearchParams(paramsAllString).toString()

    router
      .push(`${paths.restaurants}?${paramsQuery}`)
      .then(() => onClose())
      .catch(() => {
        setSubmittingState(false)
      })
  }
  const onSmartSearchOptionSelect = useOnSmartSearchOptionSelect({ searchValue })

  return (
    <ModalWrapper>
      <Title fontWeight='semiBold' as={isTablet ? Heading3 : Heading2}>
        {gt.tp('HomePage', 'Discover your favorite restaurant')}
      </Title>
      <SearchWrapper>
        <Search
          {...search}
          withSmartSearch
          onSmartSearchChange={onSmartSearchOptionSelect}
          value={searchValue}
          onChange={setSearchValue}
          onSubmit={handleSubmit}
          hasWrapper={true}
          orientationBreakpoint='1216px'
          loading={isSubmitting}
        />
      </SearchWrapper>
      {shortcuts.length > 0 && (
        <ShortcutsContainer>
          <ShortcutsTitle fontWeight='medium'>{gt.tp('HomePage', 'Shortcuts:')}</ShortcutsTitle>
          <ShortcutsList>
            {shortcuts.map(({ url, title, badge, enabledForFeatureFlag }) => (
              <VisibleIfFeatureFlag name={enabledForFeatureFlag} hasValue={true} key={url}>
                <Link href={url}>
                  <Shortcut onClick={() => pushUI(url, undefined)}>
                    <Heading5 as='div' fontWeight='medium'>
                      {gt.tp('HomePage', title)}
                    </Heading5>
                    {badge && <Badge {...badge} size='small' />}
                  </Shortcut>
                </Link>
              </VisibleIfFeatureFlag>
            ))}
          </ShortcutsList>
        </ShortcutsContainer>
      )}
    </ModalWrapper>
  )
}

export default Hero
