/*

<div>
  {t('Check the {{ author }} for more articles', {
    author: <Link to={`/authors/${author.id}`}>{author.name}</Link>
  })}
</div>

*/

// Note that [^] is used rather than . to match any character. This
// is because . doesn't span over multiple lines, whereas [^] does.
export const variableRegex = /(\{\{\s[^]+?(?=\s\}\})\s\}\})/g

/**
 * Returns whether a string is a template variable.
 */
export function isTemplateVariable(str: string) {
  return new RegExp(variableRegex).test(str)
}

/**
 * Interpolates a string, replacing template variables with values
 * provided in the scope.
 */
export function interpolateString(str: string, scope: any = {}) {
  if (!str) {
    return str
  }

  // Split string into array with regular text and variables split
  // into separate segments, like ['This is a ', '{{ thing }}', '!']
  const parts = str.split(new RegExp(variableRegex)).filter(x => x)

  // If the only thing we have is a single regular string, just return it as is
  if (parts.length === 1 && isTemplateVariable(parts[0]) === false) {
    return str
  }

  return parts
    .map(part => {
      if (isTemplateVariable(part) === false) {
        return part
      }

      const variableName = part.replace(/^\{\{\s/, '').replace(/\s\}\}$/, '')
      if (scope[variableName] === undefined) {
        return part
      }

      return scope[variableName]
    })
    .join('')
}
