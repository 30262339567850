// @ts-strict-ignore
import Pill from '~/shared/atoms/Pill'
import { ModalProps } from '~/shared/molecules/Modal'
import { CloseIcon as ModalCloseIcon } from '~/shared/molecules/Modal/ModalShards'

import { IconContainer, PillContainer } from './RestaurantCardShards'

export const loginModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed' as const,
  size: 'big' as const,
  iconSize: 'big' as const,
}

export const LoadingPills = () => (
  <PillContainer>{[1, 2, 3, 4, 5]?.map(slot => <Pill key={slot} loading={true} text={slot.toString()} />)}</PillContainer>
)

export const CloseIcon = ({ onClick }) => {
  return (
    <IconContainer onClick={onClick} isOutside={false} iconSize={'big'}>
      <ModalCloseIcon icon='x' isOutside={false} />
    </IconContainer>
  )
}
