import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import Collapse from '~/shared/atoms/Collapse'
import { Title } from '~/shared/atoms/Collapse/CollapseStyle'

export const Wrapper = styled.div`
  width: 100%;
`

export const StyledCollapse = styled(Collapse)`
  & > * {
    width: 100%;
  }

  ${Title} {
    font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  }
`

export const ContentWrapper = styled(Wrapper)`
  padding: 0 1.25rem;
  padding-bottom: 2rem;

  ${mediaQueryUp('m')} {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
  }

  ${mediaQueryUp('l')} {
    padding-left: 0;
    padding-right: 5rem;
  }
`
