import { gt } from '~/locale'

export const getDefaultMetadatas = () => ({
  title: gt.tp('Head', 'No1 ONLINE BOOKING AND FESTIVALS | RestaurantClub'),
  description: gt.tp(
    'Head',
    "Book top restaurants at a special price. Discover the show-stopping menus of Chefs and the world's most popular Restaurant Festival - RestaurantWeek®."
  ),
  imageUrl: 'https://restaurantclub.pl/assets/rc_share.png',
  imageAlt: 'rc_share',
  siteName: gt.tp('Head', 'RestaurantClub.pl'),
  url: 'https://restaurantclub.pl',
})
