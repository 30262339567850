import { components, ControlProps, DropdownIndicatorProps, MenuProps, PlaceholderProps } from 'react-select'

import { Heading6 } from '~/theme/utils/typography'

import Icon from '~/shared/atoms/Icon/IconView'
import { ActionWrapper } from '~/shared/atoms/Select/SelectShards'
import { StyledControl, StyledMenu } from '~/shared/molecules/SmartSearch/SmartSearchShards'
import {
  RestaurantOption,
  RestaurantOptionProps,
  SmartSearchOption,
  SmartSearchOptionProps,
  TagOption,
  TagOptionProps,
} from '~/shared/molecules/SmartSearch/SmartSearchTypes'

export const isTagOption = (option: SmartSearchOption): option is TagOption => option.details.__typename === 'Tag'
export const isRestaurantOption = (option: SmartSearchOption): option is RestaurantOption => option.details.__typename === 'Restaurant'

export const isTagOptionProps = (optionProps: SmartSearchOptionProps): optionProps is TagOptionProps => isTagOption(optionProps.data)
export const isRestaurantOptionProps = (optionProps: SmartSearchOptionProps): optionProps is RestaurantOptionProps =>
  isRestaurantOption(optionProps.data)

export const Control = ({ isFocused, innerProps, isDisabled, innerRef, children }: ControlProps<SmartSearchOption, false>) => (
  <StyledControl active={isFocused} disabled={isDisabled} ref={innerRef} {...innerProps}>
    {children}
  </StyledControl>
)

export const Menu = ({ children, innerProps, innerRef }: MenuProps<SmartSearchOption, false>) => (
  <StyledMenu {...innerProps} ref={innerRef}>
    {children}
  </StyledMenu>
)

export const IconWrapper = ({ innerProps, selectProps: { menuIsOpen } }: DropdownIndicatorProps<SmartSearchOption, false>) => (
  <ActionWrapper active={menuIsOpen} {...innerProps}>
    <Icon icon='caret-down' />
  </ActionWrapper>
)

export const Placeholder = ({ children, ...props }: PlaceholderProps<SmartSearchOption, false>) => (
  <components.Placeholder {...props}>
    <Heading6 as='span' fontWeight='light'>
      {children}
    </Heading6>
  </components.Placeholder>
)
