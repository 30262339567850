import { Region } from '~/generated/graphql'
import { locationLogger } from '~/shared/molecules/LocationMenu/LocationMenuUtils'

interface Position {
  readonly latitude: number
  readonly longitude: number
}

// WARSAW
const DEFAULT_POSITON: Position = {
  latitude: 52.229675,
  longitude: 21.01223,
}

const getDistance = (region: Pick<Region, 'longitude' | 'latitude'>, coords: Position) => {
  const toRad = (v: number) => (Math.PI * v) / 180
  const theta = region.longitude - coords.longitude
  let dist =
    Math.sin(toRad(region.latitude)) * Math.sin(toRad(coords.latitude)) +
    Math.cos(toRad(region.latitude)) * Math.cos(toRad(coords.latitude)) * Math.cos(toRad(theta))

  if (dist > 1) {
    dist = 1
  }

  dist = (Math.acos(dist) * 180) / Math.PI
  return dist
}

const getGeolocation = () =>
  new Promise<Position>(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          resolve(coords)
          locationLogger(`Got user geolocation. lat: ${coords.latitude}, long: ${coords.longitude}`)
        },
        _error => {
          resolve(DEFAULT_POSITON)
          locationLogger("Couldn't access geolocation. Using default position.")
        }
      )
    }
  })

export const getClosestRegionByGeolocation = async (regions: Array<Omit<Region, 'zones'>>) => {
  const position = await getGeolocation()
  return [...regions].sort((regionA, regionB) => getDistance(regionA, position) - getDistance(regionB, position))[0]
}
