// @ts-strict-ignore
import { Heading5, Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'
import Map from '~/shared/molecules/Map'

import { ContactDetails, IconButton, MapContainer, Section, StyledIcon, WebsiteSection, Wrapper } from './ContactShards'

const ContactView = ({ latitude, longitude, address, instagramUrl, facebookUrl, phoneNumber, webUrl, isMobile }) => {
  const Heading = isMobile ? Heading6 : Heading5

  return (
    <Wrapper>
      {latitude && longitude && (
        <MapContainer>
          <Map latitude={latitude} longitude={longitude} />
        </MapContainer>
      )}
      <ContactDetails>
        <Section>
          <Heading>{address || gt.tp('NoInformation', '-')}</Heading>
        </Section>
        <Section>
          <StyledIcon icon='phone-outline' />
          <Heading>{phoneNumber || gt.tp('NoInformation', '-')}</Heading>
        </Section>
        <WebsiteSection hasAddress={webUrl}>
          <StyledIcon icon='external-link' />
          <Heading>
            <Link href={webUrl}>{webUrl || gt.tp('NoInformation', '-')}</Link>
          </Heading>
        </WebsiteSection>
        {facebookUrl && (
          <a href={facebookUrl} target='_blank' rel='noopener noreferer noreferrer'>
            <IconButton icon='facebook' />
          </a>
        )}
        {instagramUrl && (
          <a href={instagramUrl} target='_blank' rel='noopener noreferer noreferrer'>
            <IconButton icon='instagram' />
          </a>
        )}
      </ContactDetails>
    </Wrapper>
  )
}

export default ContactView
