import Image from 'next/image'

import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const RestaurantCollectionSliderContainer = styled.div`
  position: relative;

  .alice-carousel__stage-item:last-child {
    padding-right: 0;
  }
`

export const BannersContainer = styled.div`
  width: 100%;
  position: relative;

  .alice-carousel__wrapper {
    width: 100vw;
  }
`

export const BannersOverlayLeft = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  height: 100%;
  left: -1rem;
  width: 6rem;
  justify-content: center;
  align-items: center;
`

export const BannerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  padding-right: 1.375rem;
`

export const BannerImage = styled(Image)`
  border-radius: 0.75rem;
  user-select: none;
`

export const BannersOverlayRight = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  height: 100%;
  right: -1rem;
  width: 6rem;
  justify-content: center;
  align-items: center;
`

export const Arrow = styled.div<{
  left: boolean
  active: boolean
}>`
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  cursor: pointer;

  ${mediaQueryUp('m')} {
    display: ${({ active, left }) => (!left && active ? 'flex' : 'none')};
  }

  img {
    height: 1.5rem;
    filter: ${({ theme }) => theme.colors.gray[1000]};
    transition: filter 0.3s;
    ${({ left }) => left && 'transform: rotate(180deg)'};
    pointer-events: none;
    user-select: none;
  }
`
