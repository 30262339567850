import { Heading4, Heading5 } from '~/theme/utils/typography'

import { Title } from './CollapseStyle'
import { CollapseSize } from './CollapseTypes'

export const titleAs: Record<CollapseSize, typeof Title> = {
  small: Heading5,
  regular: Heading4,
  big: Heading5,
}
