import { UserDataQuery, useUserDataQuery } from '~/generated/graphql'

interface UseUserOutput {
  user?: UserDataQuery['user']
  loading: boolean
}

const useUser = ({ returnBlankOnAnonymous = false } = {}): UseUserOutput => {
  const { data, loading } = useUserDataQuery({
    nextFetchPolicy: 'cache-first',
  })

  if (returnBlankOnAnonymous && !loading && data?.user?.anonymous) {
    return {
      user: null,
      loading,
    }
  }

  return {
    user: data?.user,
    loading,
  }
}

export default useUser
