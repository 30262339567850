// @ts-strict-ignore
import dayjs from 'dayjs'

import { getPossibleSlots, getTimeFromSlot } from '~/utils/graphqlDataFormatters'
import { dailySlotsGenerator } from '~/utils/helpers'

import { END_SLOT, MAX_NUMBER_OF_ATTENDEES, MIN_NUMBER_OF_ATTENDEES, SLOT_DURATION, SLOT_OFFSET, START_SLOT } from '~/constants'
import { gt as GT } from '~/locale'
import { DropdownItem } from '~/shared/atoms/Dropdown'

interface SlotItem extends DropdownItem {
  key: number | undefined
}

export const slotsItems = (slots): SlotItem[] =>
  slots
    .map(value => ({
      key: value,
      label: value ? getTimeFromSlot(value) : GT.tp('Search', 'Any time'),
    }))
    .sort((a, b) => a.key - b.key)

export const DEFAULT_SLOTS = getPossibleSlots(START_SLOT, END_SLOT, SLOT_DURATION + SLOT_OFFSET)

export const getDefaultDailySlots = (today: boolean): number[] => {
  const now = dayjs()
  if (today) {
    const currentMinute = now.minute()
    const minutesToNextHalfHour = 30 - (currentMinute % 30)
    const newTimeInMinutes = now.hour() * 60 + currentMinute + minutesToNextHalfHour + 120
    return dailySlotsGenerator(newTimeInMinutes, END_SLOT - 60, 30)
  }
  return dailySlotsGenerator(480, END_SLOT - 60, 30)
}

export const DEFAULT_DAILY_SLOTS = dailySlotsGenerator(480, END_SLOT - 60, 30)

export const getReservationSizes = (min = MIN_NUMBER_OF_ATTENDEES, max = MAX_NUMBER_OF_ATTENDEES) =>
  [...Array(max - min + 1)].map((_, i) => i + min)

export const peopleCount = (gt: typeof GT, showExtraGuest: boolean = false, min: number, max: number): DropdownItem[] => {
  const sizes = getReservationSizes(min, max).map(value => ({
    key: value,
    label: gt.tn('{{ count }} Guest', '{{ count }} Guests', value, {
      count: value,
    }),
  }))
  if (showExtraGuest) {
    sizes.push({
      key: max + 1,
      label: gt.tn('{{ count }}+ Guest', '{{ count }}+ Guests', max, {
        count: max,
      }),
    })
  }
  return sizes
}
