import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body2, Heading4, Heading5, Heading6, HeadingEyebrow, lineClamp, TileDescription, TileTitle } from '~/theme/utils/typography'

import { ContentContainer, StyledTile as TileContainer } from '~/shared/atoms/Tile/TileShards'
import Tile from '~/shared/atoms/Tile/TileView'

export const PhotoWrapper = styled.div<{
  ratio: string
  imageMargin: string
  horizontal: boolean
}>`
  height: 0;
  padding-top: ${({ ratio }) => `calc(${ratio} * 100%)`};
  margin: ${({ imageMargin }) => imageMargin};
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;

  ${mediaQueryUp('l')} {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
  }
`

export const Photo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const StyledTile = styled(Tile)`
  position: relative;
  max-width: 19rem;
  height: 100%;

  ${Photo} {
    min-width: 19rem;
    transition: transform 0.3s ease-in-out;
  }

  ${PhotoWrapper} {
    background: ${({ theme }) => theme.colors.gray[200]};
  }
  ${TileContainer},
  ${PhotoWrapper} {
    ${mediaQueryUp('m')} {
      border-radius: 1rem;
    }
  }
  ${ContentContainer} {
    flex: 1;
    min-width: 100%;
  }

  &:hover {
    cursor: pointer;

    ${Photo} {
      transform: scale(1.1);
    }
  }
`

export const StyledTileWide = styled(Tile)`
  border-radius: 0.75rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem;
  max-width: 28rem;

  & > * {
    width: 100%;
  }

  @media (min-width: 500px) {
    max-width: 45rem;
    padding: 2rem;
    border-radius: 0.5rem;

    & > * {
      width: unset;
    }
  }

  ${Heading4}, ${Heading5}, ${Heading6} {
    margin: 0;
  }
`

export const WidePhotoWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  max-width: 25rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    aspect-ratio: 1 / 1;
    margin-right: 2.5rem;
    margin-bottom: 0;
    min-width: 186px;
    max-width: 100%;
    height: auto;
  }
`

export const WideContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  @media (min-width: 500px) {
    flex-direction: row;
    flex-wrap: unset;
  }
`

export const WidePhoto = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const StyledTileTitle = styled(TileTitle)`
  color: ${({ theme }) => `${theme.colors.gray[1000]}`};
  margin-top: 0.75rem;
  font-size: ${({ theme }) => theme.text.fontSize.l};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
`

export const StyledTileDescription = styled(TileDescription)`
  color: ${({ theme }) => `${theme.colors.gray[800]}`};
`

export const StyledTileBoldDescription = styled(TileDescription)`
  color: ${({ theme }) => `${theme.colors.gray[1000]}`};
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  margin-right: 0.25rem;
`

export const BadgeContainer = styled.div`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex: 1;
  color: ${({ theme }) => theme.colors.gray[1000]};
  min-width: 180px;
  max-width: 200px;

  & button {
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

export const InfoRow = styled.div<{ marginBottomRem?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottomRem = '0.75' }) => marginBottomRem}rem;
  flex: 1;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Eyebrow = styled(HeadingEyebrow)`
  color: ${({ theme }) => `${theme.colors.gray[600]}`};
  text-transform: uppercase;
`

export const InfoTitle = styled(Heading5)`
  ${lineClamp(2)};
  margin-bottom: 0.5rem;
`

export const InfoSubtitle = styled(Body2)`
  ${lineClamp(1)}
  margin-top: auto;
  color: ${({ theme }) => theme.colors.gray[800]};
`
