import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading5 } from '~/theme/utils/typography'

import { StyledBadge } from '~/shared/atoms/Badge/BadgeShards'
import { NameInput, SubmitButton } from '~/shared/molecules/Search/SearchShards'
import { fontWeight } from '~/theme/text'

type FontWeight = keyof typeof fontWeight

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbf8ef;
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;
  text-align: center;
  padding: 2rem 1rem;
  position: relative;
  z-index: 0;

  ${mediaQueryUp('l')} {
    max-width: 1312px;
    padding: 5rem 1rem;
  }
`

export const SearchWrapper = styled.div`
  margin: 2rem auto 0;

  ${mediaQueryUp('m')} {
    max-width: 450px;
    & > div {
      width: 100%;
    }
  }

  ${SubmitButton} {
    width: 100%;
    transition: width 0s;
  }

  ${mediaQueryUp('l')} {
    margin: 3rem auto 0;
    width: initial;
    max-width: unset;

    ${SubmitButton} {
      width: 8rem;
    }

    ${NameInput} {
      min-width: 20rem;
    }
  }
`

export const Title = styled.h1<{ fontWeight?: FontWeight }>`
  color: ${({ theme }) => theme.colors.gray[1000]};
`

export const SubTitle = styled.h5<{ fontWeight?: FontWeight }>`
  color: ${({ theme }) => theme.colors.gray[700]};
  display: none;

  ${mediaQueryUp('l')} {
    margin-top: 1rem;
    display: block;
  }
`

export const ShortcutsTitle = styled(Heading5)`
  color: ${({ theme }) => theme.colors.gray[700]};
`

export const ShortcutsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 2.5rem;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 25rem;

  ${mediaQueryUp('l')} {
    margin-top: 3.25rem;
    max-width: unset;
  }
`

export const ShortcutsList = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
`

export const Shortcut = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[1000]};
  background: ${({ theme }) => theme.colors.gray[0]};
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  box-shadow: ${({ theme }) => theme.commons.shadow.xxs};
  transition: 0.1s ease-out;

  ${StyledBadge} {
    margin-left: 0.25rem;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.orange[200]};
    cursor: pointer;
  }
`

export const DesktopLeftImage = styled.img`
  position: absolute;
  left: -5%;
  top: 0;
  height: 100%;
  z-index: -1;
  display: none;

  ${mediaQueryUp('l')} {
    display: block;
  }
`

export const DesktopRightImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  display: none;

  ${mediaQueryUp('l')} {
    display: block;
  }
`

export const ModalWrapper = styled.div`
  background: #fbf8ef;
  box-shadow: ${({ theme }) => theme.commons.shadow.l};
  padding: 1.5rem;
  width: 100%;

  ${Title} {
    text-align: center;

    ${mediaQueryUp('m')} {
      width: 80%;
      margin: 0 auto;
    }
  }

  ${SearchWrapper} {
    box-shadow: ${({ theme }) => theme.commons.shadow.xl};
  }

  ${SubmitButton} {
    width: 100%;
  }

  ${mediaQueryUp('m')} {
    padding: 2rem 1.5rem;
    border-radius: 0.75rem;
  }

  ${mediaQueryUp('l')} {
    padding: 3.75rem 4rem 3rem;
  }
`

export const MobileBottomImage = styled.img`
  width: 100%;
  max-width: 25rem;
  margin: auto;
  margin-top: 2rem;

  ${mediaQueryUp('m')} {
    margin-top: 4rem;
  }

  ${mediaQueryUp('l')} {
    display: none;
  }
`

export const LocationMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;
  max-width: 90vw;
  overflow: hidden;

  & > span {
    margin-right: 0.5rem;
  }

  ${mediaQueryUp('l')} {
    display: none;
  }
`
