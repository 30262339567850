// @ts-strict-ignore
import { css } from '@emotion/react'

import { AutoplacementData } from '../Tooltip/TooltipTypes'

import { ShowOnTriggerOrientation, ShowOnTriggerPlacement } from './ShowOnTriggerTypes'

interface Dimensions {
  width: number
  height: number
}

export const getWrapperPositionStyle = (
  rect: DOMRect,
  placementData: ShowOnTriggerPlacement | AutoplacementData,
  contentWidth: number,
  verticalOffset: number
) => {
  const { left, right, width, height, top } = rect
  const placement = typeof placementData === 'string' ? placementData : 'auto'
  const offset = verticalOffset ?? 16
  const cWidth = contentWidth || right - left

  const config = {
    up: () => css`
      top: ${top - offset}px;
      left: ${left + width / 2}px;
      transform: translateX(-22px) translateY(-100%);
    `,
    down: () => css`
      top: ${top + height + offset}px;
      left: ${left}px;
    `,
    right: () => css`
      left: ${left + width + offset}px;
      top: ${top + height / 2}px;
      transform: translateY(-50%);
    `,
    left: () => css`
      left: ${left - offset}px;
      top: ${top + height / 2}px;
      transform: translateY(-50%) translateX(-100%);
    `,
    'down-left': () => css`
      top: ${top + height + offset}px;
      left: ${left - cWidth + offset}px;
    `,
    'down-right': () => css`
      top: ${top + height + offset}px;
      left: ${left - offset}px;
    `,

    auto: () => {
      const { orientation, content, overflowX } = placementData as AutoplacementData

      const vertical = {
        up: top - offset - content?.height,
        down: top + height + offset,
        left: top + height * 0.5 - content?.height / 2,
        right: top + height * 0.5 - content?.height / 2,
      }

      const horizontal = {
        left: left - offset - content?.width,
        right: right + offset,
        up: right - width / 2 - content?.width / 2,
        down: right - width / 2 - content?.width / 2,
      }

      return css`
        top: ${vertical[orientation] + 'px'};
        left: ${horizontal[orientation] + 'px'};
        transform: translateX(${-overflowX + 'px'}) translateY(0);
      `
    },
  }
  return config[placement]()
}

export const autoLocate = (trigger: DOMRect, content: Dimensions, window: Dimensions) => {
  const screenPadding = 32
  const placement = {
    orientation: 'down' as ShowOnTriggerOrientation,
    content,
    overflowX: 0,
    overflowY: 0,
  }

  placement.orientation = getOrientation(trigger, content, window, screenPadding)

  if (placement.orientation === 'up' || placement.orientation === 'down') {
    placement.overflowX = getOverflowX(trigger, content, window, screenPadding)
  }

  return placement
}

export const getOrientation = (trigger: DOMRect, content: Dimensions, window: Dimensions, screenPadding: number) => {
  if (trigger.bottom + content.height + screenPadding > window.height) {
    if (trigger.bottom - content.height - screenPadding >= 0) return 'up'
    if (trigger.left - content.width - screenPadding >= 0) return 'left'
    if (trigger.right + content.width + screenPadding <= window.width) return 'right'
  }
  return 'down'
}

export const getOverflowX = (trigger, content, window, screenPadding) => {
  const triggerCenter = trigger.right - trigger.width / 2
  const overflowLeft = triggerCenter - content.width / 2 - screenPadding
  const overflowRight = window.width - (triggerCenter + content.width / 2 + screenPadding)

  if (overflowLeft < 0) return overflowLeft
  if (overflowRight < 0) return -overflowRight
  return 0
}
