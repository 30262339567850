import styled from '@emotion/styled'

import { Body2 } from '~/theme/utils/typography'

import { BadgeColor, BadgeSize, BadgeTheme } from './BadgeTypes'

export const StyledBadge = styled(Body2)<{ color: BadgeColor; size: BadgeSize; badgeTheme?: BadgeTheme }>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  background: ${({ theme, color, badgeTheme }) => badgeTheme?.backgroundColor || theme.colors[color][200]};
  color: ${({ theme, color, badgeTheme }) => badgeTheme?.textColor || theme.colors[color][400]};
  ${({ size, theme }) =>
    size === 'small' &&
    `
    padding: .25rem .375rem;
    line-height: ${theme.text.lineHeight.none};
    letter-spacing: ${theme.text.letterSpacing.wider};
  `};
`
