import { rgba } from 'polished'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const spinning = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const Content = styled.span<{ color?: string }>`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  border: ${({ color, theme }) => `3px solid ${color ? rgba(color, 0.7) : rgba(theme.colors.gray[0], 0.7)}`};
  border-radius: 50%;
  border-left-color: ${({ color, theme }) => color || theme.colors.gray[0]};
  transform: rotate(45deg);
  animation: ${spinning} 2s -1s infinite;
`
