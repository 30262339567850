// @ts-strict-ignore
import dayjs from 'dayjs'
import { ParsedUrlQuery } from 'querystring'

import { FilterSettings } from '~/components/Listing/ListingPageContent'
import { URLQuery } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelTypes'
import { Tag } from '~/generated/graphql'
import { SearchSettings } from '~/shared/molecules/Search'

import { dayjsToString } from './graphqlDataFormatters'

export const queryToSearchSettings = (query: URLQuery): SearchSettings => {
  if (!query?.date || !query?.slot || !query.peopleCount) {
    return null
  }

  return {
    name: query.name,
    slot: parseInt(query.slot.toString(), 10),
    peopleCount: parseInt(query.peopleCount.toString(), 10),
    date: dayjs.parseZone(query.date.toString(), 'YYYY-MM-DD'),
  }
}

export const searchSettingsToQuery = (settings: SearchSettings): ParsedUrlQuery => {
  const params = {
    ...settings,
    date: settings?.date && dayjsToString(settings.date),
  }

  return Object.fromEntries(
    Object.entries(params)
      .filter(([, v]) => !!v)
      .map(([k, v]) => [k, v.toString()])
  )
}

const TAG_TYPE_QUERY_SEPARATOR = '-'
export const TAG_TYPE_QUERY_PREFIX = 'tag' + TAG_TYPE_QUERY_SEPARATOR
const TAG_TYPE_IDS_SEPARATOR = ','

export const filtersToQuery = (filters: Pick<FilterSettings, 'tags'>) => {
  return filters.tags.reduce(
    (acc, tag) => {
      const tagTypeKey = TAG_TYPE_QUERY_PREFIX + tag.type
      const tagsByType = acc[tagTypeKey]

      if (tagsByType) {
        acc[tagTypeKey] += TAG_TYPE_IDS_SEPARATOR + tag.id
      } else {
        acc[tagTypeKey] = tag.id
      }

      return acc
    },
    {} as Record<Tag['type'], string>
  )
}

export const filtersFromQuery = (query: ParsedUrlQuery) => {
  const tagsFromQuery = Object.entries(query)
    .filter(([key]) => key.startsWith(TAG_TYPE_QUERY_PREFIX))
    .reduce(
      (acc, [key, ids]) => {
        if (typeof ids !== 'string') return acc

        ids.split(TAG_TYPE_IDS_SEPARATOR).forEach(id => {
          acc.push({ type: key.split(TAG_TYPE_QUERY_SEPARATOR)[1], id })
        })

        return acc
      },
      [] as Pick<Tag, 'id' | 'type'>[]
    )

  return {
    tags: tagsFromQuery,
  }
}
