import * as React from 'react'

import NextHead from 'next/head'

import HeadManifest from './HeadManifest'
import HeadSEO from './HeadSEO'
import { MetadataType } from './HeadTypes'

const Head: React.FC<{ metadata?: MetadataType; children?: React.ReactNode }> = ({ metadata, children }) => (
  <>
    <NextHead>
      <HeadManifest metadata={metadata} />
      {children}
    </NextHead>
    <HeadSEO metadata={metadata} />
  </>
)

export default Head
