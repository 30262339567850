// @ts-strict-ignore
import { css, SerializedStyles } from '@emotion/react'

import { AutoplacementData, TooltipPlacement, TooltipSize } from './TooltipTypes'

export const getTooltipPositionStyle = (placementData: TooltipPlacement | AutoplacementData, size: TooltipSize): SerializedStyles => {
  const arrowWidth = size === 'big' ? 24 : 16
  const offsetX = size === 'big' ? -22 : -18
  const offsetY = size === 'big' ? 2 : 6

  const config: Record<TooltipPlacement, any> = {
    up: css`
      bottom: -14px;
      left: 14px;
      transform: rotate(180deg);
    `,
    down: css`
      top: -14px;
      right: 14px;
    `,
    right: css`
      left: ${offsetX}px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    `,
    left: css`
      right: ${offsetX}px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    `,
    auto: ({ orientation, content, overflowX }) => {
      const rotation = {
        up: 180,
        down: 0,
        left: 90,
        right: 270,
      }
      const horizontal = {
        up: content?.width / 2 + overflowX - arrowWidth / 2,
        down: content?.width / 2 + overflowX - arrowWidth / 2,
        left: content?.width - arrowWidth / 6,
        right: -arrowWidth + arrowWidth / 6,
      }
      const vertical = {
        up: content?.height + offsetY,
        down: -arrowWidth / 2 - offsetY,
        left: content?.height / 2,
        right: content?.height / 2,
      }
      return css`
        top: ${vertical[orientation] + 'px'};
        left: ${horizontal[orientation] + 'px'};
        transform: rotate(${rotation[orientation] + 'deg'});
      `
    },
  }

  return typeof placementData === 'string' ? config[placementData] : config.auto(placementData)
}
