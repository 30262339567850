import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { breakpoints, mediaQueryUp } from '~/theme/utils/grid'

import { BasicIcon } from '~/shared/atoms/Icon'

import { ModalSize, StyledModalProps } from './ModalTypes'

export const ModalOverlay = styled.div<{ isVisible: boolean; centerVerticallyOnMobile?: boolean; isMobileNavigation?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 10;
  background: ${({ isVisible }) => (isVisible ? 'rgba(0,0,0,0.33)' : 'transparent')};

  ${({ isMobileNavigation }) =>
    isMobileNavigation &&
    css`
      padding-bottom: 5.4rem;
    `}

  ${({ centerVerticallyOnMobile }) =>
    centerVerticallyOnMobile &&
    css`
      display: flex;

      ${ModalWrapper} {
        margin: auto;
      }
    `}

  ${mediaQueryUp('m')} {
    display: flex;
  }
`

export const ModalWrapper = styled.div`
  min-width: fit-content;
  max-width: 1280px;

  ${mediaQueryUp('m')} {
    padding: 3rem;
    padding-top: 5rem;
    margin: auto;
    flex: 1;
  }

  @media (min-width: 768px) {
    padding: 6rem;
    min-width: unset;
  }
`

export const StyledModal = styled.div<StyledModalProps>`
  position: relative;
  z-index: 2;
  width: 100%;
  height: ${({ fillScreenOnMobile }) => (fillScreenOnMobile ? '100%' : 'fit-content')};
  margin: auto;

  ${mediaQueryUp('m')} {
    height: fit-content;
    width: ${({ size }) => (size === 'big' ? '100%' : 'fit-content')};
    ${({ position }) =>
      position === 'fixed' &&
      `
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
  }

  ${({ position }) =>
    position === 'anchored' &&
    `
      position: absolute;
      height: fit-content;
      margin: unset;
    `}

  & > *:first-child {
    min-height: ${({ fillScreenOnMobile }) => (fillScreenOnMobile ? '100vh' : 'fit-content')};

    ${mediaQueryUp('m')} {
      min-height: unset;
    }
  }
`

export const CloseIcon = styled(BasicIcon)<{
  isOutside: boolean
  showOutsideIconOnMobile?: boolean
}>`
  filter: ${({ theme }) => theme.filters.gray[900]};

  ${({ isOutside, theme, showOutsideIconOnMobile }) =>
    isOutside &&
    showOutsideIconOnMobile &&
    `
      filter: ${theme.filters.gray[800]};
    `};

  ${mediaQueryUp('m')} {
    ${({ isOutside, theme }) =>
      isOutside &&
      `
      filter: ${theme.filters.gray[800]};
    `}
  }
`

export const CloseIconContainer = styled.div<{
  isOutside: boolean
  showOutsideIconOnMobile?: boolean
  iconSize: ModalSize
}>`
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  top: ${({ iconSize }) => (iconSize === 'big' ? '1rem' : '.5rem')};
  right: ${({ iconSize }) => (iconSize === 'big' ? '1rem' : '.5rem')};
  z-index: 3;

  ${CloseIcon} {
    height: ${({ iconSize }) => (iconSize === 'big' ? '.875rem' : '.625rem')};
    width: ${({ iconSize }) => (iconSize === 'big' ? '.875rem' : '.625rem')};
  }

  ${({ theme, isOutside, showOutsideIconOnMobile }) =>
    isOutside &&
    showOutsideIconOnMobile &&
    `
        background-color: ${theme.colors.gray[0]};

        @media (max-width: ${breakpoints.m}) {
          top: -3rem;
          right: 0;
          height: 2rem;
          width: 2rem;
  
          ${CloseIcon} {
            height: .625rem;
            width: .625rem;
          }
        }
    `};

  ${mediaQueryUp('m')} {
    ${({ theme, isOutside, iconSize }) =>
      isOutside &&
      `
        background-color: ${theme.colors.gray[0]};
        top: ${iconSize === 'big' ? '-3.5rem' : '-2.25rem'};
        right: 0;
        height: ${iconSize === 'big' ? '2.5rem' : '1.75rem'};
        width: ${iconSize === 'big' ? '2.5rem' : '1.75rem'};
      `}
  }
`
