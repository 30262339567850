// @ts-strict-ignore
import { useRouter } from 'next/router'

import { MutationHookOptions } from '@apollo/client'
import { MutationTuple } from '@apollo/client/react/types/types'

import { useResolveRestaurantCodeQuery } from '~/generated/graphql'

const useReservationCreate = <TData extends unknown, TVariables extends { reservationSource?: number; sourceId?: number }>({
  useCreateReservationMutation,
  options = {},
}: {
  useCreateReservationMutation: (options: MutationHookOptions<TData, TVariables>) => MutationTuple<TData, TVariables>
  options?: MutationHookOptions<TData, TVariables>
}) => {
  const router = useRouter()
  const { data } = useResolveRestaurantCodeQuery({ variables: { code: router.query.code as string }, skip: !router.query.code })
  const sourceId = data?.decodeRestaurant.sourceId
  return useCreateReservationMutation({
    ...options,
    variables: { ...options.variables, reservationSource: sourceId, sourceId },
  })
}

export default useReservationCreate
