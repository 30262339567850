// @ts-strict-ignore
import { useEffect, useState } from 'react'

import { TagFragmentFragment } from '~/generated/graphql'
import { gt as GT } from '~/locale'
import { DropdownItem } from '~/shared/atoms/Dropdown'

import { Props } from './TagBarTypes'

type UseTag = (input: Pick<Props, 'restaurant' | 'reservableExtra'>, maxBaseTagCount: number) => (string | TagFragmentFragment)[]

const getRestaurantTags = ({ tags, address, zone }: Props['restaurant'], maxBaseTagCount) => {
  if (!tags) {
    return []
  }
  const priceTag = [...tags].sort((a, b) => b.position - a.position).find(tag => tag.type === 'Price')

  const baseTags = [...tags]
    ?.sort((a, b) => a.position - b.position)
    .filter(tag => tag?.type !== 'Price')
    .slice(0, maxBaseTagCount)

  return [...baseTags, priceTag]
}

const getReservableExtraTags = (_reservableExtra: Props['reservableExtra']) => {
  return []
}

export const getAddress = (address, zone) => address + (zone ? `, ${zone.name}` : '')

export const useTags: UseTag = ({ restaurant, reservableExtra }, maxBaseTagCount) => {
  const [data, setData] = useState(restaurant ? getRestaurantTags(restaurant, maxBaseTagCount) : getReservableExtraTags(reservableExtra))

  useEffect(() => {
    setData(restaurant ? getRestaurantTags(restaurant, maxBaseTagCount) : getReservableExtraTags(reservableExtra))
  }, [restaurant, reservableExtra])

  return data
}

type UseOpeningTime = (
  input: Props['restaurant'],
  gt: typeof GT
) => {
  currentTitle: string
  items: DropdownItem[]
}

export const useOpeningTime: UseOpeningTime = (restaurant, gt) => {
  const states = {
    open: gt.tp('TagBar', 'Open'),
    closed: gt.tp('TagBar', 'Closed'),
  }

  const weekdays = [
    gt.tp('TagBar', 'Monday'),
    gt.tp('TagBar', 'Tuesday'),
    gt.tp('TagBar', 'Wednesday'),
    gt.tp('TagBar', 'Thursday'),
    gt.tp('TagBar', 'Friday'),
    gt.tp('TagBar', 'Saturday'),
    gt.tp('TagBar', 'Sunday'),
  ]

  const items: DropdownItem[] = restaurant.openingHours.map((hours, index) => {
    const weekdayText = weekdays[parseInt(hours.day, 10) - 1]

    if (!hours?.from) {
      return {
        key: index,
        label: `${weekdayText} (${hours?.from} – ${hours?.to})`,
      }
    }

    return {
      key: index,
      label: `${weekdayText} (${hours?.from} – ${hours?.to})`,
    }
  })

  const currentTitle = `${states[restaurant.openingState]}${
    restaurant.openingHoursToday?.from ? ` (${restaurant.openingHoursToday?.from} – ${restaurant.openingHoursToday?.to})` : ''
  }`

  return {
    currentTitle,
    items,
  }
}
