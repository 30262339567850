// @ts-strict-ignore
import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { HeadingEyebrow } from '~/theme/utils/typography'

import { SelectIconWrapper } from '../Select/SelectShards'

import { CustomStyles } from './DropdownTypes'

export const Button = styled.div<{ selected?: boolean; hasIcon?: boolean; customStyles?: CustomStyles }>`
  ${({ theme }) => getSelectTextStyles({ theme })};
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.gray[1000]};
  transition: 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
  font-size: ${({ theme }) => theme.text.fontSize.m};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  line-height: ${({ theme }) => theme.text.lineHeight.relaxed};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};

  &:hover {
    background: ${({ theme }) => theme.colors.orange[200]};
  }

  ${({ customStyles }) =>
    customStyles?.singleLine &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ selected, hasIcon, theme }) =>
    selected &&
    !hasIcon &&
    css`
      background: ${theme.colors.orange[400]};
      color: ${theme.colors.gray[0]};

      &:hover {
        background: ${theme.colors.orange[200]};
        color: ${theme.colors.gray[1000]};
      }
    `}

  ${({ selected, hasIcon, theme }) =>
    selected &&
    hasIcon &&
    css`
      background: ${theme.colors.orange[200]};
      color: ${theme.colors.gray[1000]};

      &:hover {
        background: ${theme.colors.orange[200]};
        color: ${theme.colors.gray[1000]};
      }
    `}
`

export const Title = styled(HeadingEyebrow)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[500]};
  padding: 0.5rem 1rem;
  pointer-events: none;
`

export const BorderedButton = styled(Button)`
  color: ${({ theme }) => theme.colors.orange[500]};
  border-radius: 0;
  position: relative;
  margin: 0.5rem auto 0;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  background-color: ${({ theme }) => theme.colors.gray[0]};

  &:hover {
    color: ${({ theme }) => theme.colors.orange[600]};
    background-color: ${({ theme }) => theme.colors.gray[0]};
  }

  &::after {
    content: ' ';
    position: absolute;
    display: inline-block;
    top: 0;
    left: -0.5rem;
    width: calc(100% + 1rem);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray[300]};
  }
`

export const getButtonStyles = ({ theme, selected }: { theme: Theme; selected: boolean }) => css`
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: 0.2s ease-in-out;
  pointer-events: auto;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  background: ${selected ? theme.colors.orange[400] : theme.colors.gray[0]};
  color: ${selected ? theme.colors.gray[0] : theme.colors.gray[1000]};

  &:hover {
    background: ${theme.colors.orange[selected ? 400 : 200]};
    color: ${theme.colors.gray[selected ? 0 : 1000]};
  }
`

export const getSelectTextStyles = ({ theme }: { theme: Theme }) => css`
  font-size: ${theme.text.fontSize.m};
  font-weight: ${theme.text.fontWeight.medium};
  line-height: ${theme.text.lineHeight.relaxed};
  letter-spacing: ${theme.text.letterSpacing.normal};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const getListStyles = ({ show, theme }: { show: boolean; theme: Theme }) => css`
  display: flex;
  opacity: ${show ? 1 : 0};
  pointer-events: ${show ? 'auto' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  background: ${theme.colors.gray[0]};
  border: 1px solid ${theme.colors.gray[200]};
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: 100%;
  width: auto;
  transition: opacity 0.2s ease-in-out;
  z-index: 20;
  max-height: 14rem;
  overflow-y: auto;
  position: absolute;
`

export const List = styled.div<{ show: boolean; customStyles?: CustomStyles; elementHeight: number }>`
  ${({ show, theme }) => getListStyles({ show, theme })};

  & ${Button}, & ${Title}, & ${BorderedButton} {
    height: ${({ elementHeight }) => `${elementHeight}px`};
    pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  }

  top: ${({ customStyles }) => `${-1 + customStyles?.marginTopInRem}rem` || 0};

  ${({ customStyles }) =>
    customStyles?.shadow &&
    css`
      box-shadow: ${customStyles.shadow};
    `}
`

export const Trigger = styled.div`
  cursor: pointer;
  width: fit-content;
`

export const DropdownIconWrapper = styled(SelectIconWrapper)<{ selected: boolean; unfilterableColor: boolean }>`
  & img {
    width: 1.25rem;

    ${({ selected, theme, unfilterableColor }) =>
      !unfilterableColor &&
      selected &&
      css`
        filter: ${theme.filters.orange[400]};

        &:hover {
          filter: ${theme.filters.orange[500]};
        }
      `}
  }
`
