import { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash'

import { Heading4, Heading6 } from '~/theme/utils/typography'

import { TagFragmentFragment } from '~/generated/graphql'
import { gt } from '~/locale'

import { DescriptionGrid, DescriptionText, ExpandTextButton, Item, ItemCategory, ItemContent } from './DescriptionShards'
import { getRestaurantDetails, toggleExpandButton } from './DescriptionUtils'

interface DescriptionViewProps {
  chef: string
  description: string
  establishmentYear: number
  tags: TagFragmentFragment[]
  isMobile: boolean
}

const DescriptionView = ({ chef, description, establishmentYear, tags, isMobile }: DescriptionViewProps) => {
  const [expandText, setExpandText] = useState(false)
  const [showReadMoreBtn, setShowReadMoreBtn] = useState(false)
  const restaurantDetails = getRestaurantDetails(chef, establishmentYear, tags)
  const descriptionText = useRef(null)

  useEffect(() => {
    const btn = descriptionText?.current
    toggleExpandButton(btn, setShowReadMoreBtn)
    const debouncedHandleResize = debounce(() => {
      toggleExpandButton(btn, setShowReadMoreBtn)
    }, 500)
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])

  return (
    <>
      <DescriptionGrid>
        {restaurantDetails.map(
          item =>
            item.content && (
              <Item key={item.title} as={isMobile ? Heading6 : 'div'}>
                <ItemCategory as={isMobile ? 'span' : Heading6}>
                  {item.title}
                  {isMobile && ': '}
                </ItemCategory>
                <ItemContent as={isMobile ? 'span' : Heading4}>{item.content}</ItemContent>
              </Item>
            )
        )}
      </DescriptionGrid>
      <DescriptionText ref={descriptionText} as='div' fontWeight='regular' expandText={expandText}>
        {description}
      </DescriptionText>
      {showReadMoreBtn && (
        <ExpandTextButton onClick={() => setExpandText(!expandText)} as='button' fontWeight='medium'>
          {expandText ? gt.tp('ProductDetails', 'Read less') : gt.tp('ProductDetails', 'Read more')}
        </ExpandTextButton>
      )}
    </>
  )
}

export default DescriptionView
