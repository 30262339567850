import { Content } from './LoaderShards'

interface Props {
  color: string
  className?: string
}

const Loader: React.FC<Props> = ({ color, className }) => {
  return <Content color={color} className={className} />
}

export default Loader
