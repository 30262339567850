import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { BasicIcon } from '../Icon'
import Loader from '../Loader'

export const LikeButton = styled.button<{ isActive: boolean; isVisible: boolean }>`
  border-radius: 50%;
  pointer-events: none;
  position: relative;
  height: 1.5rem;
  width: 1.5rem;

  ${({ isActive, isVisible, theme }) =>
    isVisible &&
    css`
      box-shadow:
        0px 0px 2px rgba(0, 0, 0, 0.2),
        0px 2px 10px rgba(0, 0, 0, 0.1);
      height: 2rem;
      width: 2rem;
      background: ${isActive ? theme.colors.green[100] : theme.colors.gray[0]};

      &:hover {
        background: ${theme.colors.green[100]};
      }
    `}
`

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    & img {
      filter: ${({ theme }) => theme.filters.green[400]};
    }
    & h6 {
      color: ${({ theme }) => theme.colors.green[400]};
    }
  }
`

export const LoaderContainer = styled.div<{ isVisible: boolean; hasTitle: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  height: 1rem;
  width: auto;
  aspect-ratio: 1 / 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  ${({ hasTitle }) =>
    hasTitle &&
    css`
      height: 1.5rem;

      ${mediaQueryUp('l')} {
        height: 1.25rem;
      }
    `}
`

export const StyledLoader = styled(Loader)<{ hasTitle: boolean }>`
  height: ${({ hasTitle }) => (hasTitle ? 'calc(100% - 2px)' : '100%')};
  width: auto;
  aspect-ratio: 1 / 1;
`

export const IconContainer = styled(LoaderContainer)<{ hasTitle: boolean; isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  height: 0.875rem;

  ${({ hasTitle }) =>
    hasTitle &&
    css`
      height: 1.5rem;

      ${mediaQueryUp('l')} {
        height: 1.25rem;
      }
    `}
`

export const LikeIcon = styled(BasicIcon)<{ isFavorited: boolean }>`
  z-index: 2;
  height: 100%;
  filter: ${({ theme, isFavorited }) => (isFavorited ? theme.filters.green[400] : theme.filters.gray[600])};
`
