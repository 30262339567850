// @ts-strict-ignore
import { Formik } from 'formik'

import { ApolloError } from '@apollo/client'

import { Heading3, Heading6 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { errors as errorsList } from '~/constants/errors'
import { useResetPasswordRequestMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import { useToast } from '~/shared/atoms/Toast'
import { useModal } from '~/shared/molecules/Modal'

import CheckYourEmailModal from '../CheckYourEmailModal'

import { StyledForgotPasswordModal, StyledForm, StyledToastContainer } from './ForgotPasswordModalShards'
import { FormValues } from './ForgotPasswordModalTypes'
import { successModalProps, validationSchema } from './ForgotPasswordModalUtils'

const ForgotPasswordModalView = () => {
  const [resetPassword] = useResetPasswordRequestMutation()
  const modal = useModal()
  const toast = useToast()

  const handleErrors = (error: ApolloError, setFieldTouched: any) => {
    const errors = formatErrorMessages(error)
    errors?.map(error =>
      toast.add({
        message: error,
        type: 'error',
        containerSelector: '#ForgotPasswordToastContainer',
        size: 'small',
      })
    )
    setFieldTouched('email', false)
  }

  const handleSubmit = async (values: FormValues, setSubmitting: any, setFieldTouched: any) => {
    await resetPassword({
      variables: values,
      onCompleted: () => {
        modal.open(successModalProps, <CheckYourEmailModal handleClose={modal.close} />)
      },
      onError: error => handleErrors(error, setFieldTouched),
    })
    await setSubmitting(false)
  }

  return (
    <StyledForgotPasswordModal>
      <Heading3 fontWeight='semiBold'>{gt.tp('ForgotPasswordModal', 'Forgot password')}</Heading3>
      <Heading6 fontWeight='regular'>
        {gt.tp('ForgotPasswordModal', 'Enter the email address used for registration to receive a password reset link')}
      </Heading6>
      <Formik<FormValues>
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldTouched }) => handleSubmit(values, setSubmitting, setFieldTouched)}>
        {({ values, handleSubmit, isSubmitting, isValid, handleChange, handleBlur, setFieldTouched, touched, errors }) => (
          <StyledForm>
            <StyledToastContainer id={'ForgotPasswordToastContainer'} />
            <Input
              height='normal'
              width='full'
              title={gt.tp('ForgotPasswordModal', 'Email*')}
              type={'email'}
              error={!!errors.email}
              onChange={e => {
                handleChange(e)
                setFieldTouched('email', true)
              }}
              value={values.email}
              name={'email'}
              onBlur={handleBlur}
              errorMessage={!!errors.email && gt.tp('Error', errorsList.validation.email.invalid)}
              required
            />
            <Button
              label={gt.tp('ForgotPasswordModal', 'Send a password reset link')}
              color='green'
              width='full'
              height='big'
              fontWeight='regular'
              onClick={handleSubmit}
              disabled={!isValid || isSubmitting || !touched.email}
              loading={isSubmitting}
            />
          </StyledForm>
        )}
      </Formik>
    </StyledForgotPasswordModal>
  )
}

export default ForgotPasswordModalView
