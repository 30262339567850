// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'

import { CommonNinjaWidget } from 'commonninja-react'

import { waitForElement } from '~/utils/waitForElement'

interface Props {
  widgetId: string
}

export const CouponBar = ({ widgetId }: Props) => {
  const containerId = `coupon-bar-widget-${widgetId}`
  const [isLoaded, setIsLoaded] = useState(false)
  const [barHeight, setBarHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const shadowRootRef = useRef<ShadowRoot | null>(null)

  useEffect(() => {
    if (isLoaded) {
      waitForElement({
        getEl: () => document.getElementById(containerId)?.firstElementChild?.shadowRoot,
      }).then(el => {
        if (el) {
          shadowRootRef.current = el

          const observer = new MutationObserver(() => {
            const notificationBar = el.querySelector('.notification-bar')
            if (notificationBar) {
              const height = notificationBar.getBoundingClientRect().height
              setBarHeight(height)
            }
          })

          observer.observe(el, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['class', 'style'],
          })

          return () => {
            observer.disconnect()
          }
        }
      })
    }
  }, [containerId, isLoaded])

  return (
    <div ref={containerRef} style={{ height: barHeight }} id={containerId}>
      <CommonNinjaWidget onLoad={() => setIsLoaded(true)} widgetId={widgetId} />
    </div>
  )
}
