import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1.8;

  &::after {
    content: '';
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 11.32%),
      linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 11.32%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  ${mediaQueryUp('l')} {
    border-radius: 0.5rem;
    overflow: hidden;
  }
`

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0.75rem;
  position: absolute;
  pointer-events: none;
  z-index: 1;

  ${mediaQueryUp('m')} {
    bottom: 1rem;
  }
`

export const IndicatorCircle = styled.div<{ active: boolean }>`
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.gray[0]};
  opacity: ${({ active }) => (active ? '1' : '.5')};
  border-radius: 1rem;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }
`

export const ButtonWrapper = styled.div`
  display: none;
  left: 1rem;
  bottom: 1rem;
  position: absolute;
  z-index: 1;

  ${mediaQueryUp('m')} {
    display: block;
  }
`

export const SliderImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 55.55%; // (100 / 1.8 = 55.55)

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const ActionWrapper = styled.div`
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: none;
  justify-content: space-between;
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 0 1rem;

  ${mediaQueryUp('m')} {
    display: flex;
  }
`

export const ActionButton = styled.button`
  background: transparent;
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: drop-shadow(${({ theme }) => theme.commons.shadow.m});

  img {
    height: auto;
    height: 1.75rem;
    filter: ${({ theme }) => theme.filters.gray[0]};
  }

  &:last-child img {
    transform: rotate(180deg);
  }
`
