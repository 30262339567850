// @ts-strict-ignore
import * as React from 'react'
import { ReactNode, useMemo, useRef, useState } from 'react'
import AliceCarousel, { AutoPlayStrategy } from 'react-alice-carousel'

import Image from 'next/image'

import { useUpdateEffect } from '~/utils/hooks/useUpdateEffect'
import { imgProxyLoader } from '~/utils/imgproxy'

import { ImageFile, Restaurant } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'

import RestaurantGallery from './RestaurantGallery'
import {
  ActionButton,
  ActionWrapper,
  ButtonWrapper,
  Container,
  IndicatorCircle,
  IndicatorWrapper,
  SliderImage,
} from './RestaurantSliderShards'

export type SliderImg = Pick<ImageFile, 'id' | 'original' | 'preview'>

interface Props {
  restaurantName: Restaurant['name']
  images: SliderImg[]
  withFullGallery?: boolean
  withControls?: boolean
  autoPlay?: boolean
  autoPlayInterval?: number
  renderItem?: (image: SliderImg, restaurantName: string) => ReactNode
  autoHeight?: boolean
  mouseTracking?: boolean
}

const defaultRenderItem = (image: SliderImg, restaurantName: string) => (
  <SliderImage key={image.id}>
    <Image
      loader={imgProxyLoader}
      src={image?.original || image?.preview}
      quality={80}
      alt={restaurantName}
      layout='fill'
      objectFit='cover'
      priority={true}
    />
  </SliderImage>
)

const RestaurantSlider: React.FC<Props> = ({
  restaurantName,
  images,
  withFullGallery = true,
  withControls = true,
  autoPlay = true,
  autoPlayInterval = 5000,
  renderItem = defaultRenderItem,
  autoHeight = true,
  mouseTracking = true,
}) => {
  const [showGallery, setShowGallery] = useState(false)
  const [currentItemIndex, setCurrentItemIndex] = useState(images.length - 1)
  const carouselRef = useRef<AliceCarousel>(null)
  const items = useMemo(() => images.map(image => renderItem(image, restaurantName)), [images, renderItem, restaurantName])

  const handleSlideChange = e => {
    setCurrentItemIndex(e.item)
  }

  useUpdateEffect(() => {
    carouselRef.current._handlePlayPauseToggle()
  }, [autoPlay])

  return (
    <>
      <Container>
        <AliceCarousel
          autoHeight={autoHeight}
          infinite
          mouseTracking={mouseTracking}
          disableDotsControls
          disableButtonsControls
          onInitialized={handleSlideChange}
          onResized={handleSlideChange}
          onResizeEvent={e => {
            handleSlideChange(e)
            return true
          }}
          items={items}
          autoPlay={autoPlay}
          autoPlayStrategy={AutoPlayStrategy.ALL}
          autoPlayInterval={autoPlayInterval}
          ref={carouselRef}
          onSlideChange={handleSlideChange}
        />
        {withControls && (
          <IndicatorWrapper>
            {images.map((image, index) => (
              <IndicatorCircle key={image?.id} active={index === currentItemIndex} />
            ))}
          </IndicatorWrapper>
        )}
        {withControls && (
          <ActionWrapper>
            <ActionButton
              onClick={e => {
                carouselRef.current.slidePrev(e)
              }}>
              <Icon icon='arrow' />
            </ActionButton>
            <ActionButton
              onClick={e => {
                carouselRef.current.slideNext(e)
              }}>
              <Icon icon='arrow' />
            </ActionButton>
          </ActionWrapper>
        )}
        {withFullGallery && (
          <ButtonWrapper>
            <Button
              label={gt.tp('RestaurantSlider', 'Show all photos')}
              color='secondary'
              height='small'
              onClick={() => setShowGallery(true)}
            />
          </ButtonWrapper>
        )}
      </Container>
      {withFullGallery && (
        <RestaurantGallery images={images} title={restaurantName} show={showGallery} onClose={() => setShowGallery(false)} />
      )}
    </>
  )
}

export default RestaurantSlider
