import classNames from 'clsx'

// @ts-ignore
import sprite from '@restaurantclub/svg-client-icons/dist/sprite.svg'

import { Badge, Wrapper } from './IconStyle'

interface IconViewProps {
  icon: string
  className?: string
  isActive?: boolean
  onClick?: () => void
  badge?: {
    background?: string
    number: number
  }
}

const IconView = ({ icon, className, isActive, badge, ...props }: IconViewProps) => {
  if (typeof icon !== 'string') {
    return icon
  }

  const imgSrc = sprite?.src ? sprite.src : sprite
  const content = (
    <>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        data-icon={icon}
        src={`${imgSrc}#${icon}`}
        className={classNames(className, {
          [`icon-${icon}`]: true,
        })}
        data-active={isActive}
        alt={`Icon ${icon}`}
        {...props}
      />
    </>
  )

  if (!badge) {
    return content
  }

  const badgeLabel = badge.number < 100 ? badge.number : '99+'

  return (
    <Wrapper>
      {content}
      {badge && <Badge background={badge.background}>{badgeLabel}</Badge>}
    </Wrapper>
  )
}

export default IconView
