// @ts-strict-ignore
import { StyledBadge } from './BadgeShards'
import { BadgeColor, BadgeSize, BadgeTheme } from './BadgeTypes'

interface BadgeProps {
  color?: BadgeColor
  label: string
  size?: BadgeSize
  theme?: BadgeTheme
}

export const BadgeView = ({ color, label, size, theme }: BadgeProps) => {
  return (
    <StyledBadge color={color} size={size} badgeTheme={theme} fontWeight='semiBold'>
      {label}
    </StyledBadge>
  )
}
