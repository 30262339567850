import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { HeaderContainer, SelectContainer, SliderContainer } from '~/components/Restaurant/RestaurantPage/RestaurantPageShards'

export const ProductContainer = styled.div<{ hasImage?: boolean }>`
  width: 100%;
  margin: 0 auto;
  overflow: visible;

  ${SliderContainer} {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1.8;
    overflow: hidden;
    background-color: ${({ theme, hasImage }) => (hasImage ? theme.colors.bgColor : theme.colors.gray[200])};

    ${mediaQueryUp('m')} {
      border-radius: 0.5rem;
    }
  }
`

export const ChefsMenuHeaderContainer = styled(HeaderContainer)<{ isModal?: boolean }>`
  padding: ${({ isModal }) => (isModal ? '1rem 1rem 0' : '1rem 0')};

  ${mediaQueryUp('m')} {
    padding: ${({ isModal }) => (isModal ? '1rem 0 1.5rem' : '1rem 0 2rem')};
  }

  ${mediaQueryUp('l')} {
    padding: ${({ isModal }) => (isModal ? '1.5rem 0' : '2.5rem 0')};
  }
`

export const ChefsMenuSelectContainer = styled(SelectContainer)`
  ${mediaQueryUp('m')} {
    padding: 0;
  }

  & > button {
    margin: 1rem 0 1.5rem;
  }

  & > button + div {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.orange[500]};
  }
`

export const StyledInfobox = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
  padding: 1rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.gray[700]};
  display: flex;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
  max-width: calc(25.75rem - 3rem);
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    top: -1px;
    left: -50vw;
    width: 200vw;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gray[100]};

    ${mediaQueryUp('m')} {
      height: 0;
    }
  }

  ${mediaQueryUp('m')} {
    width: 25.75rem;
    padding: 1rem 2rem;
  }

  div:first-of-type {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.gray[200]};

    & img {
      height: 1.25rem;
      filter: ${({ theme }) => theme.filters.gray[700]};
    }
  }

  ${mediaQueryUp('l')} {
    max-width: unset;
    margin: 1.5rem 0 2.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }
`

export const HeaderWrapper = styled.div`
  & > div:first-child {
    color: ${({ theme }) => theme.colors.green[500]};
    text-transform: unset;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
