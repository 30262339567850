import { useEffect } from 'react'

import { IconWrapper, Label, StyledToast, ToastIcon, XIcon } from './ToastShards'
import { ToastViewProps } from './ToastTypes'
import { ICONS, TOAST_TIMEOUT } from './ToastUtils'

const ToastView = ({ message, type, close }: ToastViewProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => close(), TOAST_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [close])

  return (
    <StyledToast type={type}>
      <IconWrapper type={type}>
        <ToastIcon icon={ICONS[type]} />
      </IconWrapper>
      <Label>{message}</Label>
      {type === 'success' && (
        <IconWrapper>
          <XIcon icon='x' onClick={close} />
        </IconWrapper>
      )}
    </StyledToast>
  )
}

export default ToastView
