import { useEffect, useState } from 'react'

import { TagFragmentFragment } from '~/generated/graphql'

import { RestaurantPageTitleProps } from './ProductPageTitleTypes'

interface PageTitleData {
  name: string
  tags: TagFragmentFragment[]
  favorited?: boolean
}

const getPageTitleData = (props: RestaurantPageTitleProps): PageTitleData => {
  const { name } = props?.reservableExtra ?? props?.restaurant
  const { tags, favorited } = props?.restaurant || {}

  return {
    name,
    tags,
    favorited,
  }
}

export const usePageTitleData = (props: RestaurantPageTitleProps): PageTitleData => {
  const [data, setData] = useState(getPageTitleData(props))

  useEffect(() => {
    setData(getPageTitleData(props))
  }, [props])

  return data
}
