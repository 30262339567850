// @ts-strict-ignore
import { useMemo } from 'react'

import initApollo from '~/utils/init-apollo'

const DEFAULT_STATE = {}

const useApolloClient = (apolloState = DEFAULT_STATE, ctx) => {
  const apolloClient = useMemo(() => initApollo(apolloState, ctx), [apolloState, ctx])
  return apolloClient
}

export default useApolloClient
